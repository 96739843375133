import moment from 'moment';
import 'moment/locale/pt'
moment().locale('pt');

const formatCurrency = (value) => {
    if(typeof value === "number" && value >= 0) {
        let r = value.toFixed(2)?.split(".");
        return r[0]?.split("").reverse()?.join("").match(/.{1,3}/g).join(" ").split("").reverse().join("") + "," + r[1] + "€";
    } else if(value && value[0] && value[1]) {
        return value[0] + " - " + value[1] + "€";
    }
    return "-";
}

const getStay = (moveIn, moveOut, rentType) => {
    let moveInDate = new Date(moveIn);
    let moveOutDate = new Date(moveOut);
    let moveInMoment = moment(moveInDate).startOf("month");
    let moveOutMoment = moment(moveOutDate).startOf("month");
    let diff;
    if(rentType === "daily") {
        diff = moment(moveOut).diff(moment(moveIn), 'days') + 1;
        return diff + " days";
    } else if(rentType === "monthly") {
        diff = moveOutMoment.diff(moveInMoment, 'month') + 1;
    } else {
        diff = moveOutMoment.diff(moveInMoment, 'months');
        if (moveInDate.getDate() < 15) diff += 0.5;
        if (moveOutDate.getDate() > 15) diff += 0.5;
    }

    return diff + " months";
}

const toCapitalize = (string) => {
    if (string){
        let split = string.slice(0, 1);
        return split.toUpperCase() + string.slice(1, string.length);
    }else{
        return string;
    }

}

const formatDateTime = (value) => {

};

function getReservationStatus(reservation) {
    switch(reservation.currentStatus) {
        case "request":
            return "Open Request";
        case "expired":
            return "Expired" + (reservation.acceptedDate  && reservation.type !== "instant booking" && !reservation.needsStudentAcceptance ? " - No payment" : " - No reply");
        case "payment":
            return "Waiting Payment";
        case "paid":
            if (reservation.needsStudentAcceptance) {
                return "Waiting Tenant Acceptance";
            }
            return "Confirmed";
        case "rejected":
            if (reservation.rejectedDetails?.tenantMotive) {
                if (reservation.rejectedDetails.tenantMotive === "same_tenant") {
                    return "Rejected - Confirmed other listing";
                } else if (reservation.rejectedDetails.tenantMotive === "other_tenant") {
                    return "Rejected - Confirmed other tenant";
                }
            } else {
                if (reservation.rejectedDetails?.motive === "accepted_other") {
                    return "Rejected - Confirmed other listing";
                } else if (reservation.rejectedDetails?.motive === "accepted_same") {
                    return "Rejected - Confirmed other tenant";
                } else if (reservation.rejectedDetails?.motive === "changed_property" || reservation.rejectedDetails?.motive === "changed_dates") {
                    return "Rejected - Other Suggestion"
                }
            }
            return "Rejected by Landlord";
        case "cancelled":
            if(reservation.cancellation) {
                if (reservation.cancellation.type && reservation.cancellation.type.startsWith("landlord_")) {
                    let preWarningDays = moment(reservation.moveIn).diff(reservation.cancellation.requestDate, "days");
                    if(reservation.property.refundPolicy == null) reservation.property.refundPolicy = 30;
                    let hasPenalty = (reservation.property.refundPolicy === 0 || preWarningDays < reservation.property.refundPolicy) && reservation.landlord.commission !== 0;
                    return `Cancelled by Landlord - ${hasPenalty ? "With" : "No"} Penalty`;
                } else if (reservation.cancellation.type && reservation.cancellation.type.startsWith("tenant_")) {
                    let preWarningDays = moment(reservation.moveIn).diff(reservation.cancellation.requestDate, "days");
                    if (reservation.cancellation.type === "tenant_before") {
                        if (preWarningDays > reservation.property.refundPolicy && reservation.payment.refundedAmount) { //ISSUE 3051
                            return "Cancelled by Tenant - With Refund";
                        } else {
                            return "Cancelled by Tenant - No Refund";
                        }
                    } else {
                        return `Cancelled by Tenant - ${reservation.payment.refundedDate ? "Full" : "No"} Refund - With Landlord Penalty`;
                    }
                } else if (reservation.cancellation.type.startsWith("inlife")) {
                    return "Cancelled by Inlife";
                }
            }
            return "Cancelled";
        case "request_cancellation":
            return "Cancellation Request by " + toCapitalize(reservation.cancellation?.type.split("_")[0]);
    }
    return reservation.currentStatus;
}

export {formatCurrency, formatDateTime, getStay, toCapitalize, getReservationStatus};
