import React from 'react';
import {Form, FormGroup} from "react-bootstrap";


const KitchenForm = ({kitchen, index, handleChange, numKitchens}) =>{
    let kitchenName = "kitchen_"+index;
    return(
        <>
            <label
                className={"PropertyEdit_column-label-seconday"}>{'Kitchen ' + (numKitchens > 1 ? index+1 : '')}</label>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Kitchen size (&#13217;):</label>
                <Form.Control type="number" className="form-control" min={0} name="size" id={kitchenName+"-size"}
                              defaultValue={kitchen.size}
                              placeholder="Ex.: 70 m2" onChange={(e)=>handleChange(kitchenName, e.target.name, e.target.valueAsNumber)}/>
            </FormGroup>
            <div className={'d-flex flex-row Area-features'} onChange={(e)=>handleChange(kitchenName, e.target.name, e.target.value)}>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Fridge</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.fridge} className="form-check-input" name={'fridge'}
                                       value={!kitchen.fridge}
                                />
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Freezer</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.freezer} className="form-check-input" name={'freezer'}
                                       value={!kitchen.freezer}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Oven</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.oven} className="form-check-input" name={'oven'}
                                       value={!kitchen.oven}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Stove</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.stove} className="form-check-input" name={'stove'}
                                       value={!kitchen.stove}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Pots and pans</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.potsPans} className="form-check-input" name={'potsPans'}
                                       value={!kitchen.potsPans}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Kitchenware</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.kitchenware} className="form-check-input" name={'kitchenware'}
                                       value={!kitchen.kitchenware}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Coffee machine</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.coffeeMachine} className="form-check-input" name={'coffeeMachine'}
                                       value={!kitchen.coffeeMachine}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Toaster</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.toaster} className="form-check-input" name={'toaster'}
                                       value={!kitchen.toaster}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Microwave</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.microwave} className="form-check-input" name={'microwave'}
                                       value={!kitchen.microwave}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Kettle</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.kettle} className="form-check-input" name={'kettle'}
                                       value={!kitchen.kettle}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Tables</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.tables} className="form-check-input" name={'tables'}
                                       value={!kitchen.tables}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Chairs</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.chairs} className="form-check-input" name={'chairs'}
                                       value={!kitchen.chairs}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Juicer</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.juicer} className="form-check-input" name={'juicer'}
                                       value={!kitchen.juicer}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Blender</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.blender} className="form-check-input" name={'blender'}
                                       value={!kitchen.blender}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Kitchen hood</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.kitchenHood} className="form-check-input" name={'kitchenHood'}
                                       value={!kitchen.kitchenHood}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Window to the outside</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={kitchen.window} className="form-check-input" name={'window'}
                                       value={!kitchen.window}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default KitchenForm;