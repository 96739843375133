import React from 'react';
import Select from "react-select";
import {useTranslation} from "react-i18next";

const Rules = ({property, handleChange}) => {
    const {
        landlordLives,
        onlyFemales,
        onlyMales,
        onlyStudents,
        onlyWorkers,
        overnightGuests,
        overnightGuestsCost,
        smoking,
        smokingBalcony,
        pets,
        billsIncluded,
        billsMax,
        billsSpecific,
        cleaningLady,
        cleaningLadyFreq,
        cleaningLadyAreas,
        cleaningLadyPrice,
        securityDeposit,
        paidAtMovinDate,
        incompleteRentType,
        refundPolicy,
        contractType,
        extraAdministrativeFee,
        requiredDocuments,
        landlordGender,
        landlordStudent,
        landlordWorker,
        landlordAgeGroup,
        landlordSmoker,
        landlordPets
    } = property;

    let billsSpecificOptions = [
        {value: 'internet', label: 'Internet'},
        {value: 'tv', label: 'TV'},
        {value: 'water', label: 'Water'},
        {value: 'electricity', label: 'Electricity'},
        {value: 'gas', label: 'Gas'},
        {value: 'condominium', label: 'Condominium'}]

    let documentOptions = [
        {value: 'cc', label: "Citizen Card"},
        {value: 'passport', label: "Passport"},
        {value: 'visa', label: "Residence Visa"},
        {value: 'contract', label: "Employment contract"},
        {value: 'university', label:  "College acceptance letter"}]

    let requiredDocumentsLabels ={
        "cc": "Citizen Card",
        "passport": "Passport",
        "visa": "Residence Visa",
        "contract": "Employment contract",
        "university": "College acceptance letter"
    }


    const getPropertyBillsOptions = () => {
        let bills = [];
        for (let index in billsSpecific) {
            bills.push({value: billsSpecific[index], label: bills[billsSpecific[index]]})
        }
        return bills;
    }

    const getPropertyDocuments = () => {
        let docs = [];
        for (let index in requiredDocuments) {
            docs.push({value: requiredDocuments[index], label: requiredDocumentsLabels[requiredDocuments[index]]})
        }
        return docs;
    }
    const toggleExclusive = excl => e => {
        if (e.target.prop('checked'))
            handleChange({target: {name: excl, value: false}});
        handleChange(e)
    }

    let securityDepositTypes = ['1/2month', '1month', '3/2months', '2months', '5/2months', '3months', 'false']

    return (
        <>
            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label"}>Rules</label>
                <label className={"PropertyEdit_column-label-seconday"}>General rules</label>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Does the landlord live on the property?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={landlordLives} className="form-check-input"
                                   onChange={() => {
                                       handleChange('landlordLives', !landlordLives)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {landlordLives &&
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>About the landlord:</label>
                    <div className={"d-flex flex-row"} id={"landlordGender"}>
                        <div className="form-check mr-1">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="landlordGender"
                                       id="landlordGender1"
                                       defaultChecked={landlordGender === 'male'} value={'male'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Male
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="landlordGender"
                                       id="landlordGender2" defaultChecked={landlordGender === 'female'}
                                       value={'female'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Female
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-flex flex-row"}>
                        <div className="form-check mr-1">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={landlordWorker} className="form-check-input"
                                       onChange={() => {
                                           handleChange('landlordWorker', !landlordWorker);
                                       }}/>
                                <i className="input-helper"></i>
                                Worker
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={landlordStudent} className="form-check-input"
                                       onChange={() => {
                                           handleChange('landlordStudent', !landlordStudent);
                                       }}/>
                                <i className="input-helper"></i>
                                Student
                            </label>
                        </div>
                    </div>
                    <div onChange={(e) => handleChange(e.target.name, e.target.value)}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Age:</label>
                        <div className={"d-flex flex-row"}>
                            <div className="form-check mr-1">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="landlordAgeGroup"
                                           id="landlordAgeGroup1"
                                           defaultChecked={landlordAgeGroup === 'young'} value={'young'}
                                    />
                                    18-25
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="landlordAgeGroup"
                                           id="landlordAgeGroup2" defaultChecked={landlordAgeGroup === 'youngadult'}
                                           value={'youngadult'}
                                    />
                                    25-34
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                        </div>
                        <div className={"d-flex flex-row"}>
                            <div className="form-check mr-1">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="landlordAgeGroup"
                                           id="landlordAgeGroup3"
                                           defaultChecked={landlordAgeGroup === 'adult'} value={'adult'}
                                    />
                                    34-60
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name="landlordAgeGroup"
                                           id="landlordAgeGroup4" defaultChecked={landlordAgeGroup === 'old'}
                                           value={'old'}
                                    />
                                    more than 60
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Smoker?</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={landlordSmoker} className="form-check-input"
                                       onChange={() => {
                                           handleChange('landlordSmoker', !landlordSmoker)
                                       }}/>
                                <i className="input-helper"></i>
                                Yes
                            </label>
                        </div>
                    </div>
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Has pets?</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={landlordPets} className="form-check-input"
                                       onChange={() => {
                                           handleChange('landlordPets', !landlordPets)
                                       }}/>
                                <i className="input-helper"></i>
                                Yes
                            </label>
                        </div>
                    </div>

                </div>}
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Do they accept both genders?</label>
                    <div className={"PropertyEdit_row-inputs"}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={onlyFemales && !onlyMales} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyFemales', true);
                                           handleChange('onlyMales', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Only women
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={!onlyFemales && onlyMales} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyMales', true);
                                           handleChange('onlyFemales', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Only men
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={onlyMales === onlyFemales} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyMales', false);
                                           handleChange('onlyFemales', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Both
                            </label>
                        </div>
                    </div>

                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Do they accept workers and students?</label>
                    <div className={"PropertyEdit_row-inputs"}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={onlyStudents && !onlyWorkers} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyStudents', true);
                                           handleChange('onlyWorkers', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Only students
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={!onlyStudents && onlyWorkers} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyWorkers', true);
                                           handleChange('onlyStudents', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Only workers
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" checked={onlyWorkers === onlyStudents} className="form-check-input"
                                       onChange={() => {
                                           handleChange('onlyWorkers', false);
                                           handleChange('onlyStudents', false);
                                       }}/>
                                <i className="input-helper"></i>
                                Both
                            </label>
                        </div>
                    </div>

                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Are occasional guests allowed on the property?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" defaultChecked={overnightGuests && !overnightGuestsCost} className="form-check-input"
                                   name={"overnightGuests"}
                                   onChange={() => {
                                       handleChange('overnightGuests', true)
                                       handleChange('overnightGuestsCost', null)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" defaultChecked={!overnightGuests} className="form-check-input"
                                   name={"overnightGuests"}
                                   onChange={() => {
                                       handleChange('overnightGuests', false)
                                       handleChange('overnightGuestsCost', null)
                                   }}/>
                            <i className="input-helper"></i>
                            No
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" defaultChecked={overnightGuestsCost && overnightGuests} className="form-check-input"
                                   name={"overnightGuests"}
                                   onChange={() => {
                                       handleChange('overnightGuestsCost', 100)
                                       handleChange('overnightGuests', false)
                                   }}/>
                            <i className="input-helper"></i>
                            Occasionally with a fee
                        </label>
                    </div>
                    {overnightGuestsCost && <input type={'number'} defaultValue={overnightGuestsCost} onChange={(e)=>{handleChange('overnightGuestsCost', e.target.valueAsNumber || 0)}} />
                    }
                </div>

                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>
                        Are pets allowed on the property?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={pets} className="form-check-input"
                                   onChange={() => {
                                       handleChange('pets', !pets)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>
                        Is smoking allowed on the property?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={smoking} className="form-check-input"
                                   onChange={() => {
                                       handleChange('smoking', !smoking)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                </div>
                {smoking &&
                <div className={"PropertyEdit_row-inputs"}>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="smokingBalcony"
                                   id="smokingBalcony_no"
                                   defaultChecked={!smokingBalcony} value={false}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            No restrictions
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="smokingBalcony"
                                   id="smokingBalcony_yes" defaultChecked={smokingBalcony} value={true}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Window/balcony only.
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>}
                <label className={"PropertyEdit_column-label-seconday"}>Services and Cleaning</label>
                <div className={"d-flex flex-column "}>
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>
                            Are bills included in the price?</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input" name="billsIncluded"
                                       id="billsIncluded"
                                       value="yes" defaultChecked={billsIncluded}
                                       onChange={() => handleChange('billsIncluded', !billsIncluded)}/>
                                <i className="input-helper"></i>
                                Yes
                            </label>
                        </div>
                    </div>
                </div>
                <div className={"d-flex flex-row mb-2"}>
                    <label>Maximum value:</label>
                    <input type={'number'} placeholder={'Maximum value'} name={"billsMax"}
                           className={"form-control"}
                           disabled={billsSpecific?.length > 0 || !billsIncluded}
                           defaultValue={billsMax} onChange={(e) => {
                        handleChange('billsMax', e.target.valueAsNumber || 0);
                    }}/>
                </div>
                <Select
                    placeholder={"Specific expenses"}
                    name={'billsSpecific'}
                    isMulti={true}
                    options={billsSpecificOptions}
                    defaultValue={getPropertyBillsOptions()}
                    onChange={(options => {
                        let list = [];
                        Object.keys(options || []).map((bill) => list.push(options[bill].value));
                        handleChange('billsSpecific', list)
                    })}
                    isDisabled={billsMax > 0 || !billsIncluded}
                />
                <div className={"d-flex flex-column "}>
                    <div className={"PropertyEdit_row-inputs"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Is cleaning service included in the price?</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLady"
                                       id="cleaningLady_yes"
                                       defaultChecked={cleaningLady === 'yes'} value={'yes'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Yes
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLady"
                                       id="cleaningLady_no" defaultChecked={cleaningLady === 'no'} value={'no'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                No
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        {cleaningLady === 'no' &&
                            <input type={'number'} name={'cleaningLadyPrice'} placeholder={'20€/mês por quarto'} defaultValue={cleaningLadyPrice} onChange={(e)=> handleChange('cleaningLadyPrice', e.target.valueAsNumber || 0)}/>
                        }
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLady"
                                       id="cleaningLady_non_available" defaultChecked={cleaningLady === 'non_available'}
                                       value={'non_available'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Service Unavailable
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
                {cleaningLady !== 'non_available' &&
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>How many times a week?</label>
                    <div className={"wrap-2-columns"}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyFreq"
                                       id="cleaningLadyFreq1"
                                       defaultChecked={cleaningLadyFreq === 1} value={1}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                1 time a week
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyFreq"
                                       id="cleaningLadyFreq2" defaultChecked={cleaningLadyFreq === 2} value={2}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                2 times per week
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyFreq"
                                       id="cleaningLadyFreq3" defaultChecked={cleaningLadyFreq === 3} value={3}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                3 times per week
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyFreq"
                                       id="cleaningLadyFreq7" defaultChecked={cleaningLadyFreq === 7} value={7}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Every day
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>}
                {cleaningLady !== 'non_available' &&
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>
                        In the following areas:</label>
                    <div className={"PropertyEdit_row-inputs"}>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyAreas"
                                       id="cleaningLadyAreas1"
                                       defaultChecked={cleaningLadyAreas === 'commonAreas'} value={'commonAreas'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Common areas
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyAreas"
                                       id="cleaningLadyAreas2"
                                       defaultChecked={cleaningLadyAreas === 'commonAreasAndRooms'}
                                       value={'commonAreasAndRooms'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                Rooms and common areas
                                <i className="input-helper"></i>
                            </label>
                        </div>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="cleaningLadyAreas"
                                       id="cleaningLadyAreas3" defaultChecked={cleaningLadyAreas === 'rooms'}
                                       value={'rooms'}
                                       onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                                        Rooms only
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>}
            </div>
            <div className={"PropertyEdit_column mt-4 mr-2"}>
                <label className={"PropertyEdit_column-label-seconday"}>Deposit and Stays</label>
                <label className={"PropertyEdit_input-label mt-2 mr-1"}>Is there a security deposit?</label>
                <div className={"wrap-2-columns"}>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit0" defaultChecked={securityDeposit === 'false'}
                                   value={'false'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            No
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit1"
                                   defaultChecked={securityDeposit === '1/2month'} value={'1/2month'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Half month's rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit2"
                                   defaultChecked={securityDeposit === '1month' || !securityDeposit}
                                   value={'1month'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            1 month's rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit3" defaultChecked={securityDeposit === '3/2months'}
                                   value={'3/2months'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            1.5 months of rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit4"
                                   defaultChecked={securityDeposit === '2month'} value={'2months'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            2 months rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit5"
                                   defaultChecked={securityDeposit === '5/2months'}
                                   value={'5/2months'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            2.5 months rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit6" defaultChecked={securityDeposit === '3months'}
                                   value={'3months'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            3 months rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check d-flex flex-row">
                        <label className="form-check-label mr-1">
                            <input type="radio" className="form-check-input" name="securityDeposit"
                                   id="securityDeposit7"
                                   defaultChecked={!securityDepositTypes.includes(securityDeposit) && securityDeposit}
                                   value={'specificValue'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Specific Value
                            <i className="input-helper"></i>
                        </label>
                        <input type={'number'} placeholder={'300 €'}
                               className={'form-control'}
                               defaultValue={!securityDepositTypes.includes(securityDeposit) && (isNaN(parseInt(securityDeposit))? 100 : parseInt(securityDeposit))}
                               name="securityDeposit" disabled={securityDepositTypes.includes(securityDeposit)}
                               id="securityDeposit" onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>What payments must tenants make at the moment of check in?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="paidAtMovinDate"
                                   id="paidAtMovinDate1"
                                   defaultChecked={paidAtMovinDate === 'Security Deposit'} value={'Security Deposit'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Defined security deposit
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="paidAtMovinDate"
                                   id="paidAtMovinDate2"
                                   defaultChecked={paidAtMovinDate === 'Security Deposit + Last Rent'}
                                   value={'Security Deposit + Last Rent'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Security Deposit + Last Rent
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>What type of contract to consider in case of of incomplete months at the tenant's entry or exit:</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="incompleteRentType"
                                   id="incompleteRentType1"
                                   defaultChecked={incompleteRentType === 'daily'} value={'daily'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Daily
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="incompleteRentType"
                                   id="incompleteRentType2"
                                   defaultChecked={incompleteRentType === 'biweekly'}
                                   value={'biweekly'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Biweekly
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="incompleteRentType"
                                   id="incompleteRentType3"
                                   defaultChecked={incompleteRentType === 'monthly'}
                                   value={'monthly'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Monthly
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Cancellation Policy:</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="refundPolicy"
                                   id="refundPolicy1"
                                   defaultChecked={refundPolicy === 15} value={15}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Flexible (up to 15 days before the start of the contract)
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="refundPolicy"
                                   id="refundPolicy2"
                                   defaultChecked={refundPolicy === 30}
                                   value={30}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Moderate (up to 30 days before the start of the contract)
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="refundPolicy"
                                   id="refundPolicy3"
                                   defaultChecked={refundPolicy === 60}
                                   value={60}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Rigid (up to 60 days before the start of the contract)
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="refundPolicy"
                                   id="refundPolicy3"
                                   defaultChecked={refundPolicy === 0}
                                   value={0}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Non-refundable
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
            </div>
            <div className={"PropertyEdit_column mt-4"}>
                <label className={"PropertyEdit_column-label-seconday"}>Contract</label>
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>What type of contract do you have with tenants?
                    </label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="contractType"
                                   id="contractType1"
                                   defaultChecked={contractType === 'traditional'} value={'traditional'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Traditional renting
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="contractType"
                                   id="contractType2"
                                   defaultChecked={contractType === 'renting'}
                                   value={'renting'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Hosting Services
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="contractType"
                                   id="contractType3"
                                   defaultChecked={contractType === 'undefined'}
                                   value={'undefined'}
                                   onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                            Undefined
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Are there any additional administrative fees related to the contract?</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox"
                                   defaultChecked={extraAdministrativeFee && extraAdministrativeFee !== 0}
                                   className="form-check-input"
                                   onChange={() => {
                                       handleChange('extraAdministrativeFee', extraAdministrativeFee ? (extraAdministrativeFee !== 0 ? 0 : 1) : 1)
                                   }}/>
                            <i className="input-helper"></i>
                            Yes
                        </label>
                    </div>
                    <input type={'number'} name={'extraAdministrativeFee'} placeholder={'20€'}
                           defaultValue={extraAdministrativeFee !== 0 && extraAdministrativeFee}
                           disabled={!extraAdministrativeFee || extraAdministrativeFee === 0}
                           onChange={(e) =>
                               handleChange('extraAdministrativeFee', e.target.valueAsNumber || 0)}
                    />
                </div>
                <div>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Documents to be submitted to sign the contract:</label>
                    <Select
                        isMulti={true}
                        options={documentOptions}
                        defaultValue={getPropertyDocuments()}
                        onChange={(options => {
                            let list = [];
                            Object.keys(options || []).map((doc) => list.push(options[doc].value));
                            handleChange('requiredDocuments', list)
                        })}
                    />
                </div>
            </div>
        </>
    )
}

export default Rules;