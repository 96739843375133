import React from 'react'
import {useHistory} from "react-router-dom";
import moment from 'moment';


const LandlordCard = ({data: landlord, impersonateLandlord}) => {
    const history = useHistory();

    return (
        <div className="card col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card-body p-1">
                <div className="card border-primary border pricing-card-body p-1 h-100">
                    <div className="text-center pricing-card-head">
                        <h3 className={"text-capitalize"}>{landlord.name}</h3>
                        {landlord.topLandlord && <h1 className="font-weight-normal mb-4 badge badge-success">Top Landlord</h1>}
                        {(landlord.acceptedRatio > 0 && !landlord.topLandlord) && <h1 className="font-weight-normal mb-4 badge badge-info">Rising Star</h1>}
                        {landlord.promotion?.endDate && moment(landlord.promotion.endDate).isAfter(moment()) && <span className="font-weight-normal mb-4 badge badge-warning text-dark">
                            {landlord.promotion.commission}% Commission until {moment(landlord.promotion.endDate).format("DD/MM/YYYY")}
                        </span>}
                    </div>
                    <div className="card pricing-card-body flex-grow-1" style={{boxShadow: "unset"}}>
                        <ul className="list-unstyled plan-features">
                            <li><i className="mdi mdi-phone text-info mr-2" />{((landlord.dialCode ? landlord.dialCode + " " : "") + landlord.phone) || "N/A"}</li>
                            <li><i className="mdi mdi-email-outline text-primary mr-2" />{landlord.email || "N/A"}</li>
                            <li><i className="mdi mdi-cash icon-sm mr-2" />{landlord.iban || "N/A"}</li>
                            <li><i className="mdi mdi-file-document-edit-outline icon-md-1 mr-2 text-muted"/>{landlord.nif || "N/A"}</li>
                            <li><i className="mdi mdi-office-building icon-md-1 mr-2 text-muted"/>{landlord.address || "N/A"}</li>
                            <li>{"Properties: " + (landlord.houses || "0")}</li>
                            <li>{"Total Reservations: "+ (landlord.reservations || "0")}</li>
                            <li>{"Accepted Ratio: "+ (landlord.acceptedRatio || "0")}</li>
                            <li>{"Pending Reservations: "+ (landlord.pendingReservations || "0")}</li>
                            <li>{"Pending Visits: " + (landlord.pendingVisits || "0")}</li>
                            <li>Account created on {moment(landlord.registrationDate).format("DD/MM/YYYY")}</li>
                            {landlord.lastLogin && <li>Last login: {moment(landlord.lastLogin).format("DD/MM/YY HH:mm")}</li>}
                        </ul>
                    </div>
                    <div className="wrapper mb-1">
                        <button onClick={() => history.push(`/landlords/${landlord.id}`)} className="btn btn-secondary btn-block">Manage</button>
                    </div>
                    <div className="wrapper">
                        <button onClick={impersonateLandlord(landlord)} className="btn btn-primary btn-block">Impersonate</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LandlordCard;
