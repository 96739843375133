import React, {useMemo, useRef, useState} from 'react'
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';
import {visitService} from '../../services/visitService';
import cogoToast from "cogo-toast";
import DataCardTable from "../components/DataCardTable";
import VisitCard from "../components/VisitCard";
import PageWrapper from "../components/PageWrapper";
import {Link} from "react-router-dom";
import {useDialogContext} from "../App";
import {useTranslation} from "react-i18next";
import {utils} from "../../utils";


const Visits = ({inline, overrideFetch, cardTableStyles, customDataTable}) => {
    const { setModal } = useDialogContext();
    const {t} = useTranslation();


    const conditionalRowStyles = [
        {
            when: row => row.rejectedDate,
            style: {
                backgroundColor: '#f5ccce',
            }
        },
        {
            when: row => (visitService.isExpired(row) && !row.selectedDate),
            style: {
                backgroundColor: '#e8e8e8',
            }
        },
        {
            when: row => visitService.isFinished(row) || (!row.rejectedDate && row.selectedDate && moment(row.selectedDate).isSameOrBefore(moment())),
            style: {
                backgroundColor: '#c2fabd',
            }
        }
    ];

    const dataColumns = [
        {
            name: 'Client ID',
            grow: 0.7,
            selector: row => row.user?.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.user?.id?.split("-")[0]}</div>
        },
        {
            name: 'Client',
            width: '120px',
            selector: row => row.user?.name
        },
        {
            name: 'Visit ID',
            grow: 0.7,
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: 'Email',
            grow: 1.2,
            selector: row => row.user?.email
        },
        {
            name: 'Phone',
            grow: 1.2,
            selector: row => row.user?.phone
        },

        {
            name: 'Visit Date',
            minWidth: "120px",
            center: true,
            cell: row => (row.selectedDate)  ? <span>
                    <OverlayTrigger overlay={<Tooltip>Hora: {moment(row.selectedDate).format("HH:mm")}{"\nTimezone: " + (row.property?.region || "where this accommodation is located")}</Tooltip>}>
                        <div className={"badge badge-"+(moment(row.selectedDate).isAfter(moment()) && (!row.rejectedDate) ? 'primary' : (!row.rejectedDate ? 'success' : 'danger'))}>
                            {moment(row.selectedDate).format("DD-MM-YYYY")}
                        </div>
                    </OverlayTrigger>
                </span> :
                <span>{row.chosenDates?.map((dt,idx) =>
                    <OverlayTrigger key={row.id+dt.start+dt.end+idx} overlay={<Tooltip>Time: {dt.start} - {dt.end}</Tooltip>}>
                        <div>{moment(dt.date).format("DD-MM-YYYY")}</div>
                    </OverlayTrigger>)}
                </span>
        },
        {
            name: 'Move-In',
            center: true,
            selector: row => row.moveIn,
            format: row => moment(row.moveIn).format("DD-MM-YYYY")
        },
        {
            name: 'Move-Out',
            center: true,
            selector: row => row.moveOut,
            format: row => moment(row.moveOut).format("DD-MM-YYYY")
        },
        {
            name: 'Nº Tenants',
            maxWidth: "110px",
            center: true,
            selector: row => row.numTenants
        },
        {
            name: 'Rent',
            center: true,
            selector: row => getMonthlyRent(row)
        },
        {
            name: 'Type',
            selector: row => row.type,
            format: row => row.type === "onsite" ? "In person" : "Video call"
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={updateNote(row, "comment")} rows="2" defaultValue={row?.notes?.comment}/>
        },
        {
            name: 'City',
            grow: 0.7,
            selector: row => row.property?.region
        },
        {
            name: 'Listing',
            minWidth: "200px",
            selector: row => row.property,
            cell: row => (
                <Link to={`/property/${row.property?.id}${row.room_id ? "?room_id=" + row.room_id : ""}`}
                      style={{color: 'inherit', textDecoration: 'inherit'}}>
                    <div data-tag="allowRowEvents" className={"property-cell"}>
                        <span>{row.property?.internal_name}</span>
                        <small>{row.property?.address}</small>
                    </div>
                </Link>
            )
        },
        {
            name: 'Listing ID',
            grow: 0.7,
            selector: row => row.property?.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.property?.id?.split("-")[0]}</div>
        },
        {
            name: 'Landlord',
            grow: 1.2,
            selector: row => row.landlord,
            cell: row => <div data-tag="allowRowEvents"><Link
                to={`/landlords/${row.landlord?.id}`}>{row.landlord?.name}</Link></div>
        },
        {
            name: 'Email',
            grow: 1.2,
            selector: row => row.landlord?.email
        },
        {
            name: 'Phone',
            grow: 1.2,
            selector: row => row.landlord,
            right: true,
            cell: row => <div data-tag="allowRowEvents">{(row.landlord?.dialCode ? row.landlord?.dialCode + " " : "") + row.landlord?.phone}</div>
        },
        {
            name: 'Status',
            button: true,
            cell: row => (row.rejectedDate) ? <div>
                Rejected </div> : (row.selectedDate) ?
                (visitService.isFinished(row)) ? <div> Concluded </div>
                    : <div> Accepted </div>
                : (visitService.isExpired(row) ? "Expired" : "To be accepted")

        },
        {
            name: 'Status Info',
            button: true,
            cell: visit => <div>
                {visit.selectedDate && !visit.rejectedDate && <div>
                    <span>Scheduled at: {moment(visit.acceptedDate).format("DD/MM/YY HH:mm")}</span>
                </div>}
                {(visit.rejectedDate || (visitService.isExpired(visit) && !visit.selectedDate)) &&
                <div>
                    {t('visit_card.reject_motive.' + (visit.rejectedMotive || "expired"))}
                </div>}
                {(!visitService.isExpired(visit) && !visit.selectedDate && !visit.rejectedDate) &&
                <div>
                    <i className="mdi mdi-history mr-2"/>Expires in {utils.getExpirationDate(visit.suggestionType ? visit.acceptedDate : visit.requestDate).endOf("day").diff(moment().startOf("day"), 'day')} days
                </div>}
            </div>

        },
        {
            name: 'Assigned to',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={updateNote(row, "assigned")} rows="2" defaultValue={row?.notes?.assigned}/>
        },
        {
            name: 'Request Date',
            minWidth: "120px",
            center: true,
            selector: row => row.requestDate,
            cell: row => <OverlayTrigger overlay={<Tooltip>Hora: {moment(row.requestDate).format("HH:mm")}</Tooltip>}>
                <span>{moment(row.requestDate).format("DD-MM-YYYY")}</span>
            </OverlayTrigger>
        },
        {
            name: 'Accepted Date',
            minWidth: "120px",
            center: true,
            selector: row => row.acceptedDate,
            cell: row => row.acceptedDate ?
                <OverlayTrigger overlay={<Tooltip> Hora: {row.acceptedDate ? moment(row.acceptedDate).format("HH:mm") : "-"}</Tooltip>}>
                    <span>{row.acceptedDate ? moment(row.acceptedDate).format("DD-MM-YYYY") : "-"}</span>
                </OverlayTrigger> : <span>-</span>
        },
        {
            name: 'Diff',
            button: true,
            cell: row => (row.acceptedDate && row.requestDate) ? <div>
                    {moment(row.acceptedDate).diff(moment(row.requestDate), 'days')}
                </div> :
                <span>-</span>

        },

        {
            name: 'Actions',
            button: true,
            cell: row => ((visitService.isExpired(row) && !row.selectedDate) || row.rejectedDate) ?
                <span>-</span> :
                (row.selectedDate ? ((moment(row.selectedDate).isAfter(moment())) ?
                            <div className={"actions-container"}>
                                <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                                    <i onClick={rejectVisit(row)}
                                       className={"mdi mdi-close-box-multiple-outline action-reject"}/>
                                </OverlayTrigger>
                            </div> : <span>-</span>)
                        : (
                            <div className={"actions-container"}>
                                <OverlayTrigger overlay={<Tooltip>Accept</Tooltip>}>
                                    <i onClick={acceptVisit(row)}
                                       className={"mdi mdi-checkbox-marked-outline action-accept"}/>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                                    <i onClick={rejectVisit(row)}
                                       className={"mdi mdi-close-box-multiple-outline action-reject"}/>
                                </OverlayTrigger>
                            </div>
                        )
                )
        }
    ];

    const debounceHandler = useRef(null);


    const acceptVisit = (visit) => async () => {
        let dates = [...new Set(visit.chosenDates?.map(item => moment(item.date).format("YYYY-MM-DD")))];
        let hours = {};
        visit.chosenDates.forEach(item => {
            let day = moment(item.date).format("YYYY-MM-DD");
            if (!hours[day]) hours[day] = [];
            hours[day].push(item.start + '-' + item.end);
        })
        let selectedDate = '';
        let selectedTime;
        setModal({
            title: "Accept visit",
            message: <>Are you sure you want to <strong>accept</strong> {visit.user.name}'s visit?
                <div>
                    <Form.Group>
                        <div>
                            <label htmlFor="visitDate">Choose a date:</label>
                            <select className="form-control" id="visitDate" defaultValue={selectedDate}
                                    onChange={e => selectedDate = e.target.value}>
                                <option value='' disabled>Choose a date</option>
                                {dates.map(dt => <option value={dt}>{dt} {hours[dt].join(', ')}</option>)}
                            </select>
                        </div>
                        <div className={"d-flex flex-column mt-2"}>
                            <label>Enter the time:</label>
                            <small className={"text-muted"}>{visit.property?.region ? visit.property?.region + "(property location) Timezone" : "This is the local timezone where this accommodation is located"}</small>
                            <Form.Control type="time" className="form-control-sm" placeholder="Time"
                                          aria-label="visitHour" required
                                          onChange={event => selectedTime = event.target.value}/>
                            {/*<input type="time"  required onChange={event => selectedTime = event.target.value}/>*/}
                        </div>
                    </Form.Group>
                </div>
            </>,

            onSubmit: async () => {
                if (selectedTime && selectedDate) {
                    await visitService.accept(visit, moment(selectedDate + " " + selectedTime));
                    visit.acceptedDate = Date.now();
                    visit.selectedDate = new Date(moment(selectedDate + " " + selectedTime));//to update table
                    setModal(m => ({...m, hide: true}));
                    cogoToast.success('The visit has been scheduled successfully.', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    })

                } else {
                    cogoToast.error('Enter a date and time for the visit.', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        })
    };

    const rejectVisit = (visit) => async () => {
        setModal({
            title: "Reject visit",
            message: <>Are you sure you want to <strong>reject</strong> {visit.user.name}'s visit?</>,
            onSubmit: async () => {
                await visitService.reject(visit);
                visit.rejectedDate = Date.now();
                visit.rejectedMotive = "landlord";
                setModal(m => ({...m, hide: true}));
                cogoToast.success('The visit has been rejected successfully.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const getMonthlyRent = (visit) => {
        if (visit.property?.room?.fixedRent) return "€" + visit.property?.room.rent;
        const _toMonth = (m) => moment(m).locale('en').format("MMMM").toLowerCase();
        let startMoveIn = moment(visit.moveIn);
        let moveOutMoment = moment(visit.moveOut);
        let min, max;
        while (startMoveIn.isBefore(moveOutMoment)) {
            let curMonthValue = parseInt(visit.property?.room?.rentMonths[_toMonth(startMoveIn)]);
            if (!min || curMonthValue < min) min = curMonthValue;
            if (!max || curMonthValue > max) max = curMonthValue;
            startMoveIn.add(1, "month");
        }
        if (min === max) return "€" + min;
        return "€" + min + " - €" + max;
    };

    const updateNote = (visit, note_type) => async (e) => {
        let note = e.target.value;
        clearTimeout(debounceHandler.current);
        debounceHandler.current = setTimeout(async () => {
            await visitService.updateNote(visit, note_type, note);
            visit.notes = visit.notes || [];
            visit.notes[note_type] = note;
        }, 1000);
    }

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("visits_filters")) || {sort: "desc"});

    return (
        <PageWrapper breadcrumbs={[{title: "Visits"}]}
                     title={"Visits"}
                     setFilters={setFilters}
                     filters={filters}
                     inline={inline}>
            <DataCardTable
                cardRendererComponent={VisitCard}
                cardRendererProps={{
                    rejectVisit, acceptVisit, getMonthlyRent
                }}
                fixedFirstColumn
                customClass={"visits-table "+ cardTableStyles}
                customHeaderClass={customDataTable}
                columns={dataColumns}
                dataType={"visits"}
                fetchData={overrideFetch || visitService.list}
                conditionalRowStyles={conditionalRowStyles}
                filters={filters}
            />
        </PageWrapper>
    );
}

export default Visits;
