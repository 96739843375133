import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import {propertyService} from "../../services/propertyService";
import React, {useState} from "react";
import PropertyCard from "../components/PropertyCard";
import {useTranslation} from "react-i18next";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from "moment";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import IntegrationCard from "../components/IntegrationCard.js";
import RatingsOverlay from "../ratings/ratings-overlay.js";

const Integrations = ({inline, cardTableStyles}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const disableHandler = (listing) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>disable</strong> this listing?</>,
            message: <>The listing will no longer be visible on the site.
            </>,
            onSubmit: async () => {
                await propertyService.disableIntegration(listing.id, listing.room_id);
                listing.disabled = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Integration disabled', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const enableHandler = (listing) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>activate</strong> this listing?</>,
            message: <>The listing will now be visible on the site.</>,
            onSubmit: async () => {
                await propertyService.enableIntegration(listing.id, listing.room_id);
                listing.disabled = false;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Room enable.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const handlePreview = (listing) => () => {
        propertyService.previewIntegration(listing);
    }


    const dataColumns = [
        {
            name: 'ID',
            width: "100px",
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: 'Address',
            minWidth: "200px",
            maxWidth: "240px",
            selector: row => row.address,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{row.address}</span>
            </div>)
        },
        {
            name: 'Landlord ID',
            width: "300px",
            selector: row => row.landlord_id
        },
        {
            name: 'Country',
            minWidth: "90px",
            maxWidth: "130px",
            selector: row => row.country
        },
        {
            name: 'Accommodation type',
            selector: row => row.accommodation,
            cell: row => <div
                data-tag="allowRowEvents">{row.accommodation ? t(`property_resume.accommodation_type.${row.accommodation}`) : " - "}</div>
        },
        {
            name: 'Rent intervals',
            selector: row => row.rent,
            cell: row => (<OverlayTrigger
                overlay={<Tooltip>{row.billsIncluded ? 'Expenses included' : 'Expenses not included'}</Tooltip>}>
                {<div>{row.minRent === row.maxRent ? row.minRent : (`${row.minRent}-${row.maxRent}`)}€</div>}
            </OverlayTrigger>)
        },
        {
            name: 'Nº of Rooms',
            selector: row => row.bedrooms,
            center: true
        },
        {
            name: 'Bathrooms',
            selector: row => row.numBathroom,
            center: true
        },
        {
            name: 'Visit Types',
            selector: row => row.accept_visit_type,
            center: true,
            cell: row =><div>
                {row.accept_visit_type || "Not defined"}
            </div>
        },
        {
            name: 'Instant Booking',
            selector: row => row.instantBooking,
            center: true,
            cell: row =><div>
                {row.instantBooking ? "Yes" : "No"}
            </div>
        },
        {
            name: 'Integration ID',
            selector: row => row.integration_id,
            center: true
        },
        {
            name: 'Actions',
            width: "100px",
            button: true,
            cell: row => <div className={"actions-container"}>
                <RatingsOverlay ratings={row.ratings}></RatingsOverlay>
                <OverlayTrigger overlay={<Tooltip>Preview</Tooltip>}>
                    <i className={"mdi mdi-eye preview-action"} onClick={handlePreview(row)}/>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>{row.disabled ? "Enable" : "Disable"}</Tooltip>}>
                    <i className={`mdi ${row.disabled ? "mdi-checkbox-marked-circle activate-action" : "mdi-block-helper inactivate-action"}`} onClick={row.disabled ? enableHandler(row) : disableHandler(row)}/>
                </OverlayTrigger>
            </div>
        }

    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("integrations_filters")) || {lastUpdatedSort:"desc"});

    return (
        <PageWrapper breadcrumbs={[{title: "Integrations"}]}
                     title={"Integrations"}
                     inline={inline} filters={filters} setFilters={setFilters}>
            <DataCardTable dataType={"integrations"}
                           customClass={cardTableStyles}
                           fetchData={propertyService.listIntegrations}
                           cardRendererComponent={IntegrationCard}
                           cardRendererProps={{
                               disableHandler, enableHandler, handlePreview
                           }}
                           columns={dataColumns}
                           filters={filters}
            />
        </PageWrapper>

    )
};

export default Integrations;
