import React, { useEffect, useState} from 'react';
import './App.scss';
import AppRoutes from './AppRoutes';
import {useTranslation} from "react-i18next";
import {ProvideAuth, useAuth} from "../services/authService";
import Modal from "./shared/ModalWrapper";

const DialogContext = React.createContext({});
const useDialogContext = () => React.useContext(DialogContext);

const App = (props) => {

    const[modal, setModal]=useState(null);

    const {i18n} = useTranslation();
    const auth = useAuth();

    useEffect(() => {
        i18n.changeLanguage('en');
    }, []);

    return (
        <ProvideAuth>
            <DialogContext.Provider value={{setModal}}>
                <AppRoutes/>
                <div>
                    <Modal size={modal?.size}
                           title={modal?.title}
                           show={modal && !modal.hide} onClose={() => setModal(m => ({...m, hide: true}))}
                           onSubmit={modal?.onSubmit}>
                        <div>{modal?.message}</div>
                    </Modal>
                </div>
            </DialogContext.Provider>
        </ProvideAuth>
    );

}

export default App;
export {useDialogContext};
