import axios from 'axios';

const base_url = process.env.REACT_APP_API_BASE;

const _axios = axios.create({withCredentials: true});

const post = async function (url, params, setProgress) {

    const config = {
        onUploadProgress:
            setProgress
                ? function (progressEvent) {
                    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
                : undefined,
        withCredentials: true
    }

    try {
        return (await _axios.post(base_url + url, params, config)).data;
    } catch (error) {
        throw (error.response && error.response.data) || error;
    }
};

const get = async function (url, params) {
    try {
        return (await _axios.get(base_url + url, {withCredentials: true, params})).data;
    } catch (error) {
        throw (error.response && error.response.data) || error;
    }
};

const patch = async function (url, params) {
    try {
        return (await _axios.patch(base_url + url, params, {withCredentials: true})).data;
    } catch (error) {
        throw (error.response && error.response.data) || error;
    }
};

export {get, post, patch};
