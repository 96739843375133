import React, {useEffect, useRef, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import {OverlayTrigger, Tab, Tabs, Tooltip} from "react-bootstrap";
import Spinner from "../shared/Spinner.js";
import {seoService} from "../../services/seoService.js";
import {useDialogContext} from "../App.js";
import cogoToast from "cogo-toast";
import Select from "react-select";
import Values from "./Values";
/*import JSONInput from 'react-json-editor-ajrm';*/

const languages = {
    "en": "English",
    "pt": "Portuguese",
    "es": "Spanish",
    "it": "Italian"
}

const Config = ({inline}) => {

    const {setModal} = useDialogContext();
    const [loading, setLoading] = useState(true);

    const [cities, setCities] = useState([]);
    const [friendlyUrls, setFriendlyUrls] = useState([]);
    const friendlyUrlDefault = {
        language: "en",
        real: "",
        friendly: {},
        title: {},
        description: {},
        results_title: {},
        meta_title: {},
        meta_description: {},
        related: [],
        aliases: []
    };
    const editUrl = useRef({...friendlyUrlDefault});
    const editCityLinks = useRef({});

    const [integrations, setIntegrations] = useState([{"name": "habyt"}, {"name": "HelpHousing"}]);

    useEffect(() => {
        (async () => {
            let _friendlyURLs = await seoService.getFriendlyUrls();
            setFriendlyUrls(_friendlyURLs);
            setLoading(false);
        })();
        (async () => {
            let _cities = await seoService.getCities();
            setCities(_cities);
        })();
    }, []);

    const handleChange = (e) => {
        let val = e.target.value;
        let tname = e.target.name;
        if(tname.split("|").length > 1) {
            let [tnamespl, tkey] = tname.split("|");
            if(!editUrl.current[tnamespl]) editUrl.current[tnamespl] = {};
            editUrl.current[tnamespl][tkey] = val;
        } else {
            editUrl.current[tname] = val;
        }
    }

    const EditFriendlyUrl = ({editing}) => {
        return <form className={"edit-form"}>
            <div className="form-group mb-2">
                <label>Real URL</label>
                <input type="text" className="form-control" name={"real"} defaultValue={editUrl.current.real} onChange={handleChange}/>
            </div>
            <Tabs defaultActiveKey={"en"} id={"edit-friendly-url-lang-tabs"}>
                {Object.keys(languages).map(lang => {
                    return <Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className="form-group mb-2">
                                    <label>Friendly URL ({lang.toUpperCase()})</label>
                                    <input autoFocus type="text" className="form-control" name={(editing ? "new_friendly" : "friendly")+"|"+lang} defaultValue={editUrl.current.friendly[lang]} onChange={handleChange}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Title ({lang.toUpperCase()})</label>
                                    <input type="text" className="form-control" name={"title|"+lang} defaultValue={editUrl.current.title[lang]} onChange={handleChange}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Description ({lang.toUpperCase()})</label>
                                    <small>This will show up on the map, under the useful information section.</small>
                                    <textarea className="form-control" name={"description|"+lang} defaultValue={editUrl.current.description[lang]} onChange={handleChange}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Meta Title ({lang.toUpperCase()})</label>
                                    <small>This is the page title and meta title property on the page head.</small>
                                    <input type="text" className="form-control" name={"meta_title|"+lang} defaultValue={editUrl.current.meta_title[lang]} onChange={handleChange}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Meta Description ({lang.toUpperCase()})</label>
                                    <small>Same as the Meta Title but for the description.</small>
                                    <textarea className="form-control" name={"meta_description|"+lang} defaultValue={editUrl.current.meta_description[lang]} onChange={handleChange}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Results Title ({lang.toUpperCase()})</label>
                                    <input type="text" className="form-control" placeholder={"{{num}} listings for rent in Lisbon, Portugal"} name={"results_title|"+lang} defaultValue={editUrl.current.results_title[lang]} onChange={handleChange}/>
                                </div>
                            </div>
                        </div>
                    </Tab>
                })}
            </Tabs>
            <Select isMulti isClearable className={"mb-2 mt-2"} placeholder={"Related Links"} hideSelectedOptions={false} menuIsOpen
                    options={friendlyUrls.filter(p => p.language === "en" && p.friendly !== editUrl.current.friendly["en"]).map(p => ({value: p.friendly, label: p.title}))}
                    defaultValue={editUrl.current.related?.map(p => ({value: p, label: friendlyUrls.find(f => f.friendly === p)?.title||(""+p)}))}
                    onChange={(v) => handleChange({target: {name: "related", value: v?.map(p => p.value)||[]}})}/>
        </form>
    }

    const EditCityLinks = () => {
        const [addingLink, setAddingLink] = useState({});
        const [selectedLang, setSelectedLang] = useState("en");
        return <form className={"edit-form"}>
            <div className={"form-group mb-2"}>
                <small>These are the links that will show up on the city page.</small>
                <Tabs defaultActiveKey="en" id="edit-city-links-lang-tabs" onSelect={(l) => setSelectedLang(l)}>
                    {Object.keys(languages).map(lang => {
                        return <Tab key={lang} eventKey={lang} title={lang.toUpperCase()}>
                            <div className={"city-links"}>
                                {editCityLinks.current[lang].length === 0 ? <div className={"d-flex justify-content-center mt-3"}>
                                    <small>No links for this language.</small>
                                </div> : editCityLinks.current[lang].map((link, i) => {
                                    return (<div key={i} className={"city-link " + (link.up ? "up" : link.down ? "down" : "")}>
                                        <div className={"link-info"}>
                                            <div className={"link-label"}>{link.label}</div>
                                            <div className={"link-url"}>{link.url}</div>
                                        </div>
                                        <div className={"link-buttons"}>
                                            <div className={"link-button-delete"} onClick={() => {
                                                editCityLinks.current[lang].splice(i, 1);
                                                setAddingLink({});
                                            }}>
                                                <i className={"mdi mdi-delete"}/>
                                            </div>
                                            <div className={"link-pos"}>
                                                <div className={"pos-chevron"} style={{visibility: i === 0 ? "hidden" : "visible"}} onClick={() => {
                                                    editCityLinks.current[lang][i].up = true;
                                                    editCityLinks.current[lang][i - 1].down = true;
                                                    setAddingLink({});
                                                    setTimeout(() => {
                                                        delete editCityLinks.current[lang][i].up;
                                                        delete editCityLinks.current[lang][i - 1].down;
                                                        let temp = editCityLinks.current[lang][i - 1];
                                                        editCityLinks.current[lang][i - 1] = editCityLinks.current[lang][i];
                                                        editCityLinks.current[lang][i] = temp;
                                                        setAddingLink({});
                                                    }, 100);
                                                }}>
                                                    <i className={"mdi mdi-chevron-up"}/>
                                                </div>
                                                <div className={"pos-chevron"} style={{visibility: i === editCityLinks.current[lang].length - 1 ? "hidden" : "visible"}} onClick={() => {
                                                    editCityLinks.current[lang][i].down = true;
                                                    editCityLinks.current[lang][i + 1].up = true;
                                                    setAddingLink({});
                                                    setTimeout(() => {
                                                        delete editCityLinks.current[lang][i].down;
                                                        delete editCityLinks.current[lang][i + 1].up;
                                                        let temp = editCityLinks.current[lang][i + 1];
                                                        editCityLinks.current[lang][i + 1] = editCityLinks.current[lang][i];
                                                        editCityLinks.current[lang][i] = temp;
                                                        setAddingLink({});
                                                    }, 100);
                                                }}>
                                                    <i className={"mdi mdi-chevron-down"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>);
                                })}
                            </div>
                        </Tab>
                    })}
                </Tabs>
                {addingLink.type ? <div className={"d-flex flex-column mt-2"}>
                    {addingLink.type === "friendly" ? <div className={"edit-form"}>
                        <Select className={"mb-2"} placeholder={"Choose one link"}
                                options={friendlyUrls.map(p => ({value: p.friendly, label: p.title}))}
                                onChange={(v) => setAddingLink({
                                    type: "friendly",
                                    label: v?.label,
                                    url: "/" + v?.value
                                })}/>
                    </div> : <div className={"edit-form mt-2"}>
                        <div className="form-group mb-2">
                            <label>Label</label>
                            <input type="text" className="form-control" onChange={(e) => {
                                let value = e.target.value;
                                setAddingLink(prev => ({...prev, label: value}));
                            }}/>
                        </div>
                        <div className="form-group mb-2">
                            <label>URL</label>
                            <input type="text" className="form-control" onChange={(e) => {
                                let value = e.target.value;
                                setAddingLink(prev => ({...prev, url: value}));
                            }}/>
                        </div>
                    </div>}
                    <div className={"d-flex"}>
                        <div className={"btn btn-primary mr-2"} onClick={()=>{
                            editCityLinks.current[selectedLang].push({...addingLink});
                            setAddingLink({});
                        }}>OK</div>
                        <div className={"btn btn-secondary"} onClick={()=>setAddingLink({})}>Cancel</div>
                    </div>
                </div> : <div className={"d-flex mt-2"}>
                    <div className={"btn btn-primary mr-2"} onClick={()=>setAddingLink({type: "normal"})}><i className={"mdi mdi-plus"}/>Add Link</div>
                    <div className={"btn btn-success"} onClick={()=>setAddingLink({type: "friendly"})}><i className={"mdi mdi-plus"}/>Add Friendly URL</div>
                </div>}
            </div>
        </form>;
    }

    const handleAddNew = () => {
        editUrl.current = {...friendlyUrlDefault};
        setModal({
            title: "Add New Friendly URL",
            size: "md",
            message: <EditFriendlyUrl/>,
            onSubmit: async () => {
                try {
                    await seoService.createFriendlyUrl(editUrl.current);
                    setModal(m => ({...m, hide: true}));
                    setLoading(true);
                    let _friendlyURLs = await seoService.getFriendlyUrls();
                    setFriendlyUrls(_friendlyURLs);
                    setLoading(false);
                } catch (e) {
                    cogoToast.error('Error creating friendly URL: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }

    const extractCurrent = (friendlyUrl) => {
        let extrated = {
            language: "en",
            real: friendlyUrl.real,
            friendly: {en: friendlyUrl.friendly},
            title: {en: friendlyUrl.title},
            description: {en: friendlyUrl.description},
            results_title: {en: friendlyUrl.results_title},
            meta_title: {en: friendlyUrl.meta_title},
            meta_description: {en: friendlyUrl.meta_description},
            related: friendlyUrl.related,
            aliases: friendlyUrl.aliases
        };
        Object.keys(languages).forEach(lang => {
            if(lang === "en") return;
            let other_friendly = friendlyUrl.aliases[lang];
            let other = friendlyUrls.find(f => f.friendly === other_friendly);
            if (other) {
                extrated.friendly[lang] = other.friendly;
                extrated.title[lang] = other.title;
                extrated.description[lang] = other.description;
                extrated.results_title[lang] = other.results_title;
                extrated.meta_title[lang] = other.meta_title;
                extrated.meta_description[lang] = other.meta_description;
            }
        })
        return extrated;
    }

    const handleClone = (friendlyUrl) => () => {
        editUrl.current = {...extractCurrent(friendlyUrl)};
        setModal({
            title: "Clone Friendly URL",
            size: "md",
            message: <EditFriendlyUrl/>,
            onSubmit: async () => {
                try {
                    await seoService.createFriendlyUrl(editUrl.current);
                    setModal(m => ({...m, hide: true}));
                    setLoading(true);
                    let _friendlyURLs = await seoService.getFriendlyUrls();
                    setFriendlyUrls(_friendlyURLs);
                    setLoading(false);
                } catch (e) {
                    cogoToast.error('Error cloning friendly URL: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }

            }
        });
    }
    const handleEdit = (friendlyUrl) => () => {
        editUrl.current = {...extractCurrent(friendlyUrl)};
        setModal({
            title: "Edit Friendly URL",
            size: "md",
            message: <EditFriendlyUrl editing={true}/>,
            onSubmit: async () => {
                try {
                    await seoService.editFriendlyUrl(editUrl.current);
                    setModal(m => ({...m, hide: true}));
                    setLoading(true);
                    let _friendlyURLs = await seoService.getFriendlyUrls();
                    setFriendlyUrls(_friendlyURLs);
                    setLoading(false);
                } catch (e) {
                    cogoToast.error('Error editing friendly URL: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }
    const handleDelete = (friendlyUrl) => () => {
        setModal({
            title: "Delete Friendly URL",
            size: "md",
            message: <div>Are you sure you want to delete <b>/{friendlyUrl.friendly}</b>?</div>,
            onSubmit: async () => {
                try {
                    await seoService.deleteFriendlyUrl(friendlyUrl.friendly);
                    setModal(m => ({...m, hide: true}));
                    setLoading(true);
                    let _friendlyURLs = await seoService.getFriendlyUrls();
                    setFriendlyUrls(_friendlyURLs);
                    setLoading(false);
                } catch (e) {
                    cogoToast.error('Error deleting friendly URL: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }

    const handleEditCityLinks = (city) => () => {
        Object.keys(languages).forEach(lang => {
            editCityLinks.current[lang] = [];
        })
        city.links.slice().forEach(link => {
            let lang = link.lang || "en";
            editCityLinks.current[lang].push({...link});
        });
        setModal({
            title: "Edit City Links - " + city.name,
            size: "md",
            message: <EditCityLinks/>,
            onSubmit: async () => {
                try {
                    let cityLinks = [];
                    Object.keys(editCityLinks.current).forEach(lang => {
                        editCityLinks.current[lang].forEach(link => {
                            cityLinks.push({...link, lang});
                        });
                    });
                    await seoService.editCityLinks(city.id, cityLinks);
                    setModal(m => ({...m, hide: true}));
                    setCities(await seoService.getCities());
                } catch (e) {
                    cogoToast.error('Error editing city links: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }

    return (
        <PageWrapper title={"Config"}
                     breadcrumbs={[
                         {title: "Config"}
                     ]}
                     inline={inline} noCard>
            <Tabs defaultActiveKey="urls" id="uncontrolled-tab-example">
                <Tab eventKey="urls" title="Friendly URLs">
                    <div className="card">
                        <div  className="card-body" style={{overflowX: 'auto'}}>
                            <button className={"btn btn-success mb-2"} onClick={handleAddNew}><i className={"mdi mdi-plus"}/>Add friendly URL</button>
                            {loading ? <Spinner inline/> : <>
                                <table className={"table border"}>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Friendly URL</th>
                                            <th>Real URL</th>
                                            <th>Title</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {friendlyUrls.filter(f => f.language === "en").map((friendlyUrl, index) => {
                                        return (<tr key={friendlyUrl.friendly}>
                                            <td style={{textTransform:"capitalize"}}>{friendlyUrl.type}</td>
                                            <td>
                                                <OverlayTrigger overlay={<Tooltip>{Object.values(friendlyUrl.aliases).join("\n")}</Tooltip>}>
                                                    <a href={process.env.REACT_APP_STUDENT_URL + (friendlyUrl.type === "house" ? "/house/" : "/") + friendlyUrl.friendly} target={"_blank"}>{friendlyUrl.friendly}</a>
                                                </OverlayTrigger>
                                            </td>
                                            <td style={{maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis'}}>{friendlyUrl.real}</td>
                                            <td>{friendlyUrl.title["en"]}</td>
                                            <td>
                                                <div className={"d-flex justify-content-end table_actions"}>
                                                    <OverlayTrigger overlay={<Tooltip>Clone</Tooltip>}>
                                                        <i className={"mdi mdi-content-copy"} onClick={handleClone(friendlyUrl)}></i>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                        <i className={"mdi mdi-pencil"} onClick={handleEdit(friendlyUrl)}></i>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                        <i className={"mdi mdi-trash-can"} onClick={handleDelete(friendlyUrl)}></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </>}
                            <div></div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="cities" title="City Links">
                    <div className="card">
                        <div  className="card-body">
                            <table className={"table border"}>
                                <thead>
                                <tr>
                                    <th>City</th>
                                    <th>Country</th>
                                    <th>Links</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {cities.map((city, index) => {
                                    return (<tr key={city.id}>
                                        <td>{city.name}</td>
                                        <td>{city.country}</td>
                                        <td>
                                            <div className={"d-flex flex-column"}>{city.links.map(l => <span key={l.url}>{l.label}</span>)}</div>
                                        </td>
                                        <td>
                                            <div className={"d-flex justify-content-end table_actions"}>
                                                <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                    <i className={"mdi mdi-pencil"} onClick={handleEditCityLinks(city)}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="integrations" title="Integrations">
                    <div className="card">
                        <div  className="card-body">
                            {/*integrations.map(integration => <div className={"integration-editor"}>
                                <h5>{integration.name}</h5>
                                <JSONInput
                                    id={"habyt"}
                                    placeholder={{
                                        "sections": [
                                            {
                                                "wrapper": "bgWrapper1",
                                                "components": ["photos", "houseDetails", "roomDetails"]
                                            },
                                            {
                                                "wrapper": "bgWrapper2",
                                                "components": ["about", "inProperty", "houseRules", "buildingDetails", "moreInfo"]
                                            },
                                            {
                                                "wrapper": "bgWrapper3",
                                                "components": ["availability", "otherRooms", "faq"]
                                            }
                                        ],
                                        "config": {
                                            "houseDetails": ["type", "rooms", "billsIncluded", "wifi", "floor", "area"],
                                            "inProperty": ["kitchen", "livingRoom", "library", "gym"],
                                            "roomDetails": ["area", "closet", "accommodation", "mattress"],
                                            "buildingDetails": ["bedrooms", "laundryLounge", "communitySpace", "coworkSpace", "diningSpace", "cutlery"]
                                        }
                                    }}
                                    height={"450px"}
                                    width={"100%"}
                                />
                            </div>)*/}
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="values" title="Values">
                   <Values setModal={setModal}/>
                </Tab>
            </Tabs>
        </PageWrapper>
    )
}

export default Config;
