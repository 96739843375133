import React from 'react';
import {Form, FormGroup} from "react-bootstrap";


const LivingRoomForm = ({livingRoom, numLivingRooms, index, handleChange}) =>{
    let livingRoomName = "livingRoom_"+index;

    return(
        <>
            <label
                className={"PropertyEdit_column-label-seconday"}>{'Living room ' + (numLivingRooms > 1 ? index+1 : '')}</label>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Living room size (&#13217;):</label>
                <Form.Control type="number" className="form-control" min={0} name="size" id={livingRoomName+"-size"}
                              defaultValue={livingRoom.size ? livingRoom.size : null}
                              placeholder="Ex.: 30 m2" onChange={(e)=>handleChange(livingRoomName, e.target.name, e.target.valueAsNumber)}/>
            </FormGroup>
            <div className={'d-flex flex-row Area-features'}  onChange={(e)=>handleChange(livingRoomName, e.target.name, e.target.value)}>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Couch</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.sofa} className="form-check-input" name={'sofa'}
                                       value={!livingRoom.sofa}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Sofa bed</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.sofaBed} className="form-check-input" name={'sofaBed'}
                                       value={!livingRoom.sofaBed}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Table</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.table} className="form-check-input" name={'table'}
                                       value={!livingRoom.table}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Chairs</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.chairs} className="form-check-input" name={'chairs'}
                                       value={!livingRoom.chairs}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Desk</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.desk} className="form-check-input" name={'desk'}
                                       value={!livingRoom.desk}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'d-flex flex-column'}>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>TV</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.tv} className="form-check-input" name={'tv'}
                                       value={!livingRoom.tv}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Air conditioner</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.ac} className="form-check-input" name={'ac'}
                                       value={!livingRoom.ac}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Heater</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.heater} className="form-check-input" name={'heater'}
                                       value={!livingRoom.heater}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Window to the outside</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.window} className="form-check-input" name={'window'}
                                       value={!livingRoom.window}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <div className={"d-inline-flex justify-content-between"}>
                        <label className={"PropertyEdit_input-label mt-2 mr-1"}>Balcony</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input type="checkbox" defaultChecked={livingRoom.balcony} className="form-check-input" name={'balcony'}
                                       value={!livingRoom.balcony}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LivingRoomForm;