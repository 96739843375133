import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {propertyService} from "../../services/propertyService";
import cogoToast from "cogo-toast";


const Amenities = ({property, handleChange, submitted, changedFields}) => {

    const {laundryService, laundryServiceIncluded, laundryServiceNotAvailable, laundryServiceNotIncluded, photos, numKitchen, numLivingRoom, numBathroom} = property;

    const {t} = useTranslation();

    let commoditiesOptions1 = ['wifi', 'dishWashingMachine', 'laundryMachine', 'dryingMachine', 'dryingRack', 'ironingBoard', 'tv', 'cableTv', 'towels', 'soap', 'elevator', 'fireExtinguisher',
        'privateParking'];
    let commoditiesOptions2 = ['paidStreetParking', 'firstAidKit', 'handSanitizer', 'cctv', 'reception', 'coworkSpace',
        'library', 'photocopier', 'barLounge', 'wellbeingActivities', 'multimediaRoom', 'cinemaRoom', 'freeStreetParking']


    const getAreasOptions = () => {
        let areasOptions = [];
        for (let i = 0; i < numKitchen; i++) {
            areasOptions.push({value: 'kitchen_' + i, label: 'Kitchen ' + (numKitchen > 1 ? i + 1 : '')})
        }
        for (let i = 0; i < numLivingRoom; i++) {
            areasOptions.push({value: 'livingRoom_' + i, label: 'Living room ' + (numLivingRoom > 1 ? i + 1 : '')})
        }
        for (let i = 0; i < numBathroom; i++) {
            areasOptions.push({value: 'bathroom_' + i, label: 'Bathroom ' + (numBathroom > 1 ? i + 1 : '')})
        }
        areasOptions.push({value: 'exteriorSpaces_0', label: 'Exterior Spaces'});
        areasOptions.push({
            value: 'otherSpaces_0',
            label: 'Other Spaces'
        });
        return areasOptions;
    };

    const [availableAreas, setAvailableAreas] = useState();

    useEffect(() => {
        if(!availableAreas || submitted)setAvailableAreas(getAreasOptions()); // para apenas ser possível adicionar fotos às divisões realmente gravadas e não às adicionadas no momento
    }, [submitted])


    const [imageUploaded, setImageUploaded] = useState();
    const [area, setArea] = useState();
    const [error, setError] = useState();
    const[uploadingImage, setUploadingImage] = useState(false);
    const [areaPhotos, setAreaPhotos] = useState({
        value: "kitchen_0",
        label: 'Kitchen ' + (property.numKitchen > 1 ? 1 : '')
    })

    const uploadImages = async (area, image) => {
        if (!area || !image) {
            setError("Choose division and image.")
            return;
        }
        setError(null);
        setUploadingImage(true);
        if (!photos) handleChange('photos', {});
        let photosToSave = new FormData();
        let index = property.photos?.[area] ? Object.keys(property.photos[area]).length : 0;
        photosToSave.append(area + "[" + index + "]", image)
        if (changedFields.hasOwnProperty('photos')) await propertyService.editProperty({'id':changedFields['id'],'photos': changedFields['photos']});
        propertyService.uploadPropertyPhotos(photosToSave, property.id).then(
            value => {
                let newPhotos = value.property.photos
                handleChange('photos', newPhotos)
                setUploadingImage(false);
                cogoToast.success('Photo successfully added.', {
                    hideAfter: 3,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        )
        setImageUploaded(null);
    };

    const isFeaturedPhoto = (photo) => {
        if(!photos['featured_0'] || !photos['featured_0'][0]) return false;
        return photos['featured_0'][0]['original'] === photo['original'];
    }

    const deleteImageHandler = (area, index) => {
        let photo = photos[area][index];
        if(isFeaturedPhoto(photo)) delete photos['featured_0'][0];
        let length = Object.keys(photos[area]).length;
        delete photos[area][index];
        if (!( length > 0)) delete photos[area];
        let idx = parseInt(index)
        if(idx !== length - 1){
            for(let i=idx; i<length-1; i++){
                photos[area][i]=photos[area][i+1]
                delete photos[area][i+1]
            }
        }
        handleChange('photos', photos);
    }


    const highlightImageHandler = (photo) => {
        if(!photos['featured_0']) photos['featured_0'] = {}
        photos['featured_0'][0] = photo;
        handleChange('photos', photos);
    }

    const photoPositionHandler = (area, mov, index) => {
        let photo = photos[area][index];
        photos[area][index] = photos[area][index + mov];
        photos[area][index + mov] = photo;
        handleChange('photos', photos);
    }

    return (
        <>
            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label"}>Amenities</label>
                <label className={"PropertyEdit_column-label-seconday"}>Services</label>
                <div className={"PropertyEdit_row-inputs"}>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>Laundry service</label>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="checkbox" defaultChecked={laundryService} className="form-check-input"
                                   name={'laundryService'} onChange={() => {
                                handleChange('laundryService', !laundryService)
                            }}/>
                            <i className="input-helper"/>
                        </label>
                    </div>
                </div>
                {laundryService===true &&
                <div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="laundryService"
                                   id="laundryServiceIncluded"
                                   defaultChecked={laundryServiceIncluded} value={!laundryServiceIncluded}
                                   onChange={()=>{
                                       handleChange('laundryServiceIncluded', !laundryServiceIncluded);
                                       handleChange('laundryServiceNotAvailable', false);
                                       handleChange('laundryServiceNotIncluded', false);
                                   }}
                            />Included in the price
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="laundryService"
                                   id="cleaningLady_no" defaultChecked={laundryServiceNotIncluded}
                                   onChange={()=>{
                                       handleChange('laundryServiceIncluded', false);
                                       handleChange('laundryServiceNotAvailable', false);
                                       handleChange('laundryServiceNotIncluded', !laundryServiceNotIncluded);
                                   }}
                            />
                            Available but not included in the price
                            <i className="input-helper"></i>
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="laundryService"
                                   id="cleaningLady_non_available" defaultChecked={laundryServiceNotAvailable}
                                   onChange={()=>{
                                       handleChange('laundryServiceIncluded', false);
                                       handleChange('laundryServiceNotAvailable', !laundryServiceNotAvailable);
                                       handleChange('laundryServiceNotIncluded', false);
                                   }}
                            />
                            Not available
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>}

            </div>

            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label-seconday"}>Amenities</label>
                <div className={'d-flex flex-row Area-features'}
                     onChange={(e) => handleChange(e.target.name, e.target.value)}>
                    <div className={'d-flex flex-column'}>
                        {commoditiesOptions1.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"commodity-"+value}>
                                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>{t('commodities.'+value)}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={property[value]}
                                                   className="form-check-input" name={value}
                                                   value={!property[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={'d-flex flex-column'}>
                        {commoditiesOptions2.map(value => {
                            return (
                                <div className={"d-inline-flex justify-content-between"} key={"commodity-"+value}>
                                    <label className={"PropertyEdit_input-label mt-2 mr-1"}>{t('commodities.'+value)}</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" defaultChecked={property[value]}
                                                   className="form-check-input" name={value} value={!property[value]}/>
                                            <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className={"PropertyEdit_column mr-2"}>
                <label className={"PropertyEdit_column-label"}>Photographs</label>
                <small>Save the new divisions so that you can add photos to them.</small>
                <div className={"mb-3"}>
                    <label className={"PropertyEdit_column-label-seconday"}>Featured Photo</label>
                    {photos?.['featured_0'] && photos['featured_0'][0] ?
                    <div className="file-image">
                        <span style={{backgroundImage: `url(${photos['featured_0'][0]['sm']})`}}/>
                    </div> :
                        <p>Choose a photo to highlight.</p>}
                </div>
                <div className={"f-flex flex-column mb-3"}>
                    <label className={"PropertyEdit_column-label-seconday"}>Add photo</label>
                    {error && <p className={"text-danger"}>{error}</p>}
                    <div className={"d-flex flex-row mb-2"}>
                        <Select
                            className={"w-100"}
                            placeholder={"Choose the area"}
                            options={availableAreas}
                            onChange={option => {
                                setArea(option.value);
                                setError(null);
                            }}
                        />
                    </div>
                    <Form.Group>
                        <div className="custom-file">
                            <input type="file" accept={"image/*"} className="form-control visibility-hidden"
                                   id="customFileLang" lang="es" onChange={(e) => {
                                       setImageUploaded(e.target.files[0]);
                                       setError(null);
                                   }}/>
                            <label className="custom-file-label"
                                   style={{zIndex:0}}
                                   htmlFor="customFileLang">{imageUploaded?.name || "Choose the image"}</label>
                        </div>
                    </Form.Group>
                    <button type={'button'} className={"btn btn-primary btn-rounded"} disabled={uploadingImage || !imageUploaded}
                            onClick={() => uploadImages(area, imageUploaded)}><i className="fa fa-upload mr-2"/>{uploadingImage ? 'Uploading...': 'Upload'}
                    </button>
                </div>
                <div className={"d-flex flex-column"}>
                    <label className={"PropertyEdit_column-label-seconday"}>Remove photo</label>
                    {availableAreas &&
                    <div className={"d-flex flex-row mb-4"}>
                        <Select
                            defaultValue={property.numKitchen > 0 && areaPhotos}
                            className={"w-100"}
                            placeholder={"Choose the area"}
                            options={availableAreas}
                            isOptionDisabled={(option) => !(photos?.hasOwnProperty(option.value) && Object.keys(photos?.[option.value]).length > 0)}
                            onChange={option => setAreaPhotos(option)}
                        />
                    </div>}
                    <div className={"display-photos"}>
                        {photos?.[areaPhotos.value] && Object.keys(photos[areaPhotos.value]).map((photo,index) => {
                            return (
                                <div key={property.id + "-" + areaPhotos.value + "-" + photo}
                                     className="file-image mr-3 mb-2">
                                    {Object.keys(photos[areaPhotos.value]).length > 1 && index < Object.keys(photos[areaPhotos.value]).length - 1 &&
                                    <div className={"move-right image-action clickable"}
                                         onClick={() => photoPositionHandler(areaPhotos.value, 1, index)}>
                                        <i className={"mdi mdi-arrow-right"}/>
                                    </div>}
                                    {Object.keys(photos[areaPhotos.value]).length > 1 && index > 0 &&
                                    <div className={"move-left image-action clickable"} onClick={() => photoPositionHandler(areaPhotos.value, -1, index)}>
                                        <i className={"mdi mdi-arrow-left"}/>
                                    </div>}
                                    <div className={"delete-image image-action clickable"}
                                         onClick={() => deleteImageHandler(areaPhotos.value, photo)}><OverlayTrigger
                                        overlay={<Tooltip>Delete</Tooltip>}><i
                                        className={"mdi mdi mdi-delete"}/></OverlayTrigger></div>
                                    <div className={"highlight-image image-action clickable"}
                                         onClick={() => highlightImageHandler(photos[areaPhotos.value][photo])}>
                                        <OverlayTrigger overlay={<Tooltip>Feature</Tooltip>}><i
                                            className={"mdi mdi-arrow-top-right"}/></OverlayTrigger></div>
                                    <span
                                        style={{backgroundImage: `url(${photos[areaPhotos.value][photo]['sm']})`}}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Amenities;