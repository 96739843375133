import React, {useMemo} from 'react'
import {Link} from "react-router-dom";
import moment from "moment";
import defaultAvatar from "../../assets/images/avatar.png";
import {useTranslation} from "react-i18next";
import {visitService} from "../../services/visitService";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toCapitalize} from "../shared/utils";
import {utils} from "../../utils";


const VisitCard = ({data: visit, acceptVisit, rejectVisit, getMonthlyRent}) => {
    const {t} = useTranslation();

    let property = visit.property;
    let propertyPhoto = visit.photo;
    let userPhoto = visit.user?.photo || defaultAvatar;
    let landlordPhoto = visit.landlord?.photo || defaultAvatar;
    let roomNumber = visit.roomNumber;
    let age = new Date().getFullYear() - visit.user?.birthdate?.split("-")[1] + " years";

    const expirationDate = useMemo(() => {
        return utils.getExpirationDate(visit.suggestionType ? visit.acceptedDate : visit.requestDate);
    }, [visit]);


    const handleJoinCall = () => {
        window.open(`${process.env.REACT_APP_STUDENT_URL}/video/${visit.id}?atoken=${visit.operator_token}`, '_blank');
    }

    return (
        <div className="Card card" style={{height: "170px"}}>
            <Link to={`/property/${property?.id}${visit.room_id ? "?room_id="+visit.room_id : ""}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <div style={{backgroundImage: `url('${propertyPhoto}')`}} className={"Card_featured-photo"}>
                {visit.type === "onsite" ? <div className={"onsite mt-1"}>
                        <div className={"mdi mdi-nature-people mr-2"}/>
                        In person</div>
                    : <div className={"video mt-1"}>
                        <div className={"mdi mdi-video-outline mr-2"}/>
                        Video call</div>}
                <div className={"bottom-image mb-1 ml-1"}>{visit.id?.split("-")[0]}</div>
            </div>
            </Link>
            <div className="Card_content">
                <div className={"Card_property"}>
                    {/*{visit.title &&*/}
                    {/*<label>*/}
                    {/*    <Link to={`/property/${property?.id}${visit.room_id ? "?room_id="+visit.room_id : ""}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>{clampText(visit?.title[localStorage.getItem('i18nextLng').split('-')[0]], 50)}</Link>*/}
                    {/*    <OverlayTrigger overlay={<Tooltip>Pré-visualizar anúncio</Tooltip>}>*/}
                    {/*        <i className={"mdi mdi mdi-arrow-top-right clickable ml-2"} style={{ color: '#2196f3'}} onClick={()=> propertyService.previewProperty(property, roomName)}/>*/}
                    {/*    </OverlayTrigger>*/}
                    {/*</label>}*/}
                    <div><i
                        className="mdi mdi-map-marker mr-2"/>{property?.address}{property?.floor ? ", " + property?.floor + " Floor" : ""}{(' | Room ' + roomNumber)} | {property?.region}
                    </div>
                    <div><i
                        className="mdi mdi-phone mr-2"/>{(visit.landlord?.dialCode ? visit.landlord?.dialCode + " " : "") + visit.landlord?.phone}
                    </div>
                    <div><i
                        className="mdi mdi-home mr-2"/>ID {property?.id?.split("-")[0]} {visit.room_id && ('| Room ' + roomNumber)}
                    </div>
                    <div><i className="mdi mdi-coin mr-2"/>Monthly rent: {getMonthlyRent(visit)}</div>
                    <div className={"Card_property-landlord"}>
                        <div style={{backgroundImage: 'url(' + (landlordPhoto) + ')'}}
                             className="rounded-circle landlord-photo mr-2"/>
                        <Link to={`/landlords/${visit.landlord?.id}`}>{visit.landlord?.name}</Link>
                    </div>
                </div>
                <div className={"Card_tenant"}>
                    <div className={"Card_tenant-image-wrapper"}>
                        <div style={{backgroundImage: `url(${userPhoto})`}} className={"Card_tenant-image"}/>
                    </div>
                    <div className={"Card_tenant-info"}>
                        <label>{visit.user?.name} <Link to={"/tenants/" + visit.user?.id}>View Profile</Link></label>
                        <div>{t("country." + visit.user?.nationality)} - {toCapitalize(visit.user?.gender)} - {age?.split("-")}</div>
                        {visit.user?.university &&
                        <div>{toCapitalize(visit.user?.occupation)} {(visit.user?.occupation === "student" && visit.user?.university) ? "at " + visit.user?.university?.split(" - ")[0] : "at " + visit.user?.occupation_detail}</div>}
                        <div className={"mdi mdi-account"}>
                            <strong className={"ml-1"}>Nr Tenants:</strong> {(visit.numTenants)}
                        </div>
                        <div className={"mdi mdi-calendar"}><strong className={"ml-1"}>Date of
                            request:</strong> {moment(visit.requestDate).format("DD-MM-YYYY HH:mm")}</div>
                        <div className={"mdi mdi-calendar"}><strong className={"ml-1"}>Date of
                            entry:</strong> {moment(visit.moveIn).format("DD-MM-YYYY")}</div>
                        <div className={"mdi mdi-calendar"}><strong className={"ml-1"}>Date of
                            exit:</strong> {moment(visit.moveOut).format("DD-MM-YYYY")}</div>
                    </div>
                </div>
                <div className={"Card_info"}>
                    <div className={"mt-1 mb-3"}>
                        <strong>{visit.suggestedDates ? "Dates suggested by the landlord" : "Dates requested by the tenant"}</strong>
                    </div>
                    <div className={"Card_info info-dates"}>
                        {(visit.suggestedDates || visit.chosenDates)?.map((dt, idx) => {
                            return <OverlayTrigger overlay={<Tooltip>This is the local timezone where this accommodation is located</Tooltip>}>
                                <div key={"chosenDate-" + idx} className={"badge badge-info date-row mb-1"}>
                                    <span>{moment(dt.date).format("DD MMM YYYY")} </span>
                                    <span className={"text-dark"}>{dt.start} - {dt.end}h</span>
                                </div>
                            </OverlayTrigger>
                        })}
                    </div>
                </div>
                <div className={"Card_actions"}>
                    {(visit.tenantSelectedDate || visit.selectedDate) && !visit.rejectedDate && <div className={"info-box"}>
                        <OverlayTrigger overlay={<Tooltip>This is the local timezone where this accommodation is located</Tooltip>}><span>Visit on: <strong>{moment((visit.tenantSelectedDate || visit.selectedDate)).format("DD/MM/YY HH:mm")}</strong></span></OverlayTrigger>
                        <span>Scheduled at: <strong>{moment(visit.acceptedDate).format("DD/MM/YY HH:mm")}</strong></span>
                    </div>}
                    {(visit.rejectedDate || (visitService.isExpired(visit) && !visit.selectedDate && !visit.tenantSelectedDate)) &&
                    <div className={"info-box"}>
                        <span>Cancelled on the day: {moment(visit.rejectedDate || moment(visit.requestDate).add(1, 'day')).format("DD/MM/YYYY")}</span>
                        <span><strong>Reason:</strong> {t('visit_card.reject_motive.' + (visit.rejectedMotive || "expired"))}</span>
                    </div>}
                    {(!visitService.isExpired(visit) && ((visit.suggestedDates && !visit.tenantSelectedDate) || !visit.selectedDate) && !visit.rejectedDate) &&
                    <OverlayTrigger overlay={<Tooltip>Expires on {expirationDate.format("DD/MM/YYYY")} at {expirationDate.format("HH:mm")} hours</Tooltip>}>
                        <div>
                            <i className="mdi mdi-history mr-2"/>Expires in {expirationDate.endOf("day").diff(moment().startOf("day"), 'day')} days
                        </div>
                    </OverlayTrigger>}
                    {(!visitService.isExpired(visit) && !visit.selectedDate && !visit.rejectedDate && !visit.suggestedDates) &&
                        <OverlayTrigger
                            overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                Please impersonate to complete this action.
                            </Tooltip>}>
                            <div className={"action-button action-schedule"} onClick={acceptVisit(visit)} disabled>
                                <span className={"mdi mdi-bell-outline mr-2"}/>
                                <span>Schedule a visit</span>
                            </div>
                        </OverlayTrigger>}
                    {(visit.selectedDate || visit.tenantSelectedDate) && !visit.rejectedDate && visit.type === "livecall" &&
                    <div className={"action-button action-join"} onClick={handleJoinCall}>
                        <span className={"mdi mdi-video-outline mr-2"}/>
                        <span>Enter call</span>
                    </div>}
                    {visit.selectedDate ? ((!visit.selectedDate && !visit.tenantSelectedDate && !visitService.isExpired(visit)) || (moment(visit.selectedDate || visit.tenantSelectedDate).isAfter(moment()) && !visit.rejectedDate)) && !visit.rejectedDate &&
                        <span className={"mt-2"}
                              onClick={rejectVisit(visit)}>{"Cancel"}</span> : ((!visit.selectedDate && !visit.tenantSelectedDate && !visitService.isExpired(visit)) || (moment(visit.selectedDate || visit.tenantSelectedDate).isAfter(moment()) && !visit.rejectedDate)) && !visit.rejectedDate &&
                        <OverlayTrigger
                            overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                Please impersonate to complete this action.
                            </Tooltip>}>
                        <span className={"mt-2"}
                              onClick={rejectVisit(visit)} disabled>{"Reject visit request"}</span>
                        </OverlayTrigger>}
                </div>
                <div className={"Card_extra"}>
                    <Dropdown drop={"start"}>
                        <Dropdown.Toggle variant="" id="dropdownMenuButton1"/>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(visit.id);
                                console.log("visit id copied to clipboard");
                            }}>Copy ID</Dropdown.Item>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(JSON.stringify(visit));
                                console.log("visit copied to clipboard");
                            }}>Copy JSON</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{
                                window["visit"] = visit;
                                console.log("visit stored as variable", '"visit"');
                            }}>Store as variable</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
};

export default VisitCard;
