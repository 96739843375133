import React, {useState} from 'react'
import {useTranslation} from 'react-i18next';
import {useDialogContext} from "../../App";
import DataCardTable from "../../components/DataCardTable";
import {ambassadorService} from "../../../services/ambassadorService";
import moment from 'moment';
import Spinner from "../../shared/Spinner";
import cogoToast from "cogo-toast";
import {useAuth} from "../../../services/authService";
import FiltersSection from "../../components/FiltersSection";


const Payments = () => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();
    const [loading, setLoading] = useState(null);
    const {user} = useAuth();

    const conditionalRowStyles = [
        {
            when: row => (!row.paid),
            style: {
                backgroundColor: '#FCE5C1',
            },
        },
        {
            when: row => (row.paid),
            style: {
                backgroundColor: '#c2fabd',
            },
        }
    ];

    const handlePayWithdrawal = async (withdrawal) => {
        try {
            setLoading(withdrawal.id);
            await ambassadorService.payWithdrawal(withdrawal.ambassador_id, withdrawal.id);
            withdrawal.paid = new Date();
            cogoToast.success('Payment marked as paid!', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            })
        } catch (e) {
            cogoToast.error("Error trying to pay!", {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        } finally {
            setLoading(null);
        }
    }

    const dataColumns = [
        {
            name: 'ID',
            width: "100px",
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: "Request Date",
            selector: row => row.date,
            cell: row => <div>{moment(row.date).format("DD-MM-YY")}</div>
        },
        {
            name: "Code",
            selector: row => row.code,
            cell: row => <div>{row.code}</div>
        },
        {
            name: "Email",
            selector: row => row.email,
            cell: row => <div>{row.email}</div>
        },
        {
            name: "Total Paid",
            selector: row => row.total_paid,
            cell: row => <div>{row.total_paid}</div>
        },
        {
            name: "Still To Redeem",
            selector: row => row.pending_value,
            cell: row => <div>{row.pending_value}</div>
        },
        {
            name: "To be Paid",
            selector: row => row.value,
            cell: row => <div>{row.value}</div>
        },
        {
            name: "Payment Done",
            selector: row => row.paid,
            cell: row => <div>{row.paid ? "Yes" : <button className={"btn btn-success"} disabled={!!loading || !user.permissions.includes("ambassadors_pay")} onClick={() => handlePayWithdrawal(row)}>{loading === row.id ? <Spinner inline small/> : "Pay"}</button>}</div>
        },
        {
            name: "Payment Date",
            selector: row => row.paid,
            cell: row => <div>{row.paid ? moment(row.paid).format("DD-MM-YY HH:mm") : "-"}</div>
        }

    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("payments_filters")) || {"hidePaid": "true"});

    return (
        <DataCardTable
            fixedFirstColumn
            columns={dataColumns}
            dataType={"payments"}
            fetchData={ambassadorService.listPayments}
            filters={filters}
            setFilters={setFilters}
            conditionalRowStyles={conditionalRowStyles}
            showFilters={true}
        />

    )

}

export default Payments;
