import React, {useEffect, useMemo, useState} from "react";
import Details from "../property-edit/Details";
import AddressSection from "../property-edit/AddressSection";
import VisitsSection from "../property-edit/VisitsSection";
import {useLocation, useParams} from "react-router-dom/cjs/react-router-dom";
import {propertyService} from "../../services/propertyService";
import PageWrapper from "./PageWrapper";
import Spinner from "../shared/Spinner";
import Rules from "../property-edit/Rules";
import PropertyAreas from "../property-edit/PropertyAreas";
import Amenities from "../property-edit/Amenities";
import RoomForm from "../property-edit/RoomForm";
import {useDialogContext} from "../App";
import cogoToast from "cogo-toast";
import FieldsInfo from "../property-edit/FieldsInfo";
import jsonDiff from "json-diff";
import RichTextEditor from "./RichTextEditor";
import RoomResidents from "./RoomResidents";

const useQuery = () => {
    let searchParams = {};
    new URLSearchParams(useLocation().search).forEach((value, key) => {
        searchParams[key] = value;
    });
    return searchParams;
};

const PropertyEdit = () => {

    let {property_id, room_id} = useParams();
    const [property, setProperty] = useState(null);
    const [originalProperty, setOriginalProperty] = useState(null)
    const [changedFields, setChangedFields] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const query = useQuery();
    let roomId= query.room_id;

    useEffect(() => {
        if(localStorage.getItem("property_id") !== property_id){
            localStorage.clear();
            localStorage.setItem("property_id", property_id);
        }
    }, [property_id])

    useEffect(() => {
        (async ()=>{
            let _property = await propertyService.getPropertyById(property_id);
            if(!_property.property.rooms) _property.property.rooms = {};
            setProperty(_property.property);
            setOriginalProperty(JSON.parse(JSON.stringify(_property.property)))
            setChangedFields(prev => {
                return {
                    ...prev,
                    ['id']: _property.property.id
                }
            });
            if(roomId) {
                document.getElementById(roomId).scrollIntoView({block: "center", behavior: "smooth"})
            }
        })();
    }, [])


    const handleFieldChange = (field, value) => {
        setProperty(prev => {
            return {
                ...prev,
                [field]: value
            }
        });

        if(field === 'accept_visitors'){
            if(jsonDiff.diff(originalProperty?.[field], value)){
                setChangedFields(prev => {
                    return {
                        ...prev,
                        [field]: value
                    }
                })
            }
            if(!value) {
                delete changedFields["visit_weekdays"];
            }
            if(!jsonDiff.diff(originalProperty?.[field], value)) {
                delete changedFields[field];
            }
            if(value && (jsonDiff.diff(originalProperty?.[field], value) || jsonDiff.diff(originalProperty?.["visit_weekdays"], property["visit_weekdays"]))) {
                setChangedFields(prev => {
                    return {
                        ...prev,
                        ["visit_weekdays"]: property["visit_weekdays"]
                    }
                })
            }
            return;
        }

        if (!jsonDiff.diff(originalProperty?.[field], value)) {
            if(field === 'visit_weekdays' && changedFields['accept_visitors']) return;
            delete changedFields[field];
            return;
        }

        setChangedFields(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const {setModal} = useDialogContext();

    const validateProperty = () =>{
        setModal({
            title: "Validate Property",
            size: "md",
            message: <>You want to <strong>validate</strong> the property?</> ,
            onSubmit: async () => {
                await propertyService.validateProperty(property.id)
                handleFieldChange('validated', true);
                setModal(m => ({...m, hide: true}));
            }

        })
    }

    const disableProperty = (property) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>enable</strong> the property?</>,
            message: <>The listing will no longer be visible on the site.
            </>,
            onSubmit: async () => {
                await propertyService.disableProperty(property.id);
                property.disabled = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Property disabled.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const enableProperty = (property) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>enable</strong> the property?</>,
            message: <>
                The listing will now be visible on the site.</>,
            onSubmit: async () => {
                await propertyService.enableProperty(property.id);
                property.disabled = false;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Property enabled.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const submitHandler = async () => {
        setModal({
            title: <>Are you sure you want to <strong>save</strong> the changes made?</>,
            message: <FieldsInfo changedFields={changedFields} originalProperty={originalProperty}/>,
            onSubmit: async () => {
                setModal(m => ({...m, hide: true}));
                await propertyService.editProperty(changedFields);
                setOriginalProperty(JSON.parse(JSON.stringify(property)));
                setChangedFields(prev => {
                    return {
                        ['id']: property.id
                    }
                });
                cogoToast.success('Changes saved.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
                setSubmitted(true);
            }
        })
    }

    const OtherInfo = (roomName) => {

        const [language, setLanguage] = useState(null);

        const room = useMemo(() => {
            return roomName.roomName;
        }, [roomName]);

        const verificationDate = useMemo(() => {
            return (room ? property.rooms[room]?.moreInfo?.validation?.validationDate : property.moreInfo?.validation?.validationDate)
        }, [property]);

        const [isValidated, setValidated] = useState(localStorage.getItem(((room ? room : "property") + "IsValidated")));
        const saved = JSON.parse(localStorage.getItem((room ? room : "property")));

        const initialValue = useMemo(() => {
            return saved ? saved : (room ? property.rooms[room]?.moreInfo : property.moreInfo);
        }, [saved, property]);

        const getInformation = (lang) => {
            return saved ? saved[lang] : (room ? property.rooms[room]?.moreInfo?.validation?.[lang] : property.moreInfo?.validation?.[lang])
        }

        const [valuePT, setValuePT] = useState(getInformation("pt"));
        const [valueEN, setValueEN] = useState(getInformation("en"));
        const [valueIT, setValueIT] = useState(getInformation("it"));
        const [valueES, setValueES] = useState(getInformation("es"));

        const validate = () => {
            let hasAllTranslations = valuePT?.length > 0 && valueEN?.length > 0 && valueIT?.length > 0 && valueES?.length > 0;
            let changedATranslation = (room ? (property.rooms[room]?.moreInfo && property.rooms[room].moreInfo.validation)
                                        : (property.moreInfo && property.moreInfo.validation)) &&
                                            (valuePT?.length > 0 || valueEN?.length > 0 || valueIT?.length > 0 || valueES?.length > 0);
            setValidated(hasAllTranslations.toString());
            localStorage.setItem(((room ? room : "property") + "IsValidated"), hasAllTranslations.toString())
            if (hasAllTranslations || changedATranslation){
                const validationObject = {"en": valueEN, "pt": valuePT, "it": valueIT, "es": valueES, "validationDate": new Date()}
                if (room){
                    const roomMoreInfo = property.rooms?.[room]?.["moreInfo"] || {};
                    roomMoreInfo["validation"] = validationObject;
                    let rooms = property.rooms;
                    if(rooms) {
                        rooms[room]["moreInfo"] = roomMoreInfo
                        handleFieldChange('rooms', rooms);
                    }
                }else{
                    const propertyMoreInfo = property?.["moreInfo"] || {};
                    propertyMoreInfo["validation"] = validationObject;
                    handleFieldChange('moreInfo', propertyMoreInfo);
                }
                localStorage.setItem((room ? room : "property"), JSON.stringify(validationObject));
            }
        }

        return (
            <div className={"otherInfoForm"}>
                <>
                    <label className={"PropertyEdit_input-label mt-2 mr-1"} htmlFor={"notes"}>Other relevant
                        information:</label>
                    <button className={"btn btn-primary ml-2"}
                            onClick={event => {event.preventDefault(); setLanguage("pt")}}>PT</button>
                    <button className={"btn btn-primary ml-2"}
                            onClick={event => {event.preventDefault(); setLanguage("en")}}>EN</button>
                    <button className={"btn btn-primary ml-2"}
                            onClick={event => {event.preventDefault(); setLanguage("it")}}>IT</button>
                    <button className={"btn btn-primary ml-2"}
                            onClick={event => {event.preventDefault(); setLanguage("es")}}>ES</button>
                    <button className={"btn btn-primary ml-2"}
                            onClick={event => {event.preventDefault(); setLanguage(null)}}>Original</button>
                    <button className={`btn btn-${(isValidated === "true" ? "success" : (isValidated === "false" ? "danger": "primary"))} ml-2`}
                            onClick={event => {event.preventDefault(); validate()}}>Validate</button>
                </>
                {isValidated === "false" && <code>There's a translation missing</code>}
                <RichTextEditor setValue={setValueEN} moreInfo={initialValue} language={"en"} display={language === "en"} verificationDate={verificationDate}/>
                <RichTextEditor setValue={setValuePT} moreInfo={initialValue} language={"pt"} display={language === "pt"} verificationDate={verificationDate}/>
                <RichTextEditor setValue={setValueES} moreInfo={initialValue} language={"es"} display={language === "es"} verificationDate={verificationDate}/>
                <RichTextEditor setValue={setValueIT} moreInfo={initialValue} language={"it"} display={language === "it"} verificationDate={verificationDate}/>
                <RichTextEditor moreInfo={(room ? property.rooms[room]?.moreInfo : property.moreInfo)} display={!language} verificationDate={verificationDate}/>
            </div>
        )
    }


    return (
        (property ?
        <PageWrapper title={"Edit Property"}
                     breadcrumbs={[
                         {url: "properties", title: "Properties"},
                         {title: property?.internal_name}
                     ]}
                     noCard>
            <div className={"d-flex flex-column"}>
                <div className={"d-flex align-items-center mb-2"}>
                    {property.validated ? <div className={"badge badge-success mr-2"}>Validated Property</div> :
                        <button className={`btn btn-rounded btn-primary mr-2 ${property.disabled ? 'button-disabled' : ''}`} onClick={()=> validateProperty()}>Validate Property</button>}
                    {property.disabled ? <button className={"btn btn-rounded btn-success"} onClick={enableProperty(property)}>Enable</button> : <button className={"btn btn-outline-danger"} onClick={disableProperty(property)}>Disable</button>}
                </div>
                <div className="form-check">
                    <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" defaultChecked={property.verified} onChange={()=> handleFieldChange('verified', !property.verified)}/>
                        <i className="input-helper" />
                        Verified
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" defaultChecked={property.instantBooking} onChange={() => handleFieldChange('instantBooking', !property.instantBooking)}/>
                        <i className="input-helper" />
                        Instant Booking
                    </label>
                </div>
            </div>
            <div className={"Submit-floating-button"}>
                <button className={`btn btn-rounded btn-primary ${Object.keys(changedFields).length > 1 ? 'clickable': 'button-disabled'}`} onClick={()=> submitHandler()}>Save</button>
            </div>
            <div className={"PropertyEdit_row"}>
                <Details property={property} handleChange={handleFieldChange}/>
                <AddressSection property={property} handleChange={handleFieldChange}/>
                <VisitsSection property={property} handleChange={handleFieldChange}/>
            </div>
            <div className={"PropertyEdit_row"}>
                <Rules property={property} handleChange={handleFieldChange}/>
            </div>
            <div className={"PropertyEdit_row"}>
                <PropertyAreas property={property} handleChange={handleFieldChange}/>
            </div>
            <div className={"PropertyEdit_row"}>
                <Amenities property={property} handleChange={handleFieldChange} submitted={submitted} changedFields={changedFields}/>
            </div>
            <div className={"PropertyEdit_column moreInfoColumn"}>
              <OtherInfo/>
            </div>
            {Object.keys(property.rooms).length > 0 &&<label className={"Rooms-title"}>Rooms</label>}
            {property.rooms && Object.keys(property.rooms).filter(rn => (property.accommodation === "apartment" ? rn === "room_1" : rn)).map((roomName) => {
                return (
                    <div className={"PropertyEdit_row RoomEdit"}>
                        <div className={"PropertyEdit_row noBorder"} key={"form-" + roomName}
                             id={property.rooms[roomName].id}>
                            <RoomForm roomName={roomName} handleChange={handleFieldChange} property={property}
                                      changedFields={changedFields} originalProperty={originalProperty}/>
                        </div>
                        <RoomResidents property={property} roomName={roomName} handleChange={handleFieldChange}/>
                        <OtherInfo roomName={roomName} key={"otherInfo"+roomName}/>
                    </div>)
            })}
        </PageWrapper> : <Spinner inline/>  )


    );
}

export default PropertyEdit;