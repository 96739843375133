import Spinner from "../shared/Spinner";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {seoService} from "../../services/seoService.js";
import cogoToast from "cogo-toast";


const EditCityConfig = ({city, cityConfig, setCityConfig, newCity}) => {
    const [loading, setLoading] = useState(false);


    let currentConfig = {...cityConfig};

    const handleChange = (name, e) => {
        let inputValue = e.target?.value || 0;
        let newValue = !["city", "booking_fee_value_min"].includes(name) ? inputValue / 100 : inputValue;
        console.log(name, newValue)
        let newConfig = {...currentConfig, [name]: newValue};
        setCityConfig(newConfig);
        currentConfig = newConfig
    }

    return (
        <form className={"edit-form"}>
            <div className={"form-group mb-2"}>
                {city && <h1 style={{textTransform: "capitalize"}}>{city}</h1>}
                {newCity && <>
                    <label htmlFor={"city"}>Cidade</label>
                    <input type="text" className="form-control" name={"city"} onChange={(e) => handleChange("city", e)}/>
                    <br/>
                </>}
                <label htmlFor={"feeRatio"}>Fee Ratio %</label>
                <input type="number" className="form-control" name={"feeRatio"} defaultValue={cityConfig.booking_fee_ratio * 100} onChange={(e) => handleChange("booking_fee_ratio", e)}/>
                <br/>
                <label htmlFor={"feeRatioHigh"}>Fee Ratio % (0% LL commission)</label>
                <input type="number" className="form-control" name={"feeRatioHigh"} defaultValue={cityConfig.booking_fee_ratio_high * 100} onChange={(e) => handleChange("booking_fee_ratio_high", e)}/>
                <br/>
                <label htmlFor={"feeMin"}>Fee Min Value €</label>
                <input type="number" className="form-control" name={"feeMin"} defaultValue={cityConfig.booking_fee_value_min} onChange={(e) => handleChange("booking_fee_value_min", e)}/>
            </div>
        </form>)

}

const Values = ({setModal}) => {

    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState([]);
    //const [cityConfig, setCityConfig] = useState({});

    useEffect(() => {
        seoService.getConfigValues().then((values) => {
            console.log("res", values)
            setValues(values);
        }).catch((e) => {
            console.error("Error getting values", e);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const handleAddNew = () => {
        let cityConfig = {};
        const setCityConfig = (newConfig) => {
            cityConfig = newConfig;
        }
        setModal({
            title: "New City",
            size: "md",
            message: <EditCityConfig cityConfig={cityConfig} setCityConfig={setCityConfig} newCity/>,
            onSubmit: async () => {
                console.log("cityConfig", cityConfig)
                try {
                    await seoService.createCityConfig(cityConfig);
                    setValues(v => ({...v, [cityConfig.city]: cityConfig}));
                    cogoToast.success(cityConfig.city + ' config created successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    })
                    setModal(m => ({...m, hide: true}));
                } catch (e) {
                    console.log(e)
                    let errorMessage = e?.error?.message || e?.message || "Error creating city config";
                    if (e.validationErrors) {
                        errorMessage += "\n";
                        e.validationErrors.forEach((error) => {
                            errorMessage += error.param + ": " + error.msg + "\n";
                        })
                    }
                    cogoToast.error(errorMessage, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }

    const handleEdit = (city, currentConfig) => {
        let cityConfig = {...currentConfig};
        const setCityConfig = (newConfig) => {
            cityConfig = newConfig;
        }
        setModal({
            title: "Edit City Config",
            size: "md",
            message: <EditCityConfig city={city} cityConfig={cityConfig} setCityConfig={setCityConfig}/>,
            onSubmit: async () => {
                console.log("city", city, "cityConfig", cityConfig)
                try {
                    await seoService.updateCityConfig(city, cityConfig);
                    setValues(v => ({...v, [city]: cityConfig}));
                    cogoToast.success('City config updated successfully!', {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Success'
                    })
                    setModal(m => ({...m, hide: true}));
                } catch (e) {
                    cogoToast.error('Error editing city config: ' + e?.error?.message, {
                        hideAfter: 5,
                        position: 'top-center',
                        heading: 'Error'
                    })
                }
            }
        });
    }




    return (
        <div className="card">
            <div className="card-body" style={{overflowX: 'auto'}}>
                <button className={"btn btn-success mb-2"} onClick={handleAddNew}><i className={"mdi mdi-plus"}/>Add City Fees</button>
                {loading ? <Spinner inline/> : <>
                    <table className={"table border"}>
                        <thead>
                        <tr>
                            <th>Cidade</th>
                            <th style={{textAlign: "center"}}>Booking Fee</th>
                            <th style={{textAlign: "center"}}>Booking Fee (0% LL Commission)</th>
                            <th style={{textAlign: "center"}}>Minimum Booking Fee</th>
                            <th style={{textAlign: "center"}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(values).filter(city => values[city].booking_fee_ratio).map((city, index) => {
                            const cityConfig = values[city];
                            return (<tr key={city}>
                                <td style={{textTransform: "capitalize"}}>{city}</td>
                                <td style={{textAlign: "center"}}>{cityConfig.booking_fee_ratio * 100}%</td>
                                <td style={{textAlign: "center"}}>{cityConfig.booking_fee_ratio_high * 100}%</td>
                                <td style={{textAlign: "center"}}>{cityConfig.booking_fee_value_min}€</td>
                                <td style={{textAlign: "center"}}>
                                    <div className={"d-flex justify-content-end table_actions"}>
                                        <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                            <i className={"mdi mdi-pencil"} onClick={() => handleEdit(city, cityConfig)}></i>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </>}
                <div></div>
            </div>
        </div>
    )

}

export default Values;