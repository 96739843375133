import React, {useEffect, useState} from 'react';
import PageWrapper from "../components/PageWrapper";
import {emailService} from "../../services/emailService";
import moment from "moment/moment";
import DataCardTable from "../components/DataCardTable";
import {useDialogContext} from "../App";

const Emails = () => {


    const {setModal} = useDialogContext();

    const conditionalRowStyles = [
        {
            when: row => row.error_message && Object.values(row.error_message).length > 0,
            style: {
                backgroundColor: '#f5ccce',
            }
        },
        {//Being send
            when: row => ["landlord_account_validation", "landlord_booking_cancellation", "landlord_booking_cancellation_request", "landlord_booking_cancellation_response",
                "landlord_booking_rejected", "landlord_booking_reminder", "landlord_booking_request_sent", "landlord_booking_suggestion_accepted", "landlord_booking_suggestion_rejected", "landlord_booking_suggestion_sent", "landlord_offer_sent", "landlord_potential_tenants_weekly",
                "landlord_recover_password", "landlord_status", "landlord_visit_1_hour", "landlord_welcome", "management_notification",
                "management_property_validation", "student_booking_accepted", "student_booking_ad_suggested", "student_booking_cancellation", "student_booking_cancellation_request", "student_booking_cancellation_response", "student_booking_dates_suggested", "student_booking_request_accepted", "student_booking_request_sent",
                "student_booking_suggestion_accepted", "student_booking_suggestion_expired", "student_booking_suggestion_rejected", "student_recommendation", "student_visit_1_hour", "student_visit_after", "student_visit_cancelled", "student_visit_confirmed", "student_visit_rejected", "student_visit_request_sent", "student_visit_suggestion",
                "student_visit_tomorrow"].includes(row.template),
            style: {
                backgroundColor: '#ccf5ed',
            }
        },
       /* {//Created but we don't send it yet
            when: row => [""].includes(row.template),
            style: {
                backgroundColor: '#dbf5cc',
            }
        },
        {//Sends but it needs alterations
            when: row => [""].includes(row.template),
            style: {
                backgroundColor: '#f5f0cc',
            }
        },*/
        {//Verified
            when: row => ["student_booking_cancellation_request", "landlord_booking_cancellation_request", "student_booking_cancellation_response", "landlord_booking_cancellation_response"].includes(row.template),
            style: {
                backgroundColor: '#97f37e',
            }
        },
        {//feedback
            when: row => ["landlord_visit_confirmed"].includes(row.template),
            style: {
                backgroundColor: '#eeba8b',
            }
        },
    ];

    const EmailPreviewContainer = (email, lang) => {
        const [emailRender, setEmailRender] = useState();

        const getRender = async () => {
            setEmailRender(await emailService.renderEmail(email, lang));
        }
        useEffect(() => {
            getRender();
        }, []);

        return (<>
            {emailRender?.status === "error" ?
                <div className={"error-page-divider"}>
                    {emailRender.error?.message}
                </div>
                : <iframe srcDoc={emailRender} style={{"width": "100%", "border": "none"}} height={"500px"}
                          title="Iframe Example"/>}
        </>)
    }

    const previewEmail = (email, lang) => {
        setModal({
            title: "Email Preview " + email.template + " to: " + (email.to.replace(",", " ")),
            size: "lg",
            message: <EmailPreviewContainer email={email} lang={lang}/>
        })
    };

    function parseRowLocals(row) {
        try {
            return JSON.parse(row.locals);
        } catch (e) {
            return {};
        }
    }

    const dataColumns = [
        {
            name: 'To',
            selector: row => row,
            cell: row => <div data-tag="allowRowEvents">{row.to ? row.to.replace(",", " ") : "-"}</div>
        },
        {
            name: 'Email Id',
            selector: row => row,
            cell: row => <div data-tag="allowRowEvents">{row.id?.split("-")[0] || "-"}</div>
        },
        {
            name: 'Template',
            selector: row => row,
            cell: row => <div data-tag="allowRowEvents">{row.template || "-"}</div>
        },
        {
            name: 'Date',
            selector: row => row,
            cell: row => <div
                data-tag="allowRowEvents">{row.timestamp ?  moment(row.timestamp).format("DD-MM-YYYY HH:mm") : "-"}</div>
        },
        {
            name: 'Type',
            selector: row => row,
            cell: row => <div
                data-tag="allowRowEvents">{parseRowLocals(row).type || "-"}</div>
        },
        {
            name: 'Preview',
            selector: row => row.id,
            minWidth: "100px",
            cell: row => <div data-tag="allowRowEvents">{
                ["gb", "pt", "it", "es"].map((lang) => {
                    return (
                        <i key={lang} className={`flag-icon flag-icon-${lang}`}
                           style={{"marginRight": "1rem", "cursor": "pointer"}}
                           onClick={async () => {
                               previewEmail(row, (lang === "gb" ? "en" : lang));
                           }}/>
                    )
                })
            }</div>
        }
    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("email_filters")) || {});

    return (<PageWrapper breadcrumbs={[{title: "Emails"}]}
                         title={"Emails"}
                         filters={filters} setFilters={setFilters}>
        <DataCardTable
            fixedFirstColumn
            columns={dataColumns}
            dataType={"emails"}
            fetchData={emailService.getEmailsElastic}
            filters={filters}
            conditionalRowStyles={conditionalRowStyles}
        />
    </PageWrapper>);
};

export default Emails;
