import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import {propertyService} from "../../services/propertyService";
import React, {useState} from "react";
import PropertyCard from "../components/PropertyCard";
import {useTranslation} from "react-i18next";
import {Form, FormGroup, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import moment from "moment";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import {Link} from "react-router-dom";
import RatingsOverlay from "../ratings/ratings-overlay";

const ExpandedRooms = ({data,disableRoomHandler, enableRoomHandler, deleteHandler, confirmAvailabilityHandler, handlePreview, submitHandler}) => {

    return (
        <div className={"ExpandedRooms"}>
            <div className={"labels"}>
                <label>Name</label>
                <label>Area</label>
                <label>Lot</label>
                <label>Rent</label>
                <label>Availability</label>
                <label>Integration ID</label>
                <label>Actions</label>
            </div>
            {Object.keys(data.rooms).map((roomName) => {
                let rooms = {};
                Object.values(data.rooms).forEach((room, index) => {
                    rooms["room_" + (index + 1)] = room;
                });
                let room = data.rooms[roomName];
                let firstAvailability = room?.firstAvailability || propertyService.calcFirstAvailability(room);
                return (
                    <div key={room.id}>
                        <div>{room.internalName ? room.internalName : "To be defined"}</div>
                        <div>{room.area || "-"} &#13217;</div>
                        <div>{room.numPeople ?  "Up to " +room.numPeople +(room.numPeople > 1 ? ' people' : ' person') : ""}</div>
                        <div className={`${(roomName === "0" && data.accommodation === 'apartment') || data.accommodation !== 'apartment' ? '' : 'hidden'}`}>{propertyService.getPricesInterval(room, data)}</div>
                        <div className={`${(roomName === "0" && data.accommodation === 'apartment') || data.accommodation !== 'apartment' ? '' : 'hidden'}`}>{data.accommodation === 'residence' && room?.openResidenceCalendar ?
                            <p className={"available-date"}>Calendar always open</p> :
                            (firstAvailability ?
                                <p className={"available-date"}>Available from&nbsp;
                                    {moment(new Date(firstAvailability)) < moment() ? moment().format('D MMM yyyy') : moment(firstAvailability).format('D MMM yyyy')}
                                </p>
                                : <p>To be defined</p>)}</div>
                        <div>
                            <FormGroup>
                                <OverlayTrigger
                                    overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                        Attention: Changing this attribute could cause <strong>duplicated</strong> rooms, please
                                        double check.
                                    </Tooltip>}>
                                    <Form.Control style={{maxWidth: "100px"}} type="text" className="form-control" name="integration_id"
                                                  id="integration_id"
                                                  defaultValue={room.integration_id} placeholder={"-"}
                                                  onKeyDown={async (e) => {
                                                      if (e.key === "Enter") {
                                                          room["integration_id"] = e.target.value;
                                                          rooms[(roomName + 1)] = room;
                                                          await submitHandler("rooms", rooms, data.id);
                                                      }
                                                  }}/>
                                </OverlayTrigger>
                            </FormGroup>
                        </div>
                        <div className={`Room-actions ${(roomName === "0" && data.accommodation === 'apartment') || data.accommodation !== 'apartment' ? '' : 'hidden'}`}>
                            <RatingsOverlay ratings={room.ratings? room.ratings: data.ratings}></RatingsOverlay>
                            <OverlayTrigger overlay={<Tooltip>Preview</Tooltip>}>
                                <i className={`mdi mdi mdi-eye ${data.disabled ? 'action-disabled' : 'preview-action'}`} onClick={handlePreview(data, roomName)}/>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                <i className={"mdi mdi-close-box-outline delete-action"} onClick={deleteHandler(data, room.id)}/>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip>{room.disabled ? "Activate" : "Deactivate"}</Tooltip>}>
                                <i className={`mdi ${data.disabled ? 'mdi-block-helper action-disabled' : (room.disabled ? "mdi-checkbox-marked-circle activate-action" : "mdi-block-helper inactivate-action")}`} onClick={data.disabled ? enableRoomHandler(data, room.id) : disableRoomHandler(data, room.id)}/>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip>Confirm availability</Tooltip>}>
                                <i className={`mdi mdi mdi-calendar-check ${(!(data.accommodation === 'residence' && room?.openResidenceCalendar) && ((roomName === "0" && data.accommodation === 'apartment') || data.accommodation !== 'apartment') && !data.disabled) ? 'confirm-action' : 'action-disabled'}`} onClick={confirmAvailabilityHandler(data, room)}/>
                            </OverlayTrigger>
                        </div>
                    </div>)})}
        </div>)

}

const Properties = ({inline, overrideFetch, landlord, cardTableStyles}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const disableRoomHandler = (property, roomId) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>disable</strong> the room?</>,
            message: <>The listing will no longer be visible on the site.
            </>,
            onSubmit: async () => {
                await propertyService.disableRoom(property.id, roomId);
                property.rooms[roomId].disabled = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Room disable.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const enableRoomHandler = (property, roomId) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>activate</strong> the room?</>,
            message: <>The listing will now be visible on the site.</>,
            onSubmit: async () => {
                await propertyService.enableRoom(property.id, roomId);
                property.rooms[roomId].disabled = false;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Room enable.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const disableHandler = (property) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>disable</strong> {property.landlord.name}'s property?</>,
            message: <>The listing will no longer be visible on the site.
            </>,
            onSubmit: async () => {
                await propertyService.disableProperty(property.id);
                property.disabled = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Disabled property.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const enableHandler = (property) => async (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>activate</strong> {property.landlord.name}'s property?</>,
            message: <>
                The listing will now be visible on the site.</>,
            onSubmit: async () => {
                await propertyService.enableProperty(property.id);
                property.disabled = false;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Property enabled.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const deleteProperty = (property) => async () => {
        setModal({
            title: <>Are you sure you want to <strong>delete</strong> {property.landlord.name}'s property?</>,
            message: <>The property and all its rooms will be deleted.
            </>,
            onSubmit: async () => {
                await propertyService.deleteProperty(property.id);
                property.justDeleted = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Property deleted.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const deleteHandler = (property, roomId) => (e) => {
        e.stopPropagation();
        setModal({
            title: <>Are you sure you want to <strong>delete</strong> the room?</>,
            message: <>The room will no longer be visible.
            </>,
            onSubmit: async () => {
                await propertyService.deleteRoom(property.id, roomId);
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Room deleted.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const handlePreview = (property, roomName) => () => {
        propertyService.previewProperty(property, roomName);
    }

    const confirmAvailabilityHandler = (property, room) => async (e) => {
        e.stopPropagation();
        let firstAvailability = room?.firstAvailability || propertyService.calcFirstAvailability(room);
        setModal({
            title: <>Confirm <strong>availability</strong>.</>,
            message: <>The room is available from {moment(new Date(firstAvailability)) < moment() ? moment().format('D MMM yyyy') : moment(firstAvailability).format('D MMM yyyy')}.
            </>,
            onSubmit: async () => {
                await propertyService.confirmAvailability(property.id, property.accommodation !== "apartment" && room.id);
                (room ? ["room_" + room.id] : Object.keys(property.rooms)).forEach(r_id => {
                    property.rooms[r_id].confirmedAvailability = new Date();
                })
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Confirmed Availability.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const validateHandler = (property) => async (e) =>{
        setModal({
            title: <>Validate <strong>property</strong>.</>,
            message: <>You want to validate {property.landlord_name}'s property?
            </>,
            onSubmit: async () => {
                await propertyService.validateProperty(property.id);
                property.validated = true;
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Property validated', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }


    const conditionalRowStyles = [
        {
            when: row => row.verificationAsked,
            style: {
                backgroundColor: '#f2f9ff',
            },
        },
        {
            when: row => row.validated,
            style: {
                backgroundColor: '#c2fabd',
            },
        },
        {
            when: row =>row.justDeleted,
            style: {
                display: 'none',
            }
        }
    ]

    const submitHandler = async (field, value, id) => {
        setModal({
            title: <>Are you sure you want to <strong>save</strong> the changes made?</>,
            onSubmit: async () => {
                setModal(m => ({...m, hide: true}));
                await propertyService.editProperty({"id": id, [field]: value});
                cogoToast.success('Changes saved.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }


    const dataColumns = [
        {
            name: 'ID',
            width: "100px",
            selector: row => row.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row.id?.split("-")[0]}</div>
        },
        {
            name: 'Address',
            minWidth: "200px",
            maxWidth: "240px",
            selector: row => row.address,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{row.address}</span>
            </div>)
        },
        {
            name: 'Landlord ID',
            width: "300px",
            selector: row => row.landlord_id
        },
        {
            name: 'Country',
            minWidth: "90px",
            maxWidth: "130px",
            selector: row => row.country
        },
        {
            name: 'Accommodation type',
            selector: row => row.accommodation,
            cell: row => <div
                data-tag="allowRowEvents">{row.accommodation ? t(`property_resume.accommodation_type.${row.accommodation}`) : " - "}</div>
        },
        {
            name: 'Rent intervals',
            selector: row => row.rooms,
            cell: row => row.rooms ? <OverlayTrigger
                overlay={<Tooltip>{row.billsIncluded ? 'Expenses included' : 'Expenses not included'}</Tooltip>}>
                {row.rooms &&<div>{row.accommodation === 'apartment' ? propertyService.getPricesInterval(row.rooms['0'], row) : propertyService.getRentsInterval(row)}
                </div>}
            </OverlayTrigger> : ""
        },
        {
            name: 'Nº of Rooms',
            selector: row => row.accommodation,
            cell: row =>
                <div>{row.accommodation === 'apartment' ?
                    (propertyService.getPropertyNumRooms(row) || 1) :
                    propertyService.getPropertyNumRooms(row)} {(propertyService.getPropertyNumRooms(row) > 1 ? "Rooms" : "Room") + " in "+ (row.accommodation === 'residence' ? 'residence' : 'property')}</div>
        },
        {
            name: 'Active Listings',
            selector: row => row.rooms,
            center: true,
            cell: row => row.accommodation === 'apartment' ?
                <div>{!row.disabled && !row.rooms?.[0]?.disabled ? 1 :0}</div> :
                <div>{row.rooms ? Object.values(row.rooms).filter(r => !r.disabled)?.length : 0}</div>
        },
        {
            name: 'Rooms | Bathrooms',
            selector: row => row.bedrooms,
            center: true,
            cell: row => row.bedrooms && <div>
                {row.bedrooms} | {row.numBathroom ? row.numBathroom : 0}
            </div>
        },
        {
            name: 'Visit Types',
            selector: row => row.accept_visit_type,
            center: true,
            cell: row =><div>
                {row.accept_visit_type || "Not defined"}
            </div>
        },
        {
            name: 'Instant Booking',
            selector: row => row.instantBooking,
            center: true,
            cell: row =><div>
                {row.instantBooking ? "Yes" : "No"}
            </div>
        },
        {
            name: 'Integration ID',
            selector: row => row.integration_id,
            center: true,
            cell: row =><div>
                <FormGroup>
                    <OverlayTrigger
                        overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                            Attention: Changing this attribute could cause <strong>duplicated</strong> houses, please
                            double check.
                        </Tooltip>}>
                        <Form.Control type="text" className="form-control" name="integration_id" id="integration_id"
                                      defaultValue={row.integration_id} placeholder={"-"}
                                      onKeyDown={async (e) => {
                                          if (e.key === "Enter") {
                                              await submitHandler(e.target.name, e.target.value, row.id)
                                          }
                                      }}/>
                    </OverlayTrigger>
                </FormGroup>
            </div>
        },
        {
            name: 'Actions',
            width: "100px",
            button: true,
            cell: row => <div className={"actions-container"}>
                <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                    <Link to={`/property/${row.id}`}><i className={"mdi mdi-pencil-box-outline preview-action"} /></Link>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Validate</Tooltip>}>
                    <i className={`mdi mdi-cloud-check ${row.validated || row.disabled ? 'action-disabled' : 'validate-action'}`}  onClick={validateHandler(row)}/>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                    <i className={"mdi mdi-delete-forever action-reject"} onClick={deleteProperty(row)}/>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>{row.disabled ? "Enable" : "Disable"}</Tooltip>}>
                    <i className={`mdi ${row.disabled ? "mdi-checkbox-marked-circle activate-action" : "mdi-block-helper inactivate-action"}`} onClick={row.disabled ? enableHandler(row) : disableHandler(row)}/>
                </OverlayTrigger>
            </div>
        }

    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("ads_filters")) || {});

    return (
        <PageWrapper breadcrumbs={[{title: "Listings"}]}
                     title={"Listings"}
                     inline={inline} filters={filters} setFilters={setFilters}>
            <DataCardTable dataType={"properties"}
                           customClass={cardTableStyles}
                           fetchData={overrideFetch || propertyService.list}
                           cardRendererComponent={PropertyCard}
                           cardRendererProps={{
                               landlord, disableHandler, disableRoomHandler, enableHandler, enableRoomHandler, deleteHandler, deleteProperty, handlePreview, confirmAvailabilityHandler, validateHandler
                           }}
                           columns={dataColumns}
                           conditionalRowStyles={conditionalRowStyles}
                           expandableRows
                           expandableRowsComponent={(props) => <ExpandedRooms{...{...props, disableRoomHandler, enableRoomHandler, deleteHandler, confirmAvailabilityHandler, handlePreview, submitHandler}} />}
                           expandableRowDisabled={row=> !row.rooms}
                           filters={filters}
            />
        </PageWrapper>

    )
};

export default Properties;
