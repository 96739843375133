import React, {useEffect, useMemo, useRef, useState} from 'react'
import DataTable from 'react-data-table-component';
import Spinner from "../shared/Spinner";
import {Pagination} from "react-bootstrap";
import {dataService} from "../../services/dataService";
import cogoToast from "cogo-toast";

const DataCardTable = ({columns, fetchData, dataType, cardRenderer, cardRendererComponent, cardRendererProps={}, fixedFirstColumn, customClass, filters, customHeaderClass, extraHeaderRenderer, ...rest}) => {
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [visualization, setVisualization] = useState((localStorage.getItem("data_card_visualization_" + dataType) || ((["visits", "reservations", "tenants", "ambassadors"].includes(dataType) && cardRendererComponent) ? "cards" : "table")));

    const firstLoadRef = useRef(false);
    const dataCardTableRef = useRef(false);

    const handleVisualization = (type) => (e) => {
        firstLoadRef.current = false;
        setVisualization(type);
        localStorage.setItem("data_card_visualization_" + dataType, type);
    }

    let wrappedColumns = useMemo(() => {
        return columns.map((column, index) => {
            if(index === 0) {
                let columnCellFunc = column.cell;
                column.cell = (row => row.id === "pagination" ?
                    <></> :
                    columnCellFunc(row))
            }
            return column;
        });
    }, [columns, loadingMore]);

    const loadData = async (npage= page) => {
        setLoadingMore(true)
        let _fetched = await fetchData(npage, searchKey, filters);
        let _fetchedData = _fetched[dataType === "finance" ? "reservations" : dataType];
        setData(_fetchedData);
        setTotalResults(_fetched.total);
        setLoadingMore(false);
    };

    const reloadData = async () => {
        setData(null);
        setPage(0);
        setSearchKey(null);
        await loadData(0);
    };

    useEffect(() => {
        (async () => {
            setPage(0);
            await loadData(0);
        })();
    }, [searchKey, filters]);

    const handleSetPage = async (next_page) => {
        if(next_page === page || next_page < 0 || next_page > Math.ceil(totalResults / 25)) return;
        setPage(next_page);
        await loadData(next_page);
    };

    const [exporting, setExporting] = useState(false);
    const handleExportToExcel = async () => {
        setExporting(true);
        let excelFetch;
        if(dataType === "reservations") excelFetch = await dataService.exportReservations();
        if(dataType === "visits") excelFetch = await dataService.exportVisits();
        if(dataType === "tenants") excelFetch = await dataService.exportUsers();
        if(dataType === "landlords") excelFetch = await dataService.exportLandlords();
        if(dataType === "properties") excelFetch = await dataService.exportProperties();
        if(dataType === "invoices") excelFetch = await dataService.exportInvoices();
        if(dataType === "finance") excelFetch = await dataService.exportFinance();
        let fileName = excelFetch?.fileName;
        if(fileName) {
            window.location = process.env.REACT_APP_API_BASE + "/public/excel/" + fileName;
            cogoToast.success('Table exported to excel.', {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Success'
            })
        } else {
            cogoToast.error(excelFetch.error? excelFetch.error : "Something went wrong", {
                hideAfter: 5,
                position: 'top-center',
                heading: 'Error'
            })
        }
        setExporting(false)
    }

    useEffect(() => {
        if(fixedFirstColumn && data && !firstLoadRef.current && visualization === "table") {
            //firstLoadRef.current = true;
            setTimeout(()=>{
                let dataTableElement = dataCardTableRef.current?.getElementsByClassName("rdt_Table")[0]?.parentElement?.parentElement;
                let dataTableBodyElement = dataCardTableRef.current?.getElementsByClassName("rdt_TableBody")[0];
                if(dataTableElement) {
                    dataTableElement.style.setProperty("--data-table-scroll-x", "0px");
                    dataTableElement.style.setProperty("--data-table-scroll-y", "0px");
                    dataTableElement.addEventListener('scroll', (e) => {
                        dataTableElement.style.setProperty("--data-table-scroll-x", Math.round(e.target.scrollLeft * 10) / 10 + "px");
                    });
                }
                dataTableBodyElement&&dataTableBodyElement.addEventListener('scroll', (e)=>{
                    dataTableElement.style.setProperty("--data-table-scroll-y", Math.round(e.target.scrollTop*10)/10 + "px");
                });
            },150);
        }
    }, [data, visualization])

    const searchTimeout = useRef(null);

    const CardRenderer = cardRenderer || cardRendererComponent;

    let pages = useMemo(() => {
        let maxPage = Math.ceil(totalResults/(dataType === "tenants" ? 28 : 25) || 1);
        let ret = [];
        if(maxPage <= 7) {
            for(let np = 1; np <= maxPage; np++) {
                ret.push(np);
            }
        } else {
            let npage = page + 1;
            if(npage < 5)
                ret = [1, 2, 3, 4, 5, "..", maxPage];
            else if(npage > maxPage-4)
                ret = [1, "..", maxPage-4, maxPage-3, maxPage-2, maxPage-1, maxPage];
            else
                ret = [1, "..", npage-1, npage, npage+1, "..", maxPage];
        }
        return ret.map((p,i) => <Pagination.Item key={p+"_"+i} active={p === page+1} disabled={p===".."} onClick={() => {if(p!=="..")handleSetPage(p-1)}}>
            {p}
        </Pagination.Item>)
    },[totalResults, page]);

    return <div ref={dataCardTableRef}
                className={"DataCardTable" + (fixedFirstColumn ? ' fixedFirstColumn' : '') + (customClass ? ' ' + customClass : '')}>
        <div className={"DataCardTable_header " + (customHeaderClass||"")}>
            <div className={"d-flex align-items-center search-container-wrapper"}>
                {extraHeaderRenderer && extraHeaderRenderer()}
                <div className="form-group search-container">
                    <input type="search" className="form-control" placeholder="Search"
                           onChange={(e) => {
                               window.clearTimeout(searchTimeout.current);
                               let searchedKey = e.target.value;
                               if (searchedKey == null || (searchedKey.length > 0 && searchedKey.length < 3)) return;
                               searchTimeout.current = window.setTimeout(() => {
                                   setSearchKey(searchedKey)
                               }, 500);
                           }}/>
                </div>
            </div>
            <div className={"d-flex"}>
                <Pagination>
                    <Pagination.Prev disabled={page === 0} onClick={() => {
                        handleSetPage(page - 1);
                    }}/>
                    {pages}
                    <Pagination.Next disabled={Math.ceil(totalResults/25)-1 === page} onClick={() => {
                        handleSetPage(page + 1);
                    }}/>
                </Pagination>
                <div className="btn-group ml-1 mr-1" role="group" aria-label="Basic example">
                    <button type="button" title={"Switch to Table view"} onClick={handleVisualization("table")}
                            className={"btn btn-outline-secondary" + (visualization === "table" ? ' active' : '')}>
                        <i className="mdi mdi-format-list-bulleted"/>
                    </button>
                    {CardRenderer &&<button type="button" title={"Switch to Card View"} onClick={handleVisualization("cards")}
                            className={"btn btn-outline-secondary" + (visualization === "cards" ? ' active' : '')}>
                        <i className="mdi mdi-card-text-outline"/>
                    </button>}
                </div>
                <div className="btn-group" role="group">
                    <button type="button" onClick={reloadData} title={"Reload Data"} className="btn btn-outline-secondary">
                        <i className="mdi mdi-reload"/>
                    </button>
                    <button type="button" disabled={exporting} onClick={handleExportToExcel} title={"Export Excel"} className="btn btn-outline-secondary">
                        {exporting ? <i className="mdi mdi-refresh mdi-spin"/> : <i className="mdi mdi-table-eye"/>}
                    </button>
                </div>
            </div>
        </div>
        <div>Results: {totalResults}</div>
        {visualization === "table" && <DataTable columns={wrappedColumns} data={data || []}
                                                 persistTableHead
                                                 noDataComponent={(
                                                     <div className={"no-data-wrapper"}>
                                                         <span>No data to show</span>
                                                     </div>
                                                 )}
                                                 progressPending={!data || loadingMore}
                                                 progressComponent={<Spinner inline/>}
                                                 fixedHeader
                                                 fixedHeaderScrollHeight={'calc(100vh - 365px)'}
                                                 {...rest}
        />}
        {visualization === "cards" && <div className="DataCardTable_cards row">
            {data && !loadingMore ? data.map(cardData => {
                return <CardRenderer key={"data-card-" + cardData.id} data={cardData} {...cardRendererProps}/>
            }) : <Spinner inline/>}
        </div>}
        <div className={"d-flex justify-content-end mt-2"}>
            <Pagination>
                <Pagination.Prev disabled={page === 0} onClick={() => {
                    handleSetPage(page - 1);
                }}/>
                {pages}
                <Pagination.Next disabled={Math.ceil(totalResults/25)-1 === page} onClick={() => {
                    handleSetPage(page + 1);
                }}/>
            </Pagination>
        </div>
    </div>
};

export default DataCardTable;
