import React, {forwardRef, useEffect, useMemo, useState} from "react";
import moment from "moment/moment";
import defaultAvatar from "../../assets/images/avatar.png";
import {propertyService} from "../../services/propertyService";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {dataService} from "../../services/dataService";
import {useTranslation} from "react-i18next";
import Spinner from "../shared/Spinner";
import {Form, FormGroup} from "react-bootstrap";


const RoomResidents = ({property, roomName, handleChange}) => {
    let room = property.rooms[roomName];
    let roomNumber = roomName?.split("_")[1];

    const {t} = useTranslation();
    const [showPrevious, setShowPrevious] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [editingResident, setEditingResident] = useState({});
    const [residents, setResidents] = useState(room?.residents || []);
    const [openDatePicker, setOpenDatePicker] = useState(null);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        (async () => {
            let _countries = (await dataService.getCountries()).map(c => ({value: c.iso2, label: c.name}))
            setCountries(_countries);
        })();
    }, []);


    const handleCancelEditing = () => {
        if (editingResident.id === "new") {
            setResidents(_residents => _residents.filter(r => r.id !== "new"));
        }
        setEditingResident({})
    }


    const handleSaveResident = async () => {
        setLoadingEdit(true);
        let formData = new FormData();
        for (const [key, value] of Object.entries(editingResident)) {
            formData.append(key, value);
        }
        try {
            let response = await propertyService.saveResident(property.id, roomNumber, formData);
            if (response.status === "success") {
                delete response.status;
                setResidents(_residents => {
                    let _residents_ = _residents.filter(r => r.id !== editingResident.id);
                    _residents_.push(response);
                    return _residents_;
                });
            }
            setEditingResident({});
        } catch (e) {
            console.log("Error saving resident", e)
        }
        setLoadingEdit(false);
    }


    const handleDeleteResident = async () => {
        setLoadingEdit(true);
        try {
            let response = await propertyService.deleteResident(property.id, roomNumber, editingResident.id);
            if (response.status === "success") {
                setResidents(_residents => _residents.filter(r => r.id !== editingResident.id));
                room.residents = room.residents.filter(r => r.id !== editingResident.id);
            }
            setEditingResident({});
        } catch (e) {
            console.log("Error deleting resident", e)
        }
        setLoadingEdit(false);
    }


    const editingPhoto = useMemo(() => {
        if (!editingResident.photo) {
            return defaultAvatar;
        } else if (editingResident.photo instanceof File) {
            return URL.createObjectURL(editingResident.photo);
        } else {
            return editingResident.photo;
        }

    }, [editingResident.photo])

    useEffect(() => {
        let _residents = room?.residents || [];
        if (showPrevious) {
            _residents = _residents.filter(resident => moment(resident.moveOut).isBefore(moment()));
        } else {
            _residents = _residents.filter(resident => moment(resident.moveOut).isSameOrAfter(moment()));
        }
        setResidents(_residents);
    }, [showPrevious])


    const CustomInput = forwardRef(({value, onClick}, ref) => (
        <div className={"CustomInput"} onClick={onClick} ref={ref}>
            {value || "Select date"}
        </div>
    ));


    return (
        <div className={"Advanced__section"}>
            <div className={"Advanced__section-title"}>
                <span>Room Residents</span>
                <span>{residents.length} residents</span>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <label htmlFor={"previousResidents"}>Show previous residents</label>
                <input disabled={!!editingResident.id} className={"ml-2 mb-2"} type={"checkbox"} name={"previousResidents"} checked={showPrevious} onChange={() => setShowPrevious(!showPrevious)}/>
            </div>
            <div className={"Advanced__section-content"} id={"residents-container"}>
                {residents.map((resident, index) => {
                    if (editingResident.id === resident.id) {
                        return (
                            <div className={"Resident Resident__edit"} key={editingResident.id}>
                                <div className={"Resident__edit-dates"}>
                                    <label>
                                        <div className={"Resident__photo"} style={{backgroundImage: `url(${editingPhoto})`}}>
                                            <input type={"file"}
                                                   accept={"image/*"}
                                                   onChange={(e) => {
                                                       let photo = e.target.files?.[0];
                                                       if (photo) setEditingResident(prev => ({...prev, ["photo"]: photo}))
                                                   }}/>
                                        </div>
                                    </label>


                                    {openDatePicker && <div className={"DatePickerBackground"} onClick={() => setOpenDatePicker(null)}/>}
                                    <div className={"Resident__edit-dates__inputs"}>
                                        {["moveIn", "moveOut"].map((date, index) => {
                                            return (
                                                <div className={"DatePickerLabel"} key={"date_input_" + date}
                                                     onClick={() => {if (openDatePicker !== date) setOpenDatePicker(date)}}
                                                     style={{cursor: "pointer"}}>
                                                    <label htmlFor={date}>{date === "moveIn" ? "Move-in" : "Move-out"}</label>
                                                    <div className={"CustomDatePicker"} style={{width: "fit-content"}}>
                                                        <DatePicker className={"input_field"}
                                                                    name={date}
                                                                    open={openDatePicker === date}
                                                                    customInput={<CustomInput/>}
                                                                    onChange={(day) => {
                                                                        setEditingResident(prev => ({...prev, [date]: day.toISOString()}))
                                                                        setOpenDatePicker(date === "moveIn" && !editingResident["moveOut"] ? "moveOut" : null)
                                                                    }}
                                                                    selected={editingResident[date] && new Date(editingResident[date])}
                                                                    locale={"en"}
                                                                    dateFormat={"dd MMM yyyy"}
                                                                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 1).toUpperCase()}
                                                                    popperPlacement='bottom-start'
                                                                    minDate={date === "moveOut" && new Date(editingResident["moveIn"])}
                                                                    maxDate={date === "moveIn" && new Date(editingResident["moveOut"])}
                                                                    popperModifiers={{
                                                                        preventOverflow: {
                                                                            enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                        },
                                                                        hide: {
                                                                            enabled: false // turn off since needs preventOverflow to be enabled
                                                                        }
                                                                    }}
                                                        />
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>

                                </div>
                                <div className={"Resident__edit-info info-personal"}>
                                    <FormGroup className={"info"}>
                                        <label htmlFor={"name"}>Name</label>
                                        <Form.Control className={"input_field basic_container"} type={"text"} name={"name"} value={editingResident.name} onChange={(e) => {
                                            let name = e.target.value;
                                            setEditingResident(prev => ({...prev, name}))}}/>
                                    </FormGroup>
                                    <Form.Group className={"info"}>
                                        <label htmlFor={"birthdate"}>Birthdate</label>
                                        <div className="col-sm-9">
                                            <DatePicker className={"form-control"} name={"birthdate"}
                                                        onChange={(date) => setEditingResident(prev => ({...prev, birthdate: moment(date).format("MM-yyyy")}))}
                                                        selected={editingResident.birthdate ? new Date(editingResident.birthdate?.split("-")[1], editingResident.birthdate?.split("-")[0] - 1) : null}
                                                        locale={"en"} dateFormat={"MM/yyyy"}
                                                        popperPlacement='bottom-start'
                                                        showMonthYearPicker
                                                        showYearDropdown
                                            />
                                        </div>
                                    </Form.Group>
                                    <div className={"info"}>
                                        <label htmlFor={"nationality"}>Nationality</label>
                                        <Select name={"nationality"} isClearable className={"react-select-container"}
                                                classNamePrefix={"react-select"}
                                                placeholder="Country"
                                                defaultValue={countries.filter(c => c.value === editingResident["nationality"])}
                                                value={countries.filter(c => c.value === editingResident["nationality"])}

                                                options={countries}
                                                onChange={(o) => {
                                                    setEditingResident(prev => ({...prev, "nationality": o.value}))
                                                }}/>
                                    </div>
                                </div>
                                <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                                    <div className={"Resident__edit-info"} style={{minWidth: "fit-content", width: "unset"}}>
                                        <div className={"info"}>
                                            <label htmlFor={"occupation"}>Occupation</label>
                                            <div className={"occupation-options"}>
                                                {["student", "worker", "internship", "other"].map((occupation, index) => {
                                                    return (
                                                        <div key={"occupation_" + occupation} style={{}} className={"text-capitalize basic_container" + (editingResident.occupation === occupation ? " selected" : "")} onClick={(e) => {
                                                            setEditingResident(prev => ({...prev, occupation: occupation}))
                                                        }}>{occupation}</div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"Resident__edit-info"} style={{minWidth: "fit-content", width: "unset"}}>
                                        <div className={"info"}>
                                            <label htmlFor={"gender"}>Gender</label>
                                            <div className={"occupation-options"}>
                                                {["male", "female", "other", "ratherNotSay"].map((gender, index) => {
                                                    return (
                                                        <div key={"gender" + gender} className={"basic_container" + (editingResident.gender === gender ? " selected" : "")} onClick={(e) => {
                                                            setEditingResident(prev => ({...prev, gender: gender}))
                                                        }}>{t("gender." + gender)}</div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"Resident__edit-buttons"}>
                                    {editingResident.id !== "new" && <button className={"btn btn-danger mr-2"} onClick={handleDeleteResident} disabled={loadingEdit}>Delete</button>}
                                    <button className={"btn btn-secondary mr-2"} onClick={handleCancelEditing} disabled={loadingEdit}>Cancel</button>
                                    <button className={"btn btn-success"} onClick={handleSaveResident} disabled={!(editingResident.name && editingResident.moveIn && editingResident.moveOut && editingResident.occupation && editingResident.birthdate && editingResident.nationality) || loadingEdit}>Save</button>
                                </div>
                            </div>
                        )
                    } else return (
                        <div className={"Resident"} key={resident.id}>
                            <div className={"Resident__photo"} style={{backgroundImage: `url(${resident.photo || defaultAvatar})`}}/>
                            <div className={"Resident__info"}>
                                <span className={"Resident__info-label"}>{resident.name}</span>
                                <span className={"Resident__info-value"}>{resident.occupation}</span>
                            </div>
                            {["moveIn", "moveOut", "birthdate", "nationality"].map((key, index) => {
                                let value = resident[key];
                                if (["moveIn", "moveOut"].includes(key)) value = moment(resident[key]).format("DD MMM YYYY");
                                if (key === "nationality") value = t("country." + resident[key]);

                                return (
                                    <div className={"Resident__info"} key={"resident-info_" + index}>
                                        <span className={"Resident__info-label"}>{key}</span>
                                        <span className={"Resident__info-value"}>{value}</span>
                                    </div>
                                )
                            })}
                            <div className={"Resident__actions"}>
                                <button className={"btn btn-secondary"} onClick={() => setEditingResident(resident)}>Edit</button>
                                {resident.reservation_id && <a className={"link link_grey"} onClick={async () => {
                                    await navigator.clipboard.writeText(resident.reservation_id)
                                }}>Copy reservation id</a>}
                            </div>

                        </div>
                    )
                })}
            </div>
            {editingResident.id !== "new" && <button className={"btn btn-primary"} style={{marginLeft: "auto", width: "fit-content"}} onClick={() => {
                setResidents(prev => [...prev, {id: "new"}])
                setEditingResident({id: "new"})
            }}>Add resident</button>}
        </div>
    )
}


export default RoomResidents;
