import {get, patch, post} from "./index.js";
import moment from "moment";
import {utils} from "../utils";

const NAMESPACE = "/backoffice/visit"

const list = async (paginationKey, searchKey, filters) => {
    return await get(NAMESPACE + "/list", {paginationKey, searchKey, filters})
}

const accept = async (visit, dateInput) => {
    return await post(NAMESPACE + "/accept", {
        landlord_id: visit.landlord.id,
        visit_id: visit.id,
        selectedDate: dateInput
    })
};

const reject = async (visit) => {
    return await post(NAMESPACE + "/reject", {
        landlord_id: visit.landlord.id,
        visit_id: visit.id
    })
};

const updateNote = async (visit, note_type, note) => {
    return await post(NAMESPACE + "/updateNote", {
        visit_id: visit.id,
        note_type:note_type,
        note:note
    })
};

const isExpired=(visit)=>{
    return utils.getExpirationDate(visit.requestDate).isBefore(moment());
};

const isFinished=(visit)=>{
    return !visit.rejectedDate && visit.selectedDate && moment(visit.requestDate).add(1, 'day').isBefore(moment());
};

export const visitService = {
    list,
    accept,
    reject,
    updateNote,
    isExpired,
    isFinished
}
