import React, {useCallback, useEffect, useRef, useState} from 'react';
import PageWrapper from "../components/PageWrapper";
import {dataService} from "../../services/dataService";
import Spinner from "../shared/Spinner";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useDialogContext} from "../App";

const PromoCodes = ({inline}) => {

    const {setModal} = useDialogContext();

    const [promoCodes, setPromoCodes] = useState({});
    const [loading, setLoading] = useState(true);
    const editCode = useRef({code: "", type: "percent", email: "", value: 0});

    useEffect(() => {
        (async () => {
            let _promoCodes = await dataService.getPromoCodes();
            setPromoCodes(_promoCodes);
            setLoading(false);
        })();
    }, []);

    const handleSaveCode = async () => {
        let _code = editCode.current.code.toUpperCase();
        let _promoCodes = {...promoCodes, [_code]: {
                "bookingfee": {
                    "type": editCode.current.type,
                    "email": editCode.current.email,
                    "value": editCode.current.value
                }
            }};
        if(editCode.current.original && editCode.current.original !== editCode.current.code) {
            delete _promoCodes[editCode.current.original];
        }
        await dataService.savePromoCodes(_promoCodes);
        setPromoCodes(_promoCodes);
        editCode.current = {code: "", type: "percent", email: "", value: 0};
        setModal(m=>({...m, hide: true}));
    };

    const handleChangeEditCode = (e) => {
        let val = e.target.value;
        let tname = e.target.name;
        if (e.target.email) editCode.current["email"] = e.target.email;
        if(tname === "code") {
            val = val.toUpperCase();
        } else if (tname === "value") {
            val = parseInt(val);
        }
        editCode.current[tname] = val;
    }

    const PromoCodeEdit = () => {
        return <form className={"AddPromoCodeForm"}>
            <div className="form-group mb-2">
                <label>Code</label>
                <input autoFocus type="text" className="form-control" name={"code"} defaultValue={editCode.current.code} onChange={handleChangeEditCode}/>
            </div>
            <div className="form-group mb-2">
                <label>Type</label>
                <select className="form-control" defaultValue={editCode.current.type} name={"type"} onChange={handleChangeEditCode}>
                    <option value="percent">Percent</option>
                    <option value="fixed">Fixed</option>
                </select>
            </div>
            <div className="form-group mb-2">
                <label>Email</label>
                <input type="email" className="form-control defaultText" defaultValue={editCode.current.email} name={"email"} onChange={handleChangeEditCode}/>
            </div>
            <div className="form-group mb-2">
                <label>Value</label>
                <input type="number" className="form-control" defaultValue={editCode.current.value} name={"value"} onChange={handleChangeEditCode}/>
            </div>
        </form>
    }

    const handleAddNewCode = () => {
        setModal({
            title: "Add New Promo Code",
            size: "sm",
            message: <PromoCodeEdit/>,
            onSubmit: handleSaveCode
        })
    }

    const handleEditCode = (code) => () => {
        let _promocode = promoCodes[code]["bookingfee"];
        editCode.current = {code, type: _promocode.type, email: _promocode.email, value: _promocode.value, original: code};
        setModal({
            title: "Edit Promo Code",
            size: "sm",
            message: <PromoCodeEdit/>,
            onSubmit: handleSaveCode
        })
    }

    const handleDeleteCode = (code) => () => {
        setModal({
            title: "Delete Promo Code",
            size: "sm",
            message: <div>Are you sure you want to delete this promo code?</div>,
            onSubmit: async () => {
                let _promoCodes = {...promoCodes};
                delete _promoCodes[code];
                await dataService.savePromoCodes(_promoCodes);
                setPromoCodes(_promoCodes);
                setModal(m=>({...m, hide: true}));
            }
        })
    }

    return (<PageWrapper breadcrumbs={[{title: "Promo Codes"}]}
                        title={"Promo Codes"}
                        inline={inline}>
        <div className={"PromoCodes"}>
            {loading ? <Spinner inline/> : <>
                <button className={"btn btn-success mb-2"} onClick={handleAddNewCode}><i className={"mdi mdi-plus"}/>{"Add new promo code"}</button>
                <table className={"table border"}>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Type</th>
                            <th>Email</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(promoCodes).map((code, index) => {
                            let promoCode = promoCodes[code]["bookingfee"];

                            return (<tr key={index}>
                                        <td>{code}</td>
                                        <td>{promoCode.type}</td>
                                        <td>{promoCode.email}</td>
                                        <td>{promoCode.value + (promoCode.type === "fixed" ? "€" : "%")}</td>
                                        <td>
                                            <div className={"d-flex justify-content-end table_actions"}>
                                                <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                                    <i className={"mdi mdi-pencil"} onClick={handleEditCode(code)}></i>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                    <i className={"mdi mdi-trash-can"} onClick={handleDeleteCode(code)}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                </tr>);
                        })}
                    </tbody>
                </table>
            </>}
        </div>
    </PageWrapper>);
};

export default PromoCodes;
