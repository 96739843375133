import {get, patch, post} from "./index.js";

const NAMESPACE = "/backoffice/invoices"

const list = async (paginationKey, searchKey, filters, pageSize) => {
    return await get(NAMESPACE + "/list", {paginationKey, searchKey, filters, pageSize})
}

const assignReservation = async (invoiceId, reservationId) => {
    return await post(NAMESPACE + "/assignReservation", {invoiceId, reservationId})
}


const updateNote = async (invoiceId, note) => {
    return await post(NAMESPACE + "/updateNote", {invoiceId, note})
}

export const invoiceService = {
    list,
    assignReservation,
    updateNote
}