import React from 'react';
import DatePicker from "react-datepicker";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import moment from 'moment';



const Unavailability = ({start, end, reason, index, handleChange, outages}) => {

    const motives = [
        {value: 'Busy by booking another platform', label: "Busy by booking another platform"},
        {value: 'Occupied by direct booking', label: 'Occupied by direct booking'},
        {value: 'Works and maintenance', label: 'Works and maintenance'},
        {value: 'Other', label: 'Other'}
    ]

    return (
        <>
            <div className={"Unavailability-container"}>
                <div className={"d-flex flex-column mr-1"}>
                    {index === 0 &&
                    <label>Start</label>}
                    <DatePicker className={"form-control datepicker-input"}
                                selected={start}
                                minDate={moment().toDate()}
                                dateFormat={'dd/MM/yy'}
                                onChange={(date) => {
                                    outages[index]['start'] = date;
                                    handleChange('unavailability', outages);
                                }}/>
                </div>
                <div className={"d-flex flex-column mr-1"}>
                    {index === 0 &&
                    <label>End</label>}
                    <DatePicker className={"form-control datepicker-input"}
                                selected={end}
                                dateFormat={'dd/MM/yy'}
                                minDate={moment().toDate()}
                                onChange={(date) => {
                                    outages[index]['end'] = date;
                                    handleChange('unavailability', outages);
                                }}
                    />
                </div>
                <div className={"d-flex flex-column"}>
                    {index === 0 &&
                    <label>Motive</label>}

                    <Select
                        className={"Select-motives"}
                        defaultValue={ reason && {
                            value: reason ,
                            label: reason
                        }}
                        options={motives}
                        onChange={(option) => {
                            outages[index]['reason'] = option.value;
                            handleChange('unavailability', outages);
                        }}
                    />
                </div>
                <div>
                    <button type={'button'} className={"btn btn-danger"} onClick={() => {
                        outages.splice(index, 1);
                        handleChange('unavailability', outages);
                    }}><i className={"mdi mdi-close"}/>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Unavailability;