import {get, post} from "./index.js";

const NAMESPACE = "/backoffice/ambassador"


const listAll = async (page, searchKey, filters) => {
    return await get(NAMESPACE + "/list", {page, filters: {...filters, searchKey}});
}

const editAmbassador = async (ambassadorId, editFields) => {
    return await post(`${NAMESPACE}/${ambassadorId}/edit`, editFields)
};

const createAmbassador = async (ambassador) => {
    return await post(`${NAMESPACE}/create`, ambassador)
};

const payWithdrawal = async (ambassadorId, widthdrawal_id) => {
    return await post(`${NAMESPACE}/${ambassadorId}/pay`, {widthdrawal_id})

}

const listPayments = async (page, searchKey, filters) => {
    return await get(`${NAMESPACE}/payments`, {page, filters: {...filters, searchKey}})

}

export const ambassadorService = {
    listAll,
    editAmbassador,
    payWithdrawal,
    listPayments,
    createAmbassador
}
