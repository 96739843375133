import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import defaultAvatar from "../../assets/images/avatar.png";
import RoomResume from "./RoomResume";
import {Link} from "react-router-dom";
import {propertyService} from "../../services/propertyService";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';

const PropertyCard = ({
                          data: property,
                          disableHandler,
                          landlord,
                          disableRoomHandler,
                          enableHandler,
                          enableRoomHandler,
                          deleteHandler,
                          deleteProperty,
                          handlePreview,
                          confirmAvailabilityHandler,
                          validateHandler
                      }) => {

    const {t} = useTranslation();

    // const [landlord, setLandlord] = useState(null);
    // let landlordPhoto;
    //
    // useEffect(() => {
    //     (async () => {
    //         let _landlord = await landlordService.getLandlordById(property.landlord)
    //         setLandlord(_landlord);
    //         landlordPhoto = _landlord.photo || defaultAvatar;
    //     })();
    // }, [])

    if(!landlord) landlord = property.landlord;

    const S3_URL = "https://inlife-new.s3.eu-west-1.amazonaws.com/houses/";

    let landlordPhoto = property.landlord_photo || defaultAvatar;
    let propertyPhoto = property.photos?.featured_0?.[0]?.md || (property.integration_id && property.photos?.[0]?.md) || (S3_URL + property.id + property.photos?.[0]);
    let numRooms = propertyService.getPropertyNumRooms(property);
    let numActive = property.rooms ? Object.values(property.rooms).length : 0;

    const clampText = (text, length) => {
        if (!text || text.length < length) return text;
        return text.substring(0, length).trim() + '...';
    };


    const [showRooms, setShowRooms] = useState(false);


    const showRoomsHandler = (e) => {
        e.stopPropagation();
        if (showRooms) {
            setShowRooms(false);
            return;
        }
        setShowRooms(true);
    };

    let isPropertyDisabled = () => {
        if (property.accommodation !== 'apartment' && property.disabled) return property.disabled
        if (property.accommodation === 'apartment') return property.rooms && Object.values(property.rooms).some(r => r.disabled);
        return Object.values(property.rooms || {}).every(room => room.disabled);
    }

    let propertyStatus = useMemo(() => {
        // bolinha verde se o anúncio estiver publicado,
        // bolinha amarela se estiver em progresso
        // bolinha vermelha se estiver inactivado
        if(!property) return;
        if(!property.finished) return "not_finished";
        if(isPropertyDisabled()) return "disabled";
        if(property.finished) return "finished";

    }, [property])

    return (
        <>
            {!property.justDeleted ?
            <>
            <div className="Card card" style={{height: "180px"}}>
                <div style={{backgroundImage: `url('${propertyPhoto}')`}} className={"Card_featured-photo"}>
                    <div className={"top-left-image " + propertyStatus} />
                    <div className={"bottom-image mb-1 ml-1"}>{property.id?.split("-")[0]}</div>
                    {property.validated && <div className={"mdi mdi-cloud-check validated-icon"}></div>}
                    {!property.validated && property.verificationAsked &&<OverlayTrigger overlay={<Tooltip>Verification Request</Tooltip>}>
                        <div className={"fa fa-exclamation-circle warning-icon"}/>
                    </OverlayTrigger>}
                </div>
                <div className="Card_content">
                    <div className={"Card_property"}>
                        {property?.title &&
                        <label>{clampText(property?.title["en"], 35)}</label>}
                        {property.address && <div><i
                            className="mdi mdi-map-marker mr-2"/>{property.address} {property.internal_name &&
                        <small> ({property.internal_name})</small>}</div>}
                        {property.accommodation === 'apartment' && <div><i
                            className={"fa fa-users mr-2"}/>Up to {property.people} {property.people > 1 ? 'people' : 'person'}
                        </div>}
                        <div><i
                            className="mdi mdi-home mr-2"/>{t(`property_resume.accommodation_type.${property.accommodation}`)}
                        </div>
                        {property.rooms && <div><i className="mdi mdi-coin mr-2"/>Monthly rent: {property.accommodation === 'apartment' ? propertyService.getPricesInterval(property.rooms['room_1'], property) : propertyService.getRentsInterval(property)}
                        </div>}
                    </div>
                    {landlord &&
                    <div className={"Card_tenant"}>
                        <div className={"Card_tenant-image-wrapper"}>
                            <div style={{backgroundImage: `url(${landlordPhoto})`}}
                                 className={"Card_tenant-image"}/>
                        </div>
                        <div className={"Card_tenant-info"}>
                            <label>{property.landlord_name} <Link to={"/landlords/" + property.landlord_id}>See Profile</Link></label>
                            <div className="mdi mdi-phone">{property.landlord_phone || "N/A"}</div>
                            <div className="mdi mdi-email-outline">{property.landlord_email || "N/A"}</div>
                            <div className={"mdi mdi-cash"}>{property.landlord_iban || "N/A"}</div>
                            <div className={"mdi mdi-file-document-edit-outline"}>{property.landlord_nif || "N/A"}</div>
                            <div>Commission {property.landlord_commission || "N/A"}</div>
                            <div>Accepted Ratio {property.landlord_acceptedRatio || "N/A"}</div>
                            <div>Accepted Reservations {property.landlord_acceptedReservations || "N/A"}</div>
                        </div>
                    </div>}
                    <div className={"Card_info property-info"}>
                        <label>{property.accommodation === 'apartment' ? (numRooms || 1) : numRooms} {(numRooms > 1 ? "Rooms" : "Room") + " in "+ (property.accommodation === 'residence' ? 'residence' : 'property')}</label>
                        {property.instantBooking && <p>Instant Bookings</p>}
                        <p>Visit types: {property.accept_visit_type || "Not defined"}</p>
                        {property.lastElasticUpdate && <div>Updated {Math.abs(moment(property.lastElasticUpdate).diff(moment().startOf("day"), "days"))} days ago</div>}
                        {property.accommodation === 'apartment' ?
                            <p>1 Active listing</p> :
                            <p>{numActive} Active Listings</p>}
                        {property.rooms && <p className={'RoomResume__info-title'}>
                            {Object.values(property.rooms).length + ' ' + (Object.values(property.rooms).length > 1 ? 'Rooms' : 'Room')} {property.numBathroom ? ' | ' + property.numBathroom + ' ' + (property.numBathroom > 1 ? 'Bathrooms' : 'Bathroom') : ""}
                        </p>}
                        <p className={"verified"}>{(property.verified && property.validated ? "Verified and Validated" : (property.verified ? "Verified" : (property.validated ? "Validated" : "")))}</p>
                    </div>
                    <div className={"Card_actions"}>
                        <div className={"Room_actions"}>
                            <div className={"property-actions mr-3"}>
                                <Link to={`/property/${property.id}`} style={{textDecoration: "none"}}>
                                    <div className={"action-edit clickable"} style={{maxHeight: '27px'}}>
                                        <span className={"fa fa-pencil mr-2"}/>
                                        <span>Edit house</span>
                                    </div>
                                </Link>
                                <div
                                    className={`action-edit clickable ${property.validated || property.disabled ? 'button-disabled' : 'validate'} mt-2`}
                                    onClick={validateHandler(property)}>
                                    <span className={"mdi mdi-cloud-check mr-2"}/>
                                    <span>Validate</span>
                                </div>
                                <div className={"action-edit delete clickable mt-2"} onClick={deleteProperty(property)}>
                                    <span className={"fa fa-times-circle mr-2 w-50"}/>
                                    <span>Delete house</span>
                                </div>
                                <div
                                    className={`action-edit ${property.disabled ? 'activate' : 'inactivate'} clickable mt-2`}
                                    onClick={property.disabled ? enableHandler(property) : disableHandler(property)}>
                                        <span
                                            className={`fa ${property.disabled ? 'fa-check-circle-o' : 'fa-ban'} mr-2`}/>
                                    <span>{property.disabled ? 'Enable' : 'Disable'}</span>
                                </div>
                            </div>

                            {property.rooms && numActive > 0 ? <div onClick={showRoomsHandler}>
                                    {showRooms ? <i className={"fa fa-arrow-up clickable"}/> :
                                        <i className={"fa fa-arrow-down clickable"}/>}</div> :
                                <div className={"mr-1"}/>}
                        </div>
                    </div>
                    <div className={"Card_extra"}>
                        <Dropdown drop={"start"}>
                            <Dropdown.Toggle variant="" id="dropdownMenuButton1"/>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={async ()=>{
                                    await navigator.clipboard?.writeText(property.id);
                                    console.log("property id copied to clipboard");
                                }}>Copy ID</Dropdown.Item>
                                <Dropdown.Item onClick={async ()=>{
                                    await navigator.clipboard?.writeText(JSON.stringify(property));
                                    console.log("property copied to clipboard");
                                }}>Copy JSON</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{
                                    window["property"] = property;
                                    console.log("property stored as variable", '"property"');
                                }}>Store as variable</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
                {property.rooms && showRooms &&
                <div className={"Rooms"}>
                    {Object.keys(property.rooms).map((roomName) =>
                        <RoomResume
                            key={property.rooms[roomName].id}
                            roomName={roomName}
                            room={property.rooms[roomName]}
                            property={property}
                            onDisable={disableRoomHandler}
                            onEnable={enableRoomHandler}
                            onDelete={deleteHandler}
                            onConfirm={confirmAvailabilityHandler}
                            preview={handlePreview}
                        />)}
                </div>}
            </>:<></>}
        </>
    )
};

export default PropertyCard;
