import {useTranslation} from "react-i18next";
import moment from "moment";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useRef, useState} from "react";
import PageWrapper from "../components/PageWrapper";
import DataCardTable from "../components/DataCardTable";
import {reservationService} from "../../services/reservationService";
import {formatCurrency, getStay} from "../shared/utils";
import Select from "react-select";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";
import DatePicker from "react-datepicker";


const Finance = ({inline, overrideFetch, landlord, cardTableStyles}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const [editingFields, setEditingFields] = useState({});


    const conditionalRowStyles = [
        {
            when: row => row.transferStatus === "yes",
            style: {
                backgroundColor: '#c2fabd',
            }
        },
        {
            when: row => row.transferStatus === "inserted",
            style: {
                backgroundColor: '#bdfaf0',
            }
        },
        {
            when: row => row.transferStatus === "cancelled" || (row.cancellation && row.cancellation.answer !== "not_accepted"),
            style: {
                backgroundColor: '#f5ccce',
            }
        }
    ]

    const seePaymentDetails = (reservation) => async () => {
        if(!reservation?.payment?.method) return;
        if(reservation.payment.method === "stripe") {
            window.open("https://dashboard.stripe.com/payments/" + reservation.payment.auth, "_blank");
        } else if (reservation.payment.method === "paypal") {
            let start_date = moment(reservation.paymentDate).subtract(1, "day").format("YYYY-MM-DD");
            let end_date = moment(reservation.paymentDate).add(1, "day").format("YYYY-MM-DD");
            let sel_id;
            if(reservation.payment.status === "auth") {
                sel_id = reservation.payment.auth;
            } else if (reservation.payment.status === "paid") {
                try {
                    let paymentInfo = await reservationService.getPaypalPaymentInfo(reservation.payment.id);
                    sel_id = paymentInfo?.transactions?.[0]?.related_resources?.[0]?.sale?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[1]?.capture?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[0]?.authorization?.id;
                } catch (e) {
                    return window.open("https://www.paypal.com/activity/payment/" + (reservation.payment.auth||reservation.payment.id), "_blank");
                }
            }
            window.open(process.env.REACT_APP_PAYPAL_URL + "/myaccount/transactions/?free_text_search=" + sel_id + "&start_date=" + start_date + "&end_date=" + end_date, "_blank");
        }
    }


    const handleEditField = (r_id, field) => {
        setEditingFields(prev => {
            //if(!prev[r_id]) prev[r_id]= [];
            //prev[r_id] = prev[r_id].includes(field) ? prev[r_id].filter(f => f !== field) : [...prev[r_id], field];
            if(prev[r_id]?.includes(field)) return ({});
            return ({[r_id]: [field]});
        })
    };


    const handleFieldChange = async (reservation, field, value) => {
        try {
            if (value !== "cancelled") reservation[field] = value;
            handleEditField(reservation.id, field);
            if (field === "overrideValue") await reservationService.overrideTransfer(reservation.id, value);
            if (field === "transferStatus") {
                if (value === "cancelled") {
                    return await handleReservationCancellation(reservation);
                }
                await reservationService.updateTransferStatus(reservation.id, value);
            }
        } catch (e) {
            console.log("Error trying to update " + field + " of reservation: " + reservation.id, e)
        }

    }


    const handleReservationCancellation = async (reservation) => {
        setModal({
            title: "Cancel reservation",
            size: "md",
            message: <>
                <div className={"d-flex flex-column"}>
                    <span>Are you sure you want to <strong>cancel</strong> {reservation.user.name}'s reservation?</span>
                    <span className={"mt-1"}>Doing so, both tenant and landlord will be notified by email.</span>
                </div>
            </>,
            onSubmit: async () => {
                let refundAmount = 0;
                let discountPercentage = 100;
                let maxRefund = reservation.payment.bookingFee + reservation.payment.firstRent + (reservation.payment.extraTenantsValue || 0);
                setModal(m => ({
                    ...m,
                    message: <>
                        <label htmlFor={"refundAmount"}>Insert the refund amount:</label>
                        <input type="number" className="form-control" defaultValue={refundAmount}
                               onChange={e => refundAmount = e.target.valueAsNumber}
                               id="refundAmount" name={"refundAmount"} placeholder="Refund amount"/>
                        <small>Max refund amount: {formatCurrency(maxRefund)}</small>
                        <label htmlFor={"discountPercentage"} className={"mt-1"}>Insert the promo code percentage (0 - 100):</label>
                        <input type="number" className="form-control" defaultValue={discountPercentage}
                               onChange={e => discountPercentage = e.target.valueAsNumber}
                               max={100} min={0}
                               id="discountPercentage" name={"discountPercentage"} placeholder="Percentage"/>
                    </>,
                    onSubmit:
                        async () => {
                            if (refundAmount > maxRefund) {
                                cogoToast.error("Refund amount cannot be greater than the total amount of the reservation payment, " + formatCurrency(maxRefund));
                                return;
                            }
                            if (discountPercentage > 100 || discountPercentage < 0) {
                                cogoToast.error("Discount percentage value invalid. Insert a value between 0 and 100");
                                return;
                            }
                            try {
                                await reservationService.cancelReservation(reservation.id, refundAmount, discountPercentage);
                                reservation.transferStatus = "cancelled";
                                cogoToast.success("Reservation cancelled");

                            } catch (e) {
                                cogoToast.error("Error cancelling reservation");
                            }
                            setModal(m => ({...m, hide: true}));
                        }
                }));
            }
        })
    }

    const reservationChangesHandler = async (fields, reservation, fieldName) => {
        setModal({
            title: "change reservation",
            size: "md",
            message: <>
                <div className={"d-flex flex-column"}>
                    <span>Are you sure you want to <strong>change</strong> {(reservation.user_info?.name || reservation.user.name)}'s reservation?</span>
                    <span>{`Old ${fieldName}: ${['moveIn', 'moveOut'].includes(fieldName) ? moment(reservation[fieldName]).format('DD/MM/yyyy') : (reservation.payment.landlordCommission || 0)}. New ${fieldName}: ${['moveIn', 'moveOut'].includes(fieldName) ? moment(fields[fieldName]).format('DD/MM/yyyy') : fields[fieldName]}`}</span>
                </div>
            </>,
            onSubmit:
                async () => {
                    try {
                        await reservationService.editReservation(reservation.id, fields);
                        if(fieldName === "commission" && reservation.payment){
                            reservation.payment["landlordCommission"] = fields[fieldName];
                        }else{
                            reservation[fieldName] = fields[fieldName];
                        }
                        cogoToast.success("Reservation changed");

                    } catch (e) {
                        cogoToast.error("Error editing reservation");
                    }
                    setModal(m => ({...m, hide: true}));
                }
        });
    };

    const debounceHandler = useRef(null);

    const updateNote = (reservation) => async (e) => {
        let note = e.target.value;
        clearTimeout(debounceHandler.current);
        debounceHandler.current = setTimeout(async () => {
            await reservationService.updateNote(reservation, "finance", note);
            reservation.notes = reservation.notes || [];
            reservation.notes["finance"] = note;
        }, 1000);
    }


    const dataColumns = [
        {
            name: 'ID',
            width: "100px",
            selector: row => row?.id,
            cell: row => <div data-tag="allowRowEvents">{"#" + row?.id?.split("-")[0]}</div>
        },
        {
            name: 'Tenant Name',
            selector: row => row?.user,
            width: "130px",
            cell: row => <div data-tag="allowRowEvents">{row?.user?.name}</div>
        },
        {
            name: 'Nationality',
            selector: row => row?.user,
            cell: row => <div data-tag="allowRowEvents">{row?.user?.nationality ? t("country." + row?.user?.nationality) : '-'}</div>
        },
        {
            name: 'City',
            selector: row => row?.user,
            cell: row => <div data-tag="allowRowEvents">{row?.property?.region}</div>
        },
        {
            name: 'Request Date',
            selector: row => row?.requestDate,
            cell: row => <div data-tag="allowRowEvents">{row ? moment(row?.requestDate).format("DD/MM/YY") : "-"}</div>
        },
        {
            name: 'Invoice Date',
            selector: row => row.date,
            cell: row => <div data-tag="allowRowEvents">{row.date ? moment(row.date).format("DD/MM/YY") : "-"}</div>
        },
        {
            name: 'Move-In',
            selector: row => row?.moveIn,
            conditionalCellStyles: [
                {
                    when: row => moment(row?.moveIn).add(1, "day").isBefore(moment()) && row.transferStatus !== "yes",
                    style: {
                        backgroundColor: '#f5ccce'
                    }
                }],
            cell: row =>
                <div data-tag="allowRowEvents">
                    <DatePicker
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPosition={'fixed'}
                        selected={new Date(row.moveIn)}
                        onChange={(date) => {
                            reservationChangesHandler({'moveIn': date.toISOString()}, row, "moveIn");
                        }}
                        dateFormat={'dd/MM/yy'}
                        className="form-control reservationDate"
                        withPortal
                    />
                </div>
        },
        {
            name: 'Move-Out',
            selector: row => row?.moveOut,
            cell: row => <div data-tag="allowRowEvents">
                <DatePicker
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPosition={'fixed'}
                    selected={new Date(row.moveOut)}
                    onChange={(date) => {
                        reservationChangesHandler({'moveOut': date.toISOString()}, row, "moveOut");
                    }}
                    dateFormat={'dd/MM/yy'}
                    className="form-control reservationDate"
                    withPortal
                />
            </div>
        },
        {
            name: 'Rent',
            cell: row => <div data-tag="allowRowEvents">{row ? reservationService.calcRent(row) : "-"}</div>
        },
        {
            name: 'Stay',
            selector: row => row?.id,
            cell: row => <div data-tag="allowRowEvents">{row ? getStay(row?.moveIn, row?.moveOut, row?.payment?.rentType||row?.property?.incompleteRentType) : "-"}</div>
        },
        {
            name: 'Contract Value',
            right: true,
            width: "130px",
            selector: row => row?.totalContractValue,
            cell: row => formatCurrency(row?.totalContractValue)
        },
        {
            name: 'Commission Value (without IVA)',
            center: true,
            minWidth: "270px",
            selector: row => row?.commission,
            cell: row =>
                <div>
                    <OverlayTrigger overlay={<Tooltip>Contract
                        Value: {formatCurrency(row?.totalContractValue)}<br/>Commission: {row?.payment?.landlordCommission}%
                        ({formatCurrency((row?.payment?.landlordCommission / 100) * row?.totalContractValue)})</Tooltip>}>
                        <span>{formatCurrency((row?.payment?.landlordCommission / 100) * row?.totalContractValue)}</span>
                    </OverlayTrigger>
                    {row.penalty &&
                    <OverlayTrigger
                        overlay={<Tooltip>An extra fee of 2% is charged for canceling {row?.penalty?.tenantName}'s
                            reservation</Tooltip>}>
                       <i className="mdi mdi-information-outline">&nbsp;</i>
                    </OverlayTrigger>}
                </div>
        },
        {
            name: 'Commission',
            center: true,
            selector: row => row,
            cell: row =>
                <input type={"number"} className={"form-control"} defaultValue={row?.payment?.landlordCommission}
                    onKeyDown={(e) => {if(e.key === "Enter") reservationChangesHandler({'commission': e.target.value}, row, "commission")}}/>
        },
        {
            name: 'Booking Fee',
            right: true,
            maxWidth: "100px",
            selector: row => row?.payment?.bookingFee,
            cell: row => <span>{(row?.payment?.bookingFee != null) ? formatCurrency(row?.payment.bookingFee) : "-"}</span>
        },
        {
            name: 'Total Paid',
            right: true,
            maxWidth: "100px",
            selector: row => row?.payment,
            cell: row => row?.payment?.firstRent && <Link><span className="text-capitalize clickable" onClick={seePaymentDetails(row)}>{formatCurrency((row?.payment?.bookingFee||0) + row?.payment?.firstRent + (row?.payment?.extraTenantsValue||0))}</span></Link>
        },
        {
            name: 'Transfer Value',
            center: true,
            selector: row => row.overrideValue,
            cell: row => <>{editingFields[row.id]?.includes("overrideValue") ? <input autoFocus style={{maxWidth: "100px"}} type={"number"} placeholder={"Transfer value"} onBlur={(e) => handleFieldChange(row, "overrideValue", e.target.valueAsNumber)} /> :
                <div data-tag="allowRowEvents" onClick={() => handleEditField(row.id,"overrideValue")}>{formatCurrency(row.overrideValue ? row.overrideValue : ((row?.payment?.bookingFee||0) + row?.payment?.firstRent + (row?.payment?.extraTenantsValue||0)) - (row?.payment?.bookingFee || 0) - (((row?.payment?.landlordCommission/100)*row?.totalContractValue) * 1.23))}</div>}</>
        },
        {
            name: 'Senhorio',
            selector: row => row?.landlord,
            cell: row => <Link to={`/landlords/${row.landlord?.id}`}><div data-tag="allowRowEvents">{row.landlord?.name}</div></Link>
        },
        {
            name: 'Fiscal Name',
            minWidth: "200px",
            center: true,
            selector: row => row?.fiscalInfo,
            cell: row => <div data-tag="allowRowEvents">{row?.fiscalInfo?.fiscalName}</div>
        },
        {
            name: 'Bank Account Holder',
            minWidth: "200px",
            center: true,
            selector: row => row?.bankInfo,
            cell: row => <div data-tag="allowRowEvents">{row?.bankInfo?.fiscalName}</div>
        },
        {
            name: 'Country',
            center: true,
            selector: row => row?.id,
            cell: row => <div data-tag="allowRowEvents">{row?.bankInfo?.country ? t("country." + row?.bankInfo.country.toLowerCase()) : '-'}</div>
        },
        {
            name: 'IBAN',
            center: true,
            minWidth: "250px",
            conditionalCellStyles: [
                {
                    when: row => row.bankInfo?.iban && !row.bankInfo?.valid,
                    style: {
                        backgroundColor: '#f5ccce'
                    }
                }],
            selector: row => row?.bankInfo,
            cell: row => <OverlayTrigger delay={{hide: 700 }} overlay={<Tooltip hidden={row.bankInfo?.valid || !row.bankInfo?.proofDocumentUrl}><Link to={`/landlords/${row.landlord?.id}?validateIban=${row.bankInfo?.id}`}>Needs Validation</Link></Tooltip>}>
                <div data-tag="allowRowEvents">{row?.bankInfo?.iban}</div>
            </OverlayTrigger>
        },
        {
            name: 'Edit',
            minWidth: "50px",
            center: true,
            button: true,
            selector: row => row.property,
            cell: row =>
                    <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                        <a href={`/property/${row.property.id}`}><i className={"mdi mdi-pencil-box-outline preview-action"} /></a>
                    </OverlayTrigger>,
        },
        {
            name: 'Transferred',
            minWidth: "110px",
            center: true,
            selector: row => row.transferStatus,
            cell: row => <>{editingFields[row.id]?.includes("transferStatus") ? <Select autoFocus className={"transferred-select"} options={["yes", "no", "inserted", "cancelled"].map(v => ({value: v, label: v}))} defaultValue={[{value: row.transferStatus, label: row.transferStatus}]} onChange={(o) => handleFieldChange(row, "transferStatus", o.value)} onBlur={() => handleEditField(row.id, "transferStatus")} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}  menuPosition={'fixed'} /> :
                <div data-tag="allowRowEvents" onClick={() => handleEditField(row.id,"transferStatus")}>{row.transferStatus || "-"}</div>}</>
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={updateNote(row)} rows="2" defaultValue={row?.notes?.finance}/>
        },
        {
            name: 'Landlord Invoice',
            center: true,
            selector: row => row.landlord_invoice,
            cell: row => <div className={`${row.landlord_invoice?.permalink ? "clickable" : ""}`} data-tag="allowRowEvents" onClick={() => row.landlord_invoice?.permalink && window.open(row.landlord_invoice?.permalink, '_blank').focus()}><i className={"mdi mdi-download"} /></div>
        },
        {
            name: 'Student Invoice',
            center: true,
            selector: row => row.student_invoice,
            cell: row => <div className={`${row.student_invoice?.permalink ? "clickable" : ""}`} data-tag="allowRowEvents" onClick={() => row.student_invoice?.permalink && window.open(row.student_invoice?.permalink, '_blank').focus()}><i className={"mdi mdi-download"} /></div>
        }
    ]

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("finance_filters")) || {"sortField": "moveIn", "sorts": "asc"});

    return (
        <PageWrapper breadcrumbs={[{title: "Finance"}]}
                     title={"Finance"}
                     inline={inline}
                     filters={filters} setFilters={setFilters}>
            <DataCardTable
                customClass={cardTableStyles}
                fixedFirstColumn
                columns={dataColumns}
                dataType={"finance"}
                fetchData={overrideFetch || reservationService.financeList}
                conditionalRowStyles={conditionalRowStyles}
                filters={filters}
            />
        </PageWrapper>

    )
};

export default Finance;
