import React, {useMemo} from 'react'
import defaultAvatar from "../../assets/images/avatar.png";
import {Link} from "react-router-dom";
import moment from "moment";
import {formatCurrency, toCapitalize} from "../shared/utils";
import {useTranslation} from "react-i18next";
import {tenantService} from "../../services/tenantService";
import cogoToast from "cogo-toast";
import {useDialogContext} from "../App";


const TenantCard = ({data: user}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();


    const preferredLocation = useMemo(()=> {
        let location = user.location;
        switch(location) {
            case "metro":
                return (user.metro || "N.D.") + " line";
            case "neighborhood":
                return user.neighborhood;
            case "university":
                return user.university;
            case "none":
                return "Without preference"
        }
    }, [user])

    const handleArchiveUser = () => {
        setModal({
            title: "Archive User",
            size:"md",
            message: <>Are you sure you want to <strong>archive</strong> {user.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.archiveUser(user.id);
                user.archived = true;
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Account archived.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const handleUnarchiveUser = () => {
        setModal({
            title: "Unarchive User",
            size:"md",
            message: <>Are you sure you want to <strong>unarchive</strong> {user.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.unarchiveUser(user.id);
                user.archived = false;
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Account unarchived.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    return (
        <div className="card col-12 col-sm-6 col-md-4 col-xl-3">
            <div className={"TenantCard"}>
                <div className={"TenantCard-top"}>
                    <div className={"tenant-photo"} style={{backgroundImage: `url(${user.photo || defaultAvatar})`}}>
                        {user.nationality &&
                        <div className={'RoundedCircleFlag'}>
                            <div className={'CountryFlagCircle'}
                                 style={{backgroundImage: `url(https://github.com/madebybowtie/FlagKit/raw/master/Assets/PNG/${user.nationality.toUpperCase()}@2x.png?raw=true)`}}/>
                        </div>}
                    </div>
                    <div className={"tenant-info"}>
                        <div>
                            <div className={"d-inline-flex"}>
                                <span className={"mr-2"} style={{fontSize: "10px"}}><strong>{user.name}</strong></span>
                                <div className={"ratingStars"}>
                                    {Array.from({length: 5}, (_, i) => i + 1).map(n => {
                                        return <i key={user.id + "star-" + n}
                                                  className={`mdi mdi-star ${n <= user.rating ? "yellow" : "grey"}`}/>
                                    })}
                                </div>
                            </div>
                            <span className={"cityName"}>{user.city || "N.D"}</span>
                        </div>
                        <div>{(user.nationality ? t("country." + user.nationality) : "n.d") + " - " + (toCapitalize(user.gender) || "rather not say") + " - "} {user.birthdate ? tenantService.getAge(new Date(parseInt(user.birthdate?.split("-")[1]), parseInt(user.birthdate.split("-")[0]) - 1)) + " years" : "n.d"}
                        </div>
                        <div>{user.occupation ? toCapitalize(user.occupation) + " at " + (user.occupation === "student" ? user.university?.split(" - ")[0] || "" : user.occupation_detail || "") : "-"}
                        </div>
                        <div className={"contacts"}>
                            <span className={"mr-2"}>{user.email}</span>
                            <span>{(user.dialCode ? user.dialCode + " " : "") + user.phone}</span>
                        </div>
                        <div>Last login: {user.lastLogin && moment(user.lastLogin).format("DD/MM/YY HH:mm")}</div>
                    </div>
                </div>
                {user.archived ? <button className={"btn btn-danger btn-true-sm mb-1"} style={{width: "50%"}} onClick={handleUnarchiveUser}>Unarchive</button> : <button className={"btn btn-warning btn-true-sm mb-1"} style={{width: "50%"}} onClick={handleArchiveUser}>Archive</button>}
                <div className={"TenantCard-preferences"}>
                    <label className={"mb-1"}>Preferences</label>
                    <div className={"mt-1"}><i className={"mdi mdi-calendar mr-1"} /> <strong className={"mr-1"}>Date of check-in: </strong>{user.moveIn ? moment(user.moveIn).format("DD/MM/YYYY") : "ND"}</div>
                    <div><i className={"mdi mdi-calendar mr-1"} /><strong className={"mr-1"}>Date of check-out: </strong>{user.moveOut ? moment(user.moveOut).format("DD/MM/YYYY") : "ND"}</div>
                    {user.accommodation && user.numRooms && user.numTenants && <div>
                        <span className={"mr-1"}>Searching for: </span>
                        <span>{user.accommodation !== "rooms" ? t("property_resume.accommodation_type."+user.accommodation) : ""} {user.accommodation === "apartment" && " com "} {user.accommodation !== "residence" ? (user.numRooms === 1 ? "1 quarto" : user.numRooms + " quartos") : ""} for {user.numTenants === 1 ? "1 person" : user.numTenants + " people"}</span>
                    </div>}
                    <div>
                        <span>Nearby: {preferredLocation}</span>
                    </div>
                    <div>
                        <span className={"mr-1"}>Monthly rent: </span>
                        <span>{formatCurrency([user.budgetMin, user.budgetMax])}</span>
                    </div>
                </div>
                <div className={"TenantCard-stats mb-1"}>
                    <div>
                        <div className={"stats-logo"}><i className={"mdi mdi-clipboard-text reservations"} /> </div>
                        <div className={"section-title"}>Reservation Requests</div>
                        <div className={"number blue"}>{user.stats?.reservations}</div>
                    </div>
                    <div>
                        <div className={"section-subtitle"}>Accepted and unpaid</div>
                        <div className={"number green"}>{user.stats?.acceptedReservations}</div>
                    </div>
                </div>
                <div className={"TenantCard-stats mb-1"}>
                    <div>
                        <div className={"stats-logo"}><i className={"mdi mdi-map-marker-radius visits"} /> </div>
                        <div className={"section-title"}>Visit Requests</div>
                        <div className={"number blue"}>{user.stats?.visits}</div>
                    </div>
                    <div>
                        <div className={"section-subtitle"}>Scheduled</div>
                        <div className={"number green"}>{user.stats?.selectedVisits}</div>
                    </div>
                </div>
                <div className={"TenantCard-stats mb-2"}>
                    <div>
                        <div className={"stats-logo"}><i className={"mdi mdi-radar offers"} /> </div>
                        <div className={"section-title"}>Received offers</div>
                        <div className={"number blue"}>{user.stats?.offers}</div>
                    </div>
                    <div>
                        <div className={"section-subtitle"}>Active</div>
                        <div className={"number green"}>{user.stats?.activeOffers}</div>
                    </div>
                </div>
                <div className={"TenantCard-bottom"}>
                    <span><strong className={"mr-1"}>Registration date:</strong>{moment(user.registrationDate).format("DD/MM/YYYY")}</span>
                    <Link to={`/tenants/${user.id}`} style={{ color: 'inherit', textDecoration: 'inherit'}}><div className={"btn btn-primary btn-true-sm"}>Manage</div></Link>
                </div>
            </div>
        </div>
    )
}

export default TenantCard;
