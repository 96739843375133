import React from 'react';
import jsonDiff from 'json-diff';

const FieldsInfo = ({changedFields, originalProperty}) => {


    const handleFieldInfo = (field) => {
        if (field === 'id') return;
        if(field === 'photos'){
            return (
                <li key={field}>
                    <span className={"text-capitalize text-dark"}>Altered photographs</span>
                    </li>
            )
        }
        if (typeof changedFields[field] !== 'object') {
            return (
                <li key={field}>
                    <span className={"text-capitalize text-dark"}>{field}</span>
                    : {typeof changedFields[field] === 'boolean' ? (changedFields[field] ? 'true' : 'false') : changedFields[field]}</li>
            )
        } else {
            return (
                <li key={field} className={"d-flex flex-column"}>
                    <span className={"text-capitalize text-dark"}>{field}</span>
                    : {JSON.stringify(jsonDiff.diff(originalProperty[field], changedFields[field]))}
                    {field === "rooms" && jsonDiff.diff(originalProperty["rooms"], changedFields["rooms"]) && Object.values(jsonDiff.diff(originalProperty["rooms"], changedFields["rooms"])).some(r => r.notes) && <span className={"text-danger"}>
                        Please make sure to insert in all languages
                    </span>}
                </li>
            )
        }
    }


    return (
        <div>
            <ul className={"list-arrow"}>
                {Object.keys(changedFields).map(field => handleFieldInfo(field))}
            </ul>
            <small className={"mt-4"}>It will not be possible to revert the changes made</small>
        </div>
    )
}
export default FieldsInfo;