import {Form, FormGroup} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {get} from "../../services";
import Select from "react-select";
import GoogleMapReact from 'google-map-react';
import mapStyle from "../components/mapStyle.json";
import {propertyService} from "../../services/propertyService";


const AddressSection = ({property, handleChange}) => {

    const {address, floor, postal, neighborhood, coordinates} = property;

    let [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);


    useEffect(() => {
        // get the country options to pass to the ReactSelectMulti
        (async () => {
            const getNeighborhoodsOptions = (await get('/data/neighborhoods'))[property.region];
            if (!getNeighborhoodsOptions || getNeighborhoodsOptions.length === 0) setNeighborhoodsOptions([]);
            else
                setNeighborhoodsOptions((getNeighborhoodsOptions.map(neighborhood => {
                    if (neighborhood === 'Other') return {label: 'Outro', value: neighborhood}
                    return {label: neighborhood, value: neighborhood}
                })));
        })();
    }, [property.region]);


    // const validateLatLng = (coordinates) => {
    //     let coords = coordinates.split(',');
    //     let lat = coords [0];
    //     let long = coords [1];
    //     let pattern = new RegExp('^-?([ 1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    //     if(pattern.test(lat) && pattern.test(long)){
    //         setError(null);
    //         handleChange('coordinates', coordinates);
    //     }else{
    //         setError("Coordenadas inválidas");
    //     }
    // }

    const fetchCoords = async (address) => {
        if (!address) address = property.address;
        if (!address) return;
        let coords = await propertyService.addressCoords(address, property.region);
        if (coords?.lat && coords?.lng) {
            setCenter(coords);
            setZoom(16);
        }
    };

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (address && addressChanged) {
            setAddressChanged(false);
            fetchCoords(address);
        }
    }, [address])


    let _defaultCenter = coordinates ?
        {
            lat: parseFloat(coordinates?.split(',')[0]),
            lng: parseFloat(coordinates?.split(',')[1])
        } : null

    const mapContainerRef = useRef(null);
    const zoomTimeoutRef = useRef(Date.now());

    const [addressChanged, setAddressChanged] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState(_defaultCenter);
    const [center, setCenter] = useState(_defaultCenter);
    const [zoom, setZoom] = useState(coordinates ? 16 : 11);


    const handleMapZoom = (e) => {
        e.preventDefault();
        if (zoomTimeoutRef.current + 50 > Date.now()) return;
        let _scrollDelta = e.deltaY;
        setZoom(z => {
            let nextZoom = _scrollDelta > 0 ? z - 1 : z + 1;
            if (nextZoom < 10) nextZoom = 10;
            if (nextZoom > 19) nextZoom = 19;
            return nextZoom;
        });
        zoomTimeoutRef.current = Date.now();
    };


    useEffect(() => {
        if (mapContainerRef.current) {
            mapContainerRef.current.addEventListener('wheel', handleMapZoom);
            return () => {
                if (mapContainerRef.current) {
                    mapContainerRef.current.removeEventListener('wheel', handleMapZoom);
                }
            }
        }
    }, [mapContainerRef.current]);


    const handleMapChange = ({center}) => {
        if (firstUpdate.current && coordinates) {
            firstUpdate.current = false;
            return;
        }
        let newCoordinates = center.lat + "," + center.lng;
        if(!coordinates || ((center.lat.toFixed(4) !== parseFloat(coordinates?.split(",")[0]).toFixed(4)) || (center.lng.toFixed(4) !== parseFloat(coordinates.split(",")[1]).toFixed(4)))){
            handleChange('coordinates', newCoordinates);
        }
        setCenter(center);
    };


    return (
        <div className={"PropertyEdit_column"} onChange={(e) =>{
            if(e.target.name === 'address') return;
            handleChange(e.target.name, e.target.value)
        }}>
            <label className={"PropertyEdit_column-label"}>Address</label>
            <FormGroup>
                <label className={"PropertyEdit_input-label"}>Full address and house number</label>
                <input type="text" className="form-control" name="address" id="address" defaultValue={address}
                              placeholder="Property address" onBlur={(e)=>{
                                  handleChange('address', e.target.value);
                                  setAddressChanged(true);
                }}/>
            </FormGroup>
            <div className={"PropertyEdit_row-inputs"}>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Floor</label>
                    <Form.Control type="text" className="form-control" name="floor" id="floor" defaultValue={floor}
                                  placeholder={'1º'}/>
                </FormGroup>
                <FormGroup>
                    <label className={"PropertyEdit_input-label"}>Postal Code</label>
                    <Form.Control type="text" className="form-control" name="postal" id="postal" defaultValue={postal}
                                  placeholder={'1100-234'}/>
                </FormGroup>
            </div>
            <div className={"mb-3"}>
                <label className={"PropertyEdit_input-label"}>Area where the property is located</label>
                <Select
                    placeholder={"Zone"}
                    name={'neighborhood'}
                    options={neighborhoodsOptions}
                    defaultValue={{label: neighborhood, value: true}}
                    onChange={(option) => {
                        handleChange('neighborhood', option.value)
                    }}
                />
            </div>
            <div className={'map_header'}>
                <label className={"PropertyEdit_input-label"} htmlFor={'map_preview'}><strong>Exact location</strong></label>
                {(property.address) && coordinates && <div>
                    <label><strong>N</strong> {parseFloat(coordinates?.split(',')[0]).toFixed(6)}</label>
                    <label><strong>W</strong> {parseFloat(coordinates?.split(',')[1]).toFixed(6)}</label>
                </div>}
            </div>
            <div className={'map_preview'}
                 style={{width: '100%', height: '300px'}}>
                <div className={"Map"} ref={mapContainerRef}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                        zoom={zoom}
                        onChange={handleMapChange}
                        options={{
                            styles: mapStyle,
                            fullscreenControl: false,
                            zoomControl: false,
                            scrollwheel: false,
                            disableDoubleClickZoom: true,
                            clickableIcons: false
                        }}
                        defaultCenter={defaultCenter}
                        center={center}/>
                    {(property.address) && <div className={"marker"}/>}
                </div>
            </div>
        </div>
    )
}

export default AddressSection;