import React, {useEffect, useRef, useState} from 'react'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {reservationService} from '../../services/reservationService';
import {formatCurrency, getReservationStatus, getStay, toCapitalize} from '../shared/utils';
import DataCardTable from "../components/DataCardTable";
import cogoToast from "cogo-toast";
import PageWrapper from "../components/PageWrapper";
import ReservationCard from "../components/ReservationCard";
import {Link} from "react-router-dom";
import {useDialogContext} from "../App";
import Select from "react-select";
import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import DatePicker from "react-datepicker";


const img = {
    display: 'block',
    width: 'auto',
    height: '110px'
};

const getContractDates= (reservation) => {
    let incompleteRentType= reservation.payment?.rentType || reservation.property?.incompleteRentType;
    let momentMoveIn = moment(reservation.moveIn);
    let momentMoveOut = moment(reservation.moveOut);
    let contractStartDate = momentMoveIn.clone();
    let contractEndDate = momentMoveOut.clone();
    if(incompleteRentType === "monthly") {
        contractStartDate.startOf("month");
        contractEndDate.endOf("month");
    } else if (incompleteRentType === "biweekly") {
        if(contractStartDate.date() < 15) {
            contractStartDate.startOf("month");
        } else {
            contractStartDate.date(15);
        }
        if(contractEndDate.date() > 15) {
            contractEndDate.endOf("month");
        } else {
            contractEndDate.date(15);
        }
    }
    return {contractStartDate, contractEndDate};
}

const Reservations = ({inline, overrideFetch, cardTableStyles, customDataTable}) => {

    const {t} = useTranslation();
    const {setModal} = useDialogContext();

    const reservationChangesHandler = async (fields, reservation, fieldName) => {
        setModal({
            title: "change reservation",
            size: "md",
            message: <>
                <div className={"d-flex flex-column"}>
                    <span>Are you sure you want to <strong>change</strong> {(reservation.user_info?.name || reservation.user.name)}'s reservation?</span>
                    <span>{`Old ${fieldName}: ${moment(reservation[fieldName]).format('DD/MM/yyyy')}. New ${fieldName}: ${moment(fields[fieldName]).format('DD/MM/yyyy')}`}</span>
                </div>
            </>,
            onSubmit:
                async () => {
                    try {
                        await reservationService.editReservation(reservation.id, fields);
                        reservation[fieldName] = fields[fieldName];
                        cogoToast.success("Reservation changed");

                    } catch (e) {
                        cogoToast.error("Error editing reservation");
                    }
                    setModal(m => ({...m, hide: true}));
                }
        });
    };

    const conditionalRowStyles = [
        {
            when: row => (row.currentStatus === "expired"),
            style: {
                backgroundColor: '#e8e8e8',
            },
        },
        {
            when: row => (row.currentStatus === "paid"),
            style: {
                backgroundColor: '#c2fabd',
            },
        },
        {
            when: row => row.currentStatus === "payment",
            style: {
                backgroundColor: '#f2f9ff',
            },
        },
        {
            when: row => row.currentStatus === "rejected" || row.currentStatus === "cancelled",
            style: {
                backgroundColor: '#f5ccce',
            },
        }
    ];

    const dataColumns = [
        {
            name: 'ID',
            width: "100px",
            selector: row=>row.id,
            cell: row => <div data-tag="allowRowEvents">{"#"+row.id?.split("-")[0]}</div>
        },
        {
            name: 'Client',
            width: "120px",
            selector: row=>row.user_info ? row.user_info.name : row.user?.name
        },
        {
            name: 'Client ID',
            width: "120px",
            selector: row=>row.user?.id?.split("-")[0]
        },
        {
            name: 'Contact',
            minWidth: "160px",
            maxWidth: "200px",
            selector: row=>row.user?.email,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <span>{row.user_info ? ((row.user_info.dialCode ? row.user_info.dialCode + " " : "") + row.user_info.phone) : ((row.user?.dialCode ? row.user?.dialCode + " " : "") + row.user?.phone)}</span>
                <small>{row.user?.email}</small>
            </div>)
        },
        {
            name: 'Nationality',
            maxWidth: "110px",
            selector: row=> {
                let nationality = row.user_info ? row.user_info.nationality : row.user?.nationality;
                return nationality ? t("country." + nationality) : '-'
            }
        },
        {
            name: 'Landlord',
            minWidth: "110px",
            maxWidth: "120px",
            selector: row=>row.landlord,
            cell: row => <div data-tag="allowRowEvents"><Link to={`/landlords/${row.landlord?.id}`}>{row.landlord?.name}</Link></div>
        },
        {
            name: 'Landlord ID',
            width: "120px",
            selector: row=>row.landlord?.id?.split("-")[0]
        },
        {
            name: 'Region',
            maxWidth: "100px",
            selector: row=>row.property?.region
        },
        {
            name: 'Ad',
            minWidth: "200px",
            maxWidth: "240px",
            selector: row => row.property,
            cell: row => (
                <Link to={`/property/${row.property?.id}${row.room_id ? "?room_id="+row.room_id : ""}`}>
                    <div data-tag="allowRowEvents" className={"property-cell"}>
                        <span>{row.property?.internal_name}</span>
                        <small>{row.property?.address}</small>
                    </div>
                </Link>)
        },
        {
            name: 'Listing ID',
            width: "120px",
            selector: row=>row.property?.id?.split("-")[0]
        },
        {
            name: 'Move-In',
            center: true,
            maxWidth: "110px",
            selector: row => row.moveIn,
            format: row =>
                <DatePicker
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPosition={'fixed'}
                    selected={new Date(row.moveIn)}
                    value={new Date(row.moveIn)}
                    onChange={(date) => {
                        reservationChangesHandler({'moveIn': date.toISOString()}, row, "moveIn");
                    }}
                    dateFormat={'dd/MM/yyyy'}
                    className="form-control reservationDate"
                />
        },
        {
            name: 'Move-Out',
            center: true,
            maxWidth: "110px",
            selector: row => row.moveOut,
            format: row =>
                <DatePicker
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPosition={'fixed'}
                    selected={new Date(row.moveOut)}
                    onChange={(date) => {
                        reservationChangesHandler({'moveOut': date.toISOString()}, row, "moveOut");
                    }}
                    dateFormat={'dd/MM/yyyy'}
                    className="form-control reservationDate"
                />
        },
        {
            name: 'Nº Tenants',
            maxWidth: "110px",
            center: true,
            selector: row => row.numTenants
        },
        {
            name: 'Contract',
            maxWidth: "120px",
            selector: row => row.payment,
            cell: row =><OverlayTrigger overlay={<Tooltip>Contract dates:<br/> Move-In: {getContractDates(row).contractStartDate.format("DD-MM-YYYY")}<br/>Move-Out: {getContractDates(row).contractEndDate.format("DD-MM-YYYY")}</Tooltip>}>
                <span>{{"monthly":"Monthly","biweekly":"Biweekly","daily":"Daily"}[row.payment?.rentType||row.property?.incompleteRentType]}</span>
            </OverlayTrigger>
        },
        {
            name: 'Payment date',
            minWidth: "140px",
            maxWidth: "140px",
            center: true,
            selector: row => row.paymentDate,
            cell: row => <span>{row.paymentDate ? moment(row.paymentDate).format("DD-MM-YYYY HH:mm") : "-"}</span>
        },
        {
            name: 'Monthly rent',
            width: "160px",
            right: true,
            selector: row => row.payment,
            format: row => reservationService.calcRent(row)
        },
        {
            name: 'Stay',
            maxWidth: "120px",
            selector: row => row.payment,
            format: row => getStay(row.moveIn, row.moveOut, row.payment?.rentType||row.property?.incompleteRentType)
        },
        {
            name: 'Contract value',
            right: true,
            width: "130px",
            selector: row => row.totalContractValue,
            cell: row => formatCurrency(row.totalContractValue)
        },
        {
            name: 'Commission',
            right: true,
            maxWidth: "100px",
            selector: row => row.commission,
            cell: row =>
                <div>
                    <OverlayTrigger overlay={
                        <Tooltip>{row.payment?.firstRentCommission ? "First Rent Value: " + formatCurrency(row.payment?.firstRent) : "Contract Value: " + formatCurrency(row.totalContractValue)}<br/>Commission: {row.payment?.landlordCommission}%
                            ({row.inlifeCommissionVat ? row.inlifeCommissionVat : formatCurrency((row.payment?.landlordCommission / 100) * (row.payment?.firstRentCommission ? row.payment?.firstRent : row.totalContractValue))})</Tooltip>}>
                        <span>{row.inlifeCommissionVat ? row.inlifeCommissionVat : formatCurrency((row.payment?.landlordCommission / 100) * (row.payment?.firstRentCommission ? row.payment?.firstRent : row.totalContractValue))}</span>
                    </OverlayTrigger>
                    {row.penalty && <OverlayTrigger
                        overlay={<Tooltip>An extra fee of 2% is charged for canceling {row?.penalty?.tenantName}'s
                            reservation</Tooltip>}>
                        <i className="mdi mdi-information-outline">&nbsp;</i>
                    </OverlayTrigger>}
                </div>
        },
        {
            name: 'Booking Fee',
            right: true,
            maxWidth: "100px",
            selector: row => row.payment?.bookingFee,
            cell: row => <span>{row.payment?.bookingFee ? formatCurrency(row.payment.bookingFee) : "-"}</span>
        },
        {
            name: 'Promo Code',
            center: true,
            selector: row => row.promo?.code,
            cell: row => <span>{row.promo?.code || "-"}</span>
        },
        {
            name: 'Affiliate',
            center: true,
            selector: row => row.affiliate,
            cell: row => <span>{row.affiliate || "-"}</span>
        },
        {
            name: 'Total paid',
            right: true,
            maxWidth: "100px",
            selector: row => row.payment,
            cell: row => row.payment?.bookingFee && row.payment?.firstRent && <span className="text-capitalize">{formatCurrency(row.payment?.bookingFee + row.payment.firstRent + row.payment.extraTenantsValue)}</span>
        },
        {
            name: 'Form of payment',
            maxWidth: "150px",
            selector: row => row.payment?.method,
            cell: row =>row.payment?.method ? <span className={"clickable"} onClick={seePaymentDetails(row)}>{toCapitalize(row.payment.method)}</span> : <span>-</span>
        },
        {
            name: 'Request Date',
            minWidth: "120px",
            maxWidth: "120px",
            center: true,
            selector: row => row.requestDate,
            cell: row => <OverlayTrigger overlay={<Tooltip>Hora: {moment(row.requestDate).format("HH:mm")}</Tooltip>}>
                <span>{moment(row.requestDate).format("DD-MM-YYYY")}</span>
            </OverlayTrigger>
        },
        {
            name: 'Type',
            maxWidth: "90px",
            selector: row => row.type,
            format: row => row.type === "booking" ? <span className="badge badge-request">Request</span> :
                <span className="badge badge-instant">Instant</span>
        },
        {
            name: 'Status',
            width: "200px",
            cell: row => <div className={"text-capitalize"}>{getReservationStatus(row)} </div>
        },
        {
            name: 'Extra Info',
            maxWidth: "240px",
            overflow: "scroll",
            selector: row=> row.user?.extraInfo,
            cell: row => (<div data-tag="allowRowEvents" className={"property-cell"}>
                <small className={"extra-info"}>{row.user?.extraInfo}</small>
            </div>)
        },
        {
            name: 'Actions',
            width: "100px",
            button: true,
            cell: row =><div className={"actions-container"}>{
                (row.currentStatus === "request") ? (<>
                    <OverlayTrigger overlay={<Tooltip>Accept</Tooltip>}>
                        <i onClick={acceptReservation(row)}
                           className={"mdi mdi-checkbox-marked-outline action-accept"}/>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                        <i onClick={rejectReservation(row)}
                           className={"mdi mdi-close-box-multiple-outline action-reject"}/>
                    </OverlayTrigger>
                </>) : (row.currentStatus === "payment" ?
                    <><OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                        <i onClick={rejectReservation(row)}
                           className={"mdi mdi-close-box-multiple-outline action-reject"}/>
                    </OverlayTrigger></>
                    : "")}
                {!row.tenantInfoVerified &&
                    <OverlayTrigger overlay={<Tooltip>Check Tenant Information</Tooltip>}>
                        <i onClick={verifyReservation(row)}
                           className={"mdi mdi-comment-check-outline action-verify"}/>
                    </OverlayTrigger>
                }
                {(row.currentStatus === "request_cancellation" || (row.cancellation?.answerDate && row.currentStatus !== "cancelled" )) &&
                    <OverlayTrigger overlay={<Tooltip>{row.cancellation?.answerDate ? "View Request Cancellation" : "Reply Request"}</Tooltip>}>
                        <i  className={"mdi mdi-file-document-box action-review"}
                             onClick={() => checkCancellationRequest(row)}/>
                    </OverlayTrigger>
                }
            </div>
        },
        {
            name: 'Notes',
            minWidth: "140px",
            selector: row => row.notes,
            cell: row => <textarea onChange={updateNote(row, "comment")} rows="2" defaultValue={row?.notes?.comment}/>
        }
    ]

    const debounceHandler = useRef(null);

    const seePaymentDetails = (reservation) => async () => {
        if(reservation.payment.method === "stripe") {
            window.open("https://dashboard.stripe.com/payments/" + reservation.payment.auth, "_blank");
        } else if (reservation.payment.method === "paypal") {
            let sel_id;
            if(reservation.payment.status === "auth") {
                sel_id = reservation.payment.auth;
            } else if (reservation.payment.status === "paid") {
                try {
                    let paymentInfo = await reservationService.getPaypalPaymentInfo(reservation.payment.id);
                    sel_id = paymentInfo?.transactions?.[0]?.related_resources?.[0]?.sale?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[1]?.capture?.id ||
                        paymentInfo?.transactions?.[0]?.related_resources?.[0]?.authorization?.id;

                } catch (e) {
                    return window.open(process.env.REACT_APP_PAYPAL_URL + "/activity/payment/" + (reservation.payment.auth||reservation.payment.id), "_blank");
                }
            }
            return window.open(process.env.REACT_APP_PAYPAL_URL + "/activity/payment/" + sel_id, "_blank");
        }
    }

    const acceptReservation = (reservation) => async () => {
        setModal({
            title: "Accept reservation",
            size:"sm",
            message: <>Are you sure you want <strong>accept</strong> {(reservation.user_info?.name || reservation.user.name)}'s reservation?</>,
            onSubmit: async () => {
                await reservationService.accept(reservation);
                reservation.acceptedDate = Date.now();
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Booking successfully accepted.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const rejectReservation = (reservation) => async () => {
        setModal({
            title: "Reject reservation",
            size:"sm",
            message: <>Are you sure you want <strong>reject</strong> {(reservation.user_info?.name || reservation.user.name)}'s reservation?</>,
            onSubmit: async () => {
                await reservationService.reject(reservation);
                reservation.rejectedDate = Date.now();
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Reservation rejected successfully.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const Dropzone = ({setDropFiles}) => {
        const [files, setFiles] = useState([]);

        useEffect(()=>{
            setDropFiles(files);
        },[files]);

        const {
            getRootProps,
            getInputProps,
            isDragActive,
            open
        } = useDropzone({
            accept: 'image/*,, application/pdf',
            maxSize: 10485760,
            maxFiles: 10,
            noClick: true,
            noKeyboard: true,
            onDrop: (acceptedFiles) => {
                if((acceptedFiles.length + files.length) > 10 ) {
                    return;
                }
                setFiles(prev => ([...prev, ...acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))]));
            }
        });

        return (
            <div>
                <div {...getRootProps({className: 'dropzone'})} style={{minHeight: '200px', padding: '15px', display: 'flex', flexDirection: 'column', border: (isDragActive ? "2px solid #18A0FB" : "2px dashed #CCDCE6")}}>
                    <input {...getInputProps()} />
                    {files.length ?
                        <div className="list-ticked d-flex" style={{maxWidth: "146px"}}>
                            {files.map(file => (
                                <div key={file.path} className={"d-flex mr-1"} style={{flexDirection: "column"}}>
                                    <img src={file.preview} style={img} alt={"image-preview"} />
                                    <p style={{maxWidth: "30px"}}>{file.path}</p>
                                    <p style={{maxWidth: "30px"}}>{file.size} bytes</p>
                                </div>
                            ))}
                        </div> :
                        <p>Drag 'n' drop some files here</p>
                    }
                    <button type="button" onClick={open} className="btn btn-primary mt-auto">Choose document
                    </button>
                </div>
            </div>
        );
    }

    const cancellationMotives = {
        "property_modifications": "Substantial modifications to the ad description",
        "property_health_risks": "Health risks arising from the condition of the property",
        "uninhabitable": "Non-habitable conditions",
        "inaccessible": "Inaccessible accommodation",
        "other": "Other extremely serious situations",
        "unable_travel": "Prevented from traveling",
        "not_needed": "Doesn't need this reservation anymore",
        "without_motive": "No specific reason",
        "extended_stay": "Current tenant extended stay",
        "overlapped_reservations": "Overlapping reserves",
        "property_unavailable": "Home is no longer available"
    }

    const checkCancellationRequest = (reservation) => {
        let cancellation = reservation.cancellation;
        let type = cancellation.type?.split("_")[0];
        let answerOptions = [{value: "accepted_refunded", label: "Accept and Refund"},
            {value: "accepted_not_refunded", label: "Accept"},
            {value: "not_accepted", label: "Reject"}]
        let answer = "";
        let hasAlreadyAnswer = cancellation.answerDate;

        let cancellationFiles = [];
        const setCancellationFiles = (files) => {
            cancellationFiles = files;
        }

        setModal({
            title: "Check Cancellation Request",
            size:"lg",
            message: <div className={"d-flex flex-column"}>
                <div className={"d-flex"}><span><strong className={"mr-2"}>Requested by:</strong>{type === "tenant" ? "Tenants" : "Landlord"}</span></div>
                <div className={"d-flex"}><span><strong className={"mr-2"}>Date:</strong>{moment(cancellation.requestDate).format("DD/MM/YYYY")}</span></div>
                <div className={"d-flex"}><span><strong className={"mr-2"}>Reason:</strong>{cancellationMotives[cancellation.motive]}</span></div>
                <div className={"d-flex"}><span><strong className={"mr-2"}>Description:</strong>{cancellation.description}</span></div>
                {cancellation.documents &&
                    <div className={"d-flex flex-column mb-2"}>
                        <span className={"mb-2"}><strong>Documents:</strong></span>
                        <ul>
                        {Object.keys(cancellation.documents).map((key, index) => {
                            if(key !== "answer") return <li key={"doc-"+index} className={"clickable mb-1"} onClick={()=> window.open(cancellation.documents[key], "_blank")}>View file {index+1}</li>
                        })}
                        </ul>
                    </div>
                }
                <div className={"mt-3 mb-2"}><u>Response</u></div>
                {!hasAlreadyAnswer &&<label htmlFor={"answerDescription"}>Descriptive:</label>}
                {!hasAlreadyAnswer &&<textarea className="form-control mb-2" rows="3" id={"answerDescription"}/>}
                {hasAlreadyAnswer && <div className={"d-flex"}>
                    <span className={"mr-1"}>Descriptive:</span>
                    <span>{cancellation.answerDescription}</span>
                </div>}
                {hasAlreadyAnswer ?
                    <div className={"d-flex flex-column mb-2"}>
                        <span className={"mb-2"}>Documents: </span>
                        <ul>
                        {cancellation.documents?.answer && Object.values(cancellation.documents.answer).map((doc, index) => {
                        return <li className={"clickable mb-1"} onClick={()=> window.open(doc, "_blank")}>View file {index+1}</li>
                    })}
                        </ul>
                    </div>
                    :
                    <Dropzone setDropFiles={setCancellationFiles}/>
                }
                {!hasAlreadyAnswer && <label htmlFor={"answerDescription"}>Final answer:</label>}
                {!hasAlreadyAnswer &&
                <Select options={answerOptions}
                        id={"answer"}
                        className={"mb-5"}
                        placeholder={"Choose an answer"}
                        menuPlacement={'top'}
                        onChange={(v)=>{answer = v.value}}
                />}
                {hasAlreadyAnswer && <div className={"d-flex"}>
                    <span className={"mr-1"}>Final decision:</span>
                    <span><strong>{cancellation[cancellation.answer]}</strong></span>
                </div>}

            </div>,
            onSubmit: async () => {
                if(hasAlreadyAnswer){
                    setModal(m=>({...m, hide: true}));
                    return;
                }
                let docs = cancellationFiles
                let answerDescription = document.getElementById("answerDescription").value;
                let formData = new FormData();
                let file;
                if(docs.length > 0){
                    for (let i = 0; i < docs.length; i++) {
                        type = docs[i]['type'].split('/')[1];
                        file = docs[i]
                        if (docs[i]) {
                            formData.append('documents', docs[i], file.name);
                        }
                    }
                }
                formData.append('reservation_id', reservation.id);
                formData.append('answerDescription', answerDescription);
                formData.append('answer', answer);
                await reservationService.cancellationReply(formData);
                reservation.cancellation = {
                    ...reservation.cancellation,
                    answerDate: Date.now(),
                    answer: answer,
                    answerDescription: answerDescription
                }
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Request verified successfully.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };


    const verifyReservation = (reservation) => async () => {
        setModal({
            title: "Verify reservation",
            size:"sm",
            message: <div className={"d-flex flex-column"}><strong>About the tenant:</strong> <span>{reservation.user.extraInfo}</span></div>,
            onSubmit: async () => {
                await reservationService.verifyReservation(reservation);
                reservation.tenantInfoVerified = true;
                setModal(m=>({...m, hide: true}));
                cogoToast.success('Reservation verified successfully.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    };

    const updateNote = (reservation, note_type) => async (e) => {
        let note = e.target.value;
        clearTimeout(debounceHandler.current);
        debounceHandler.current = setTimeout(async () => {
            await reservationService.updateNote(reservation, note_type, note);
            reservation.notes = reservation.notes || [];
            reservation.notes[note_type] = note;
        }, 1000);
    }

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("reservations_filters")) || {sort: "desc"});


    return (
        <PageWrapper breadcrumbs={[{title: "Reservations"}]}
                     title={"Reservations"}
                     inline={inline}
                     setFilters={setFilters}
                     filters={filters}>
            <DataCardTable
                cardRendererComponent={ReservationCard}
                customClass={cardTableStyles}
                customHeaderClass={customDataTable}
                cardRendererProps={{
                    rejectReservation, acceptReservation, verifyReservation, checkCancellationRequest, seePaymentDetails
                }}
                fixedFirstColumn
                columns={dataColumns}
                dataType={"reservations"}
                fetchData={overrideFetch || reservationService.list}
                conditionalRowStyles={conditionalRowStyles}
                filters={filters}
            />
        </PageWrapper>
    )
}



export default Reservations;
