import React, {useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useAuth} from "../../services/authService";

import avatar from "../../assets/images/avatar.png";

const Navbar = (props) => {

    const {user, logout} = useAuth();
    let userPhoto = user.photo || avatar;

    const handleLogout = async (e) => {
        e.preventDefault();
        await logout();
    }

    const toggleOffcanvas = () => {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }

    useEffect(() => {
        if(localStorage.getItem('sidebarClosed') === "true") {
            document.body.classList.toggle('sidebar-icon-only');
        }
    }, []);

    const toggleSidebar = () => {
        document.body.classList.toggle('sidebar-icon-only');
        localStorage.setItem('sidebarClosed', ""+(localStorage.getItem('sidebarClosed') !== "true"));
    }

    return (
        <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#"
                   onClick={evt => evt.preventDefault()}><img src={require("../../assets/images/logo-dark.svg")}
                                                              alt="logo"/></a>
                <button className="navbar-toggler navbar-toggler align-self-center" type="button"
                        onClick={toggleSidebar}>
                    <i className="mdi mdi-menu"></i>
                </button>
                <form className="ml-auto search-form d-none d-md-block" action="#">
                    <div className="form-group">
                        <input type="search" className="form-control" placeholder="Search Here"/>
                    </div>
                </form>
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item  nav-profile border-0 pl-4">
                        <Dropdown>
                            <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                                <i className="mdi mdi-bell-outline"/>
                                <span className="count bg-success">4</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown preview-list">
                                <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#"
                                               onClick={evt => evt.preventDefault()}>
                                    <p className="mb-0 font-weight-medium float-left">There are 4 new notifications</p>
                                </Dropdown.Item>
                                <div className="dropdown-divider"/>
                                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center"
                                               href="!#" onClick={evt => evt.preventDefault()}>
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-alert m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content py-2">
                                        <h6 className="preview-subject font-weight-normal text-dark mb-1">Error</h6>
                                        <p className="font-weight-light small-text mb-0">
                                            Right now</p>
                                    </div>
                                </Dropdown.Item>
                                <div className="dropdown-divider"/>
                                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center"
                                               href="!#" onClick={evt => evt.preventDefault()}>
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-settings m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content py-2">
                                        <h6 className="preview-subject font-weight-normal text-dark mb-1">New listing inserted</h6>
                                        <p className="font-weight-light small-text mb-0">20 minutes ago</p>
                                    </div>
                                </Dropdown.Item>
                                <div className="dropdown-divider"/>
                                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center"
                                               href="!#" onClick={evt => evt.preventDefault()}>
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-airballoon m-auto text-primary"/>
                                    </div>
                                    <div className="preview-item-content py-2">
                                        <h6 className="preview-subject font-weight-normal text-dark mb-1">New Register user</h6>
                                        <p className="font-weight-light small-text mb-0">5 hours ago</p>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="nav-item  nav-profile border-0">
                        <Dropdown>
                            <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                                <img className="img-xs rounded-circle"
                                     src={userPhoto} alt="Profile"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                                <div className="d-flex flex-column align-items-center py-3 px-4">
                                    <label className={"text-dark"}>{user.username}</label>
                                    <span className={"text-muted"}>{user.email}</span>
                                </div>
                                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0"
                                               onClick={handleLogout}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        onClick={toggleOffcanvas}>
                    <span className="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    );
}

export default Navbar;
