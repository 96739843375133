import moment from "moment/moment";

const getExpirationDate = (date) => {
    let currentDate = date ? moment(date) : moment();
    let businessHours = 0;
    while (businessHours < 24) {
        if (currentDate.day() >= 1 && currentDate.day() <= 5 && currentDate.hour() >= 8 && currentDate.hour() < 20) {
            businessHours++;
        }
        currentDate.add(1, 'hour');
    }
    return currentDate.locale("en");
}

export const utils = {
    getExpirationDate
};
