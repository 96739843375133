import {get, post} from "./index.js";
import moment from "moment";
import {formatCurrency} from "../app/shared/utils";
import {utils} from "../utils";

const NAMESPACE = "/backoffice/reservation"

const list = async (paginationKey, searchKey, filters) => {
    return await get(NAMESPACE + "/list", {paginationKey, searchKey, filters})
}

const financeList = async (paginationKey, searchKey, filters) => {
    return await get("/backoffice/finance/list", {paginationKey, searchKey, filters})
}

const accept = async (reservation) => {
    return await post(NAMESPACE + "/accept", {
        landlord_id: reservation.landlord.id,
        reservation_id: reservation.id
    })
};

const reject = async (reservation) => {
    return await post(NAMESPACE + "/reject", {
        landlord_id: reservation.landlord.id,
        reservation_id: reservation.id
    })
};

const cancellationReply = async (form) => {
    return await post (NAMESPACE + "/answerCancellation", form)
};

const verifyReservation = async (reservation) => {
    return await post(NAMESPACE + "/verify", {reservation_id: reservation.id})
};

const updateNote = async (reservation, note_type, note) => {
    return await post(NAMESPACE + "/updateNote", {
        reservation_id: reservation.id,
        note_type, note
    })
};


const isExpired = (reservation) => {
    if(reservation.type=== "booking" && reservation.acceptedDate){
        return moment(reservation.acceptedDate).add(1, 'day').isBefore(moment());
    }
    return utils.getExpirationDate(reservation.requestDate).isBefore(moment());
};


const calcRent = (reservation) => {
    if(!reservation.payment) return "-"; //para não dar erro na última linha do "carregar mais"
    let {fixedRent, rentValue, rentMonths, discount, extraTenantsValue} = reservation.payment;
    if(!rentValue) rentValue = reservation.property?.room?.rent;
    if(typeof fixedRent === "undefined") fixedRent = reservation.property?.room?.fixedRent;
    if (fixedRent || !rentMonths) return formatCurrency((discount ? (rentValue - (rentValue * discount)) : rentValue) + (extraTenantsValue || 0));
    const _toMonth = (m) => moment(m).locale('en').format("MMMM").toLowerCase();
    let startMoveIn = moment(reservation.moveIn);
    let momentMoveOut = moment(reservation.moveOut);
    let min, max;
    while (startMoveIn.isBefore(momentMoveOut)) {
        let curMonthValue;
        if(rentMonths) curMonthValue = rentMonths[_toMonth(startMoveIn)];
        if (!min || curMonthValue < min) min = curMonthValue;
        if (!max || curMonthValue > max) max = curMonthValue;
        startMoveIn.add(1, "month");
    }
    min += (extraTenantsValue || 0);
    max += (extraTenantsValue || 0);
    if(discount) {
        min -= min * discount;
        max -= max * discount;
    }
    if (min === max) return formatCurrency(min);
    return formatCurrency(min) + " - " + formatCurrency(max);
}

const getPaypalPaymentInfo = async (payment_id) => {
    return await get("/payment/paypalInfo?paymentId=" + payment_id);
};

const overrideTransfer = async (reservationId, transferValue) => {
    return await post("/backoffice/finance/overrideTransfer", {reservationId, transferValue})
}

const updateTransferStatus = async (reservationId, transferStatus) => {
    return await post("/backoffice/finance/updateTransferStatus", {reservationId, transferStatus})
}

const cancelReservation = async (reservation_id, refundAmount, discountPercentage) => {
    return await post("/backoffice/reservation/cancel", {reservation_id, refundAmount, discountPercentage})
}

const editReservation = async (reservation_id, fields) => {
    return await post("/backoffice/reservation/editDetails", {reservation_id, fields})
}

export const reservationService = {
    list,
    financeList,
    accept,
    reject,
    cancellationReply,
    verifyReservation,
    updateNote,
    isExpired,
    calcRent,
    getPaypalPaymentInfo,
    overrideTransfer,
    updateTransferStatus,
    cancelReservation,
    editReservation
}
