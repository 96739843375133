import React, {useMemo} from 'react'
import {formatCurrency, getReservationStatus, getStay, toCapitalize} from "../shared/utils";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import defaultAvatar from "../../assets/images/avatar.png";
import {reservationService} from "../../services/reservationService";
import {authService} from "../../services/authService";
import {OverlayTrigger, Tooltip, Popover, Dropdown} from "react-bootstrap";
import {utils} from "../../utils";

const ReservationCard = ({
                             data: reservation,
                             acceptReservation,
                             rejectReservation,
                             verifyReservation,
                             checkCancellationRequest,
                             seePaymentDetails
                         }) => {
    const {t} = useTranslation();
    let property = reservation.property;
    let propertyPhoto = reservation.photo;
    let userPhoto = reservation.user.photo || defaultAvatar;
    let landlordPhoto = reservation.landlord.photo || defaultAvatar;
    let roomNumber = reservation.room_id && property?.rooms ? parseFloat(Object.keys(property.rooms)
        .find(rn => property.rooms[rn].id === reservation.room_id)) + 1: 1;

    const expirationDate = useMemo(() => {
        if (reservation.type === "booking" && reservation.acceptedDate) {
            return moment(reservation.acceptedDate).add(1, 'day').locale("en");
        }
        return utils.getExpirationDate(reservation.requestDate);
    }, [reservation]);

    let statusIcon = useMemo(()=>  {
        let currentStatus = reservation.currentStatus;
        if(currentStatus === "paid" && reservation.needsStudentAcceptance) currentStatus = "pending";
        return{
            "expired": "fa-calendar-times-o",
            "rejected": "fa-times",
            "cancelled": "fa-times",
            "payment": "fa-money",
            "paid": "fa-check-circle-o",
            "request": "fa-clipboard",
            "pending": "fa-clipboard"
        }[currentStatus];
    }, [reservation.currentStatus])


    const impersonateLandlord = (landlord) => async () => {
        let token = await authService.impersonate("landlord", landlord.id);
        window.open(process.env.REACT_APP_LANDLORD_URL + "/api/backoffice/impersonate?type=landlord&token=" + token, '_blank').focus();
    }

    return (
        <div className="Card card" style={{height: "170px"}}>
            <Link to={`/property/${property?.id}${reservation.room_id ? "?room_id=" + reservation.room_id : ""}`}
                  style={{color: 'inherit', textDecoration: 'inherit'}}>
                <div style={{backgroundImage: `url('${propertyPhoto}')`}} className={"Card_featured-photo"}>
                    <div className={`top-image ${reservation.type === "booking" ? "booking" : "instant"}`}>
                        {reservation.type === "booking" ?
                            <div><i className={"fa fa-arrow-circle-o-right mr-2"}/>Request</div>
                            : <div><i className={"fa fa-clock-o  mr-2"}/>Instant</div>}
                    </div>
                    <div className={"bottom-image bottom-image-left mb-1 ml-1"}>{reservation.room_id ? reservation.room_id.split("-")[0] : property?.id?.split("-")[0]}</div>
                    <div className={"bottom-image mb-1 ml-1"}>{reservation.id?.split("-")[0]}</div>
                </div>
            </Link>
            <div className="Card_content">
                <div className={"Card_property"}>
                    {/*{reservation.title &&*/}
                    {/*<label>*/}
                    {/*    <Link to={`/property/${property?.id}${reservation.room_id ? "?room_id="+reservation.room_id : ""}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>{clampText(reservation?.title[localStorage.getItem('i18nextLng').split('-')[0]], 50)}</Link>*/}
                    {/*    <OverlayTrigger overlay={<Tooltip>Pré-visualizar anúncio</Tooltip>}>*/}
                    {/*        <i className={"mdi mdi mdi-arrow-top-right clickable ml-2"} style={{ color: '#2196f3'}} onClick={()=> propertyService.previewProperty(property, roomName)}/>*/}
                    {/*    </OverlayTrigger>*/}
                    {/*</label>}*/}
                    <div>
                        <i className="mdi mdi-map-marker mr-2"/>{property.address}{property?.floor ? ", " + property.floor + " Floor" : ""}{(' | Room ' + roomNumber)} | {property.region}
                    </div>
                    <div><i className="mdi mdi-phone mr-2"/>{(reservation.landlord.dialCode ? reservation.landlord.dialCode + " " : "") + reservation.landlord.phone}</div>
                    <div><i className="mdi mdi-coin mr-2"/>Monthly Rent: {reservationService.calcRent(reservation)}
                    </div>
                    <div className={"Card_property-landlord"}>
                        <div style={{backgroundImage: 'url(' + landlordPhoto + ')'}}
                             className="rounded-circle landlord-photo mr-2"/>
                        <Link to={`/landlords/${reservation.landlord.id}`}>{reservation.landlord.name}</Link>
                    </div>
                </div>
                <div className={"Card_tenant"}>
                    <div className={"Card_tenant-image-wrapper"}>
                        <div style={{backgroundImage: `url(${userPhoto})`}} className={"Card_tenant-image"}/>
                    </div>
                    <OverlayTrigger
                        key={"left"}
                        placement={"left"}
                        overlay={
                            <Popover id="tooltip-info">
                                <Popover.Title as="h3">{t("extraInfo")}</Popover.Title>
                                <Popover.Content>
                                    {reservation?.user?.extraInfo || "Not defined"}
                                </Popover.Content>
                            </Popover>
                        }>
                        <div className={"Card_tenant-info"}>
                            <label>{reservation.user_info?.name || reservation.user.name} <Link to={"/tenants/" + reservation.user.id}>View Profile</Link></label>
                            <div>
                                {t("country." + (reservation.user_info?.nationality || reservation.user.nationality))} - {t("gender." + (reservation.user_info?.gender || reservation.user.gender))} - {(reservation.user.birthdate ? (new Date().getFullYear() - reservation.user.birthdate?.split("-")[1]) : "18")}
                            </div>
                            <div className={"mb-2"}>
                                {toCapitalize(reservation.user.occupation)} {(reservation.user.occupation === "student" && reservation.user.university) ? "at" + " " + reservation.user.university?.split(" - ")[0] : reservation.user.occupation_detail}
                            </div>
                            <div className={"mdi mdi-account"}>
                                <strong className={"ml-1"}>Nr Tenants:</strong> {(reservation.numTenants)}
                            </div>
                            <div className={"mdi mdi-calendar"}>
                                <strong className={"ml-1"}>Date of request:</strong> {moment(reservation.requestDate).format("DD-MM-YYYY")}
                            </div>
                            <div className={"mdi mdi-calendar"}>
                                <strong className={"ml-1"}>Date of check-in:</strong> {moment(reservation.moveIn).format("DD-MM-YYYY")}
                            </div>
                            <div className={"mdi mdi-calendar"}>
                                <strong className={"ml-1"}>Date of check-out:</strong> {moment(reservation.moveOut).format("DD-MM-YYYY")}
                            </div>
                        </div>
                    </OverlayTrigger>
                </div>
                <div className={"Card_info"}>
                    <div className={`info-status ${reservation.currentStatus === "paid" && reservation.needsStudentAcceptance ? "pending" : reservation.currentStatus}`}>
                        <div className={`fa ${statusIcon} fa-lg mr-2`}/>
                        <span>{getReservationStatus(reservation)}</span>
                    </div>
                    <div className={"reservation-info_box"}>
                        <strong>
                            Stay {getStay(reservation.moveIn, reservation.moveOut, reservation.payment?.rentType || reservation.property?.incompleteRentType)}.
                            Commission {reservation.payment?.landlordCommission}%
                        </strong>
                    </div>
                    {reservation.payment.method &&
                    <OverlayTrigger overlay={<Tooltip>View payment details</Tooltip>}>
                        <div className={"mt-2 clickable"} style={{width: "fit-content"}} onClick={seePaymentDetails(reservation)}>Type of payment: {reservation.payment.method}</div>
                    </OverlayTrigger>}
                    {reservation.promo &&
                    <div>Promocode: {reservation.promo.code}</div>
                    }
                    <div className={"reservation-info-values mt-4"}>
                        <div>
                            <span style={{color: 'blue'}}>Contract Value</span>
                            <span style={{color: 'blue'}}>{formatCurrency(reservation.totalContractValue)}</span>
                        </div>
                        <OverlayTrigger overlay={
                            <Tooltip>{reservation.payment?.firstRentCommission ? "First Rent Value: " + formatCurrency(reservation.payment?.firstRent) : "Contract Value: " + formatCurrency(reservation.totalContractValue)}<br/>
                                Commission: {reservation.payment?.landlordCommission}% ({reservation.inlifeCommissionVat ? reservation.inlifeCommissionVat :  formatCurrency((reservation.payment?.landlordCommission/100)*(reservation.payment?.firstRentCommission ? reservation.payment?.firstRent : reservation.totalContractValue))})<br/>
                                {reservation.penalty ? `An extra fee of 2% is charged for canceling ${reservation.penalty.tenantName}'s` : ""}
                            </Tooltip>}>
                        <div>
                            <span style={{color: 'orange'}}>Commission</span>
                            <span style={{color: 'orange'}}>{reservation.inlifeCommissionVat ? reservation.inlifeCommissionVat : formatCurrency((reservation.payment?.landlordCommission/100)*(reservation.payment?.firstRentCommission ? reservation.payment?.firstRent : reservation.totalContractValue))} {reservation.penalty && <i className="mdi mdi-information-outline orange">&nbsp;</i>}</span>
                        </div>
                        </OverlayTrigger>
                        <div>
                            <span style={{color: 'purple'}}>Booking Fee</span>
                            <span style={{color: 'purple'}}>{formatCurrency(reservation.payment.bookingFee)}</span>
                        </div>
                    </div>
                </div>
                <div className={"Card_actions"}>
                    {(reservation.currentStatus === "request" || reservation.currentStatus === "payment") &&
                    <OverlayTrigger overlay={<Tooltip>Expires on {expirationDate.format("DD/MM/YYYY")+ ' at ' + expirationDate.format("HH:mm")} hours</Tooltip>}>
                        <div>
                            <i className="mdi mdi-history mr-2"/>Expires in {expirationDate.endOf("day").diff(moment().startOf("day"), 'day')} days
                        </div>
                    </OverlayTrigger>}
                    {!reservation.tenantInfoVerified &&
                    <div className={"badge badge-info mt-1 clickable"}
                         onClick={verifyReservation(reservation)}>Verify Information
                    </div>}
                    <div className={"badge badge-primary mt-1 clickable"}
                         onClick={impersonateLandlord(reservation.landlord)}>Impersonate
                    </div>
                    {(!reservationService.isExpired(reservation) && !reservation.acceptedDate && !reservation.rejectedDate) && <>
                        <OverlayTrigger
                            overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                Please impersonate to complete this action.
                            </Tooltip>}>
                            <button  /*onClick={acceptReservation(reservation)} Nao pode estar disabled porque tira o tooltip*/
                                className={"badge badge-success mt-1"}>
                                Accept
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            overlay={<Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                Please impersonate to complete this action.
                            </Tooltip>}>
                            <button /*onClick={rejectReservation(reservation)}*/
                                className={"badge badge-danger mt-1"}>
                                Reject
                            </button>
                        </OverlayTrigger>
                    </>}
                    {(reservation.currentStatus === "request_cancellation" || (reservation.cancellation?.answerDate && reservation.currentStatus !== "cancelled")) &&
                    <div className={"badge badge-warning mt-1 clickable"}
                         onClick={() => checkCancellationRequest(reservation)}>{reservation.cancellation?.answerDate ? "Order Details" : "Reply Request"}
                    </div>
                    }
                    {!["cancelled", "request"].includes(reservation.currentStatus) &&
                    <div className={"info-box mt-2"}>
                        {(reservation.currentStatus === "rejected" || reservation.currentStatus === "expired") &&<>
                        <span>Rejected on: {moment(reservation.rejectedDate).format("DD/MM/YYYY HH:mm") || expirationDate.format("DD/MM/YYYY HH:mm")}</span>
                        {reservation.rejectedDetails?.motive && <span>{["accepted_same", "accepted_other"].includes(reservation.rejectedDetails?.motive) ? reservation.rejectedDetails?.motive : `By landlord (${reservation.rejectedDetails?.motive})`}</span>}
                        </>
                        }
                        {/*{reservation.currentStatus === "cancelled" && reservation.cancellation.answerDate && <span>Cancelada no dia: {moment(reservation.cancellation.answerDate).format("DD/MM/YYYY")}</span>}*/}
                        {reservation.currentStatus === "request_cancellation" && reservation.cancellation.requestDate &&
                        <span>Cancellation Request: {moment(reservation.cancellation.requestDate).format("DD/MM/YYYY HH:mm")}</span>}
                        {reservation.currentStatus === "paid" &&
                        <span>Paid on: {moment(reservation.paymentDate).format("DD/MM/YYYY HH:mm")}</span>}
                        {/*{reservation.currentStatus === "request" &&*/}
                        {/*<span>{moment(reservation.requestDate).format("DD/MM/YYYY HH:mm")}</span>}*/}
                        {reservation.currentStatus === "payment" && !reservation.suggestionType &&
                        <span>Accepted on: {moment(reservation.acceptedDate).format("DD/MM/YYYY HH:mm")}</span>}
                        {["paid", "payment", "request"].includes(reservation.currentStatus) && reservation.suggestionType && reservation.needsStudentAcceptance && moment(reservation.acceptedDate).add(1, "day").isAfter(moment()) &&
                        <span>Suggestion on: {moment(reservation.acceptedDate).format("DD/MM/YYYY HH:mm")}</span>}
                    </div>}
                    {(reservation.currentStatus === "payment") &&
                        <OverlayTrigger
                            overlay={
                                <Tooltip className={`tooltip-blue`} id={`tooltip-blue`}>
                                    Please impersonate to complete this action.
                                </Tooltip>}>
                            <div /*onClick={rejectReservation(reservation)}*/ className={"badge badge-danger mt-1"}>
                                Reject
                            </div>
                        </OverlayTrigger>
                        // <div className={"info-box mt-2"}>
                        //     <span>Aguardar pagamento</span>
                        // </div>
                    }
                    {reservation.currentStatus === "cancelled" &&
                        <OverlayTrigger overlay={<Tooltip>View details</Tooltip>}>
                            <div className={"info-box mt-2 d-flex flex-column clickable"}  onClick={() => checkCancellationRequest(reservation)}>
                                <span>By {reservation.cancellation.type?.split("_")[0] === "tenant" ? "Tenant" : (reservation.cancellation.type === "inlife" ? "Inlife" : "Landlord")}</span>
                                <span>on {moment(reservation.cancellation.requestDate).format("DD/MM/YYYY")}</span>
                            </div>
                        </OverlayTrigger>
                    }
                </div>
                <div className={"Card_extra"}>
                    <Dropdown drop={"start"}>
                        <Dropdown.Toggle variant="" id="dropdownMenuButton1"/>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(reservation.id);
                                console.log("reservation id copied to clipboard");
                            }}>Copy ID</Dropdown.Item>
                            <Dropdown.Item onClick={async ()=>{
                                await navigator.clipboard?.writeText(JSON.stringify(reservation));
                                console.log("reservation copied to clipboard");
                            }}>Copy JSON</Dropdown.Item>
                            <Dropdown.Item onClick={()=>{
                                window["reservation"] = reservation;
                                console.log("reservation stored as variable", '"reservation"');
                            }}>Store as variable</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
};

export default ReservationCard;
