import {get, patch, post} from "./index.js";

async function getUniversities() {
    try {
        return await get('/data/universities')
    } catch (e) {
        return [];
    }
}

let cachedCountries = null;
async function getCountries() {
    if(cachedCountries) return cachedCountries;
    try {
        cachedCountries = await get('/data/countries');
        return cachedCountries;
    } catch (e) {
        console.error("what", e);
        return [];
    }
}

function getCachedCountries() {
    return cachedCountries || [];
}

async function getNeighborhoods() {
    try {
        return await get('/data/neighborhoods');
    } catch (e) {
        return [];
    }
}

async function getRegions() {
    try {
        return await get('/data/regions');
    } catch (e) {
        return [];
    }
}

async function getIntegrationPlatforms() {
    try {
        return await get('/data/integrationPlatforms');
    } catch (e) {
        return [];
    }
}

async function exportReservations() {
    try {
        return await post('/backoffice/export/reservations');
    } catch (e) {
        return e;
    }
}

async function exportVisits() {
    try {
        return await post('/backoffice/export/visits');
    } catch (e) {
        return e;
    }
}

async function exportUsers() {
    try {
        return await post('/backoffice/export/users');
    } catch (e) {
        return e;
    }
}

async function exportLandlords() {
    try {
        return await post('/backoffice/export/landlords');
    } catch (e) {
        return e;
    }
}

async function exportProperties() {
    try {
        return await post('/backoffice/export/properties');
    } catch (e) {
        return e;
    }
}

async function exportInvoices() {
    try {
        return await post('/backoffice/export/invoices');
    } catch (e) {
        return e;
    }
}

async function exportFinance() {
    try {
        return await post('/backoffice/export/finance');
    } catch (e) {
        return e;
    }
}

async function getPromoCodes() {
    try {
        return await get('/data/getPromoCodes');
    } catch (e) {
        return [];
    }
}

async function savePromoCodes(promo_codes) {
    try {
        return await post('/data/savePromoCodes', {promo_codes});
    } catch (e) {
        return e;
    }
}

export const dataService = {
    getUniversities,
    getCountries,
    getNeighborhoods,
    getRegions,
    getIntegrationPlatforms,
    exportReservations,
    exportVisits,
    exportUsers,
    exportLandlords,
    exportProperties,
    exportInvoices,
    exportFinance,
    getPromoCodes,
    savePromoCodes
};
